import {
  Mda2SensorPolicyConfiguration,
  Mda2WiFiConfiguration,
} from '@/pages/StatusView/components/ConfigureBeaconModal/components/Mda2Configuration/Mda2Configuration';
import { Action, type Mda2ConfigurationType } from 'common/types';
import Modal from 'generic/components/Modal';
import ModalFooter from 'generic/components/ModalFooter';
import useStore from 'model/store';
import { useMemo, useState } from 'react';
import { HiOutlineWrench } from 'react-icons/hi2';
import { toast } from 'sonner';
import { FormattedMessage, useIntl } from 'translations/Intl';

interface MqttConfigurationModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export default function MqttConfigurationModal({
  open,
  setOpen,
}: MqttConfigurationModalProps) {
  const intl = useIntl();
  const [validConfiguration, setValidConfiguration] = useState(false);
  const [mda2Configuration, setMda2Configuration] = useState(
    new Map<string, Mda2ConfigurationType>(),
  );
  const organization = useStore(
    (state) => state.organizationSettings.organizationName,
  );
  const setEnrollmentConfiguration = useStore(
    (state) => state.setEnrollmentConfiguration,
  );

  const {
    sensorPolicyId,
    organizationUuid,
    ssid,
    wifiPassword,
    mqttBroker,
    onPremises,
    mqttUser,
    mqttPassword,
    offlineEnrollment,
  } = useMemo(
    () => mda2Configuration.get(organization ?? '') || {},
    [mda2Configuration, organization],
  );

  const isDisabled =
    ssid === '' ||
    !ssid ||
    wifiPassword === '' ||
    !wifiPassword ||
    mqttBroker === '' ||
    !mqttBroker ||
    !mda2Configuration ||
    !validConfiguration ||
    (onPremises &&
      (mqttUser === '' ||
        !mqttUser ||
        mqttPassword === '' ||
        !mqttPassword ||
        organizationUuid === '' ||
        !organizationUuid));

  return (
    <Modal
      action={Action.ADD}
      title={intl.formatMessage({
        id: 'Device configuration',
      })}
      renderIcon={({ className }) => (
        <HiOutlineWrench className={`${className ?? ''} animate-pulse`} />
      )}
      open={open}
      setShowModal={setOpen}
      footer={
        <ModalFooter
          dataTestId={{
            proceed: 'configuration-save',
            cancel: 'configuration-cancel',
          }}
          disabled={isDisabled}
          action={Action.ADD}
          proceed={<FormattedMessage id="Save configuration" />}
          onProceed={() => {
            if (
              !isDisabled &&
              sensorPolicyId &&
              typeof offlineEnrollment === 'boolean' &&
              typeof onPremises === 'boolean'
            ) {
              setEnrollmentConfiguration({
                configuration: {
                  organizationUuid,
                  sensorPolicyId,
                  ssid,
                  wifiPassword,
                  mqttBroker,
                  mqttUser,
                  mqttPassword,
                  offlineEnrollment,
                  onPremises,
                },
              });
              setOpen(false);
              toast.success(intl.formatMessage({ id: 'Saved configuration' }));
            }
          }}
          onCancel={() => setOpen(false)}
        />
      }
    >
      <div className="flex flex-col space-y-2 w-full">
        <Mda2WiFiConfiguration
          organization={organization ?? ''}
          setConfiguration={setMda2Configuration}
        />
        <Mda2SensorPolicyConfiguration
          organization={organization ?? ''}
          setConfiguration={setMda2Configuration}
          setValid={setValidConfiguration}
        />
      </div>
    </Modal>
  );
}
