import type { UserProps } from '@/common/types';
import keycloak from '@/keycloak';
import { BUBBLE_ROUTES } from '@/router/routes';
import {
  MenuItem as HUIMenuItem,
  Menu,
  MenuButton,
  MenuItems,
} from '@headlessui/react';
import Button from 'generic/components/Form/Button';
import { FormattedMessage } from 'translations/Intl';
import PrivateWrapper from '../../../../PrivateWrapper';
import MenuItem from './MenuItem';

interface UserBubbleProps {
  userInfos?: UserProps;
}

export default function UserBubble({ userInfos }: UserBubbleProps) {
  return (
    <Menu
      as="div"
      className=" inline-block text-left"
      data-test-id="menu-wrapper"
    >
      <MenuButton
        data-test-id="user-menu-button"
        className="size-10 bg-primary-500 focus:outline-none rounded-full flex items-center justify-center text-white select-none capitalize"
      >
        {userInfos?.name[0]}
      </MenuButton>

      <MenuItems
        transition
        portal
        modal={false}
        anchor="bottom end"
        className="w-36 mt-2 z-40 origin-top-right bg-white dark:bg-neutral-800  dark:text-white divide-y divide-neutral-100 dark:divide-black rounded-md shadow-lg ring-1 ring-black dark:ring-neutral-700 ring-opacity-5 focus:outline-none text-sm transition duration-200 ease-out data-[closed]:scale-95 data-[closed]:opacity-0"
      >
        <div className="py-1">
          <div className="border-b dark:border-neutral-700">
            <div className="group flex flex-col w-full px-4 py-2 text-neutral-900 dark:text-white ">
              <div>
                <FormattedMessage id="Signed in as" />
              </div>

              <div>
                <b>{userInfos?.name}</b>
              </div>
            </div>
          </div>
          {BUBBLE_ROUTES.map(
            ({ path, userRole, name, 'data-test-id': dataTestId }) => (
              <PrivateWrapper key={name} roleRequired={userRole}>
                <MenuItem data-test-id={dataTestId} to={path}>
                  <FormattedMessage id={name} />
                </MenuItem>
              </PrivateWrapper>
            ),
          )}
          <HUIMenuItem>
            {({ focus }) => (
              <Button
                onClick={() => keycloak.logout()}
                className={`${
                  focus
                    ? 'bg-neutral-100 text-neutral-900 dark:text-neutral-500'
                    : 'text-neutral-700 dark:text-neutral-200'
                } text-sm px-4 py-2 cursor-pointer group flex text-left w-full`}
              >
                <FormattedMessage id="Logout" />
              </Button>
            )}
          </HUIMenuItem>
        </div>
      </MenuItems>
    </Menu>
  );
}
