import Accordion from 'generic/components/Accordion';
import { FormattedMessage } from 'translations/Intl';
import SetSceneIdentifyButton from './components/SetSceneIdentifyButton';

interface Mda2MqttActionProps {
  mqttTopic: string;
}

export default function Mda2MqttAction({ mqttTopic }: Mda2MqttActionProps) {
  return (
    <Accordion title={<FormattedMessage id="MQTT Actions" />} initialStateOpen>
      <div className="relative">
        <div className="flex space-x-2">
          <SetSceneIdentifyButton mqttTopic={mqttTopic} />
        </div>
      </div>
    </Accordion>
  );
}
