import CloseButton from 'generic/components/Form/Button/CloseButton';
import TextArea from 'generic/components/Form/TextArea';
import type { Route } from 'router/routes';
import { FormattedMessage, useIntl } from 'translations/Intl';

import { Action, type UrlParams } from '@/common/types';
import Input from '@/generic/components/Form/Input';
import Modal from '@/generic/components/Modal';
import { useRefreshTokenMutation } from '@/graphql/types';
import useHasuraHeader, {
  HasuraPermissions,
} from '@/utils/graphql/useHasuraHeaders';
import { useEffect, useMemo, useState } from 'react';
import { HiCodeBracket, HiOutlineCog } from 'react-icons/hi2';

interface EmbeddedModalProps {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  urlParams: UrlParams;
  component: Route['path'];
}

export default function EmbeddedModal({
  isOpen,
  setIsOpen,
  urlParams,
  component,
}: EmbeddedModalProps) {
  const intl = useIntl();
  const hasuraHeader = useHasuraHeader();
  const [value, setValue] = useState<string | null>(null);
  const [refreshToken, setRefreshToken] = useState<string | undefined>(
    undefined,
  );
  const [fetching, setFetching] = useState(false);
  const [, refreshTokenMutation] = useRefreshTokenMutation();

  useEffect(() => {
    if (isOpen && !refreshToken && !fetching) {
      setFetching(true);
      refreshTokenMutation({}, hasuraHeader(HasuraPermissions.FEATURE_SHARE))
        .then((data) =>
          setRefreshToken(data.data?.GetRefreshToken?.refreshToken),
        )
        .finally(() => setFetching(false));
    }
  }, [fetching, isOpen, refreshToken, refreshTokenMutation, hasuraHeader]);

  const url = useMemo(() => {
    const href = new URL(
      `https://${window.location.host}/component/${component}?refreshToken=${refreshToken}&embedded=true`,
    );
    for (const urlParam of urlParams) {
      const [key, param] = urlParam;
      href.searchParams.set(key, param);
    }
    return href;
  }, [component, urlParams, refreshToken]);

  const params = useMemo(
    () =>
      Array.from(url.searchParams.entries()).filter(
        (entry) => !['refreshToken', 'embedded'].includes(entry[0]),
      ),
    [url],
  );

  return (
    <Modal
      title={intl.formatMessage({
        id: 'Embed component',
      })}
      renderIcon={({ className }) => <HiCodeBracket className={className} />}
      action={Action.UPDATE}
      open={isOpen}
      wrapperClassName="sm:max-w-screen-sm md:max-w-screen-md lg:max-w-screen-lg"
      setShowModal={setIsOpen}
      footer={<CloseButton onClick={() => setIsOpen(false)} />}
    >
      <div className="flex flex-col md:flex-row space-x-0 space-y-2 md:space-x-2 md:space-y-0">
        <div className="w-full md:w-2/3">
          <p className="block text-sm text-neutral-700 dark:text-neutral-100">
            <FormattedMessage id="Preview" />
          </p>
          <div className="mt-1 aspect-w-1 aspect-h-1 border border-neutral-300 rounded-md shadow-sm">
            <iframe
              title="preview"
              width="300px"
              height="400px"
              src={url.toString()}
            />
          </div>
        </div>
        <div className="w-full md:w-1/3 space-y-3">
          <TextArea
            label={intl.formatMessage({ id: 'Embed code snippet' })}
            text={`<iframe title="preview" width="300px" height="400px" src="${
              refreshToken ? url.toString() : ''
            }" />`}
            data-test-id="embedded-url"
          />
          <p className="mt-2 text-xxs text-neutral-500 dark:text-neutral-100">
            <FormattedMessage id="Token warning" />
          </p>
          <div>
            <p className="block text-sm text-neutral-700 dark:text-neutral-100">
              <FormattedMessage id="Parameter" />
            </p>
            <div className="mt-1">
              {params.map((entry) => {
                const [key] = entry;
                return (
                  <Input
                    disabled
                    key={key}
                    type="text"
                    value={url.searchParams.get(key) ?? value ?? ''}
                    label={key}
                    id="Floor number"
                    placeholder={key}
                    renderIcon={({ className }) => (
                      <HiOutlineCog className={className} />
                    )}
                    onChangeValue={(e) => {
                      setValue(e);
                      url.searchParams.set(key, e);
                    }}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
