import Subtitle from 'generic/components/Subtitle';
import { useReportSummaryQuery } from 'graphql/types';
import { useMemo } from 'react';
import { lower } from 'utils/date';
import useReportFilter from 'utils/graphql/useReportFilter';
import { uuidv4 } from 'utils/uuid';
import ReportingPage from '../ReportingPage';
import type { SummaryData } from './LineChart';
import LineChart from './LineChart';

export default function ReportSummary() {
  const { context, variables } = useReportFilter();
  const [{ data, fetching }] = useReportSummaryQuery({
    context,
    variables: {
      Building: variables.Building,
    },
  });

  const summaryData: SummaryData = useMemo(
    () => ({
      limits: {
        good: 100, // 80-100%
        acceptable: 80, // 60-80%
        poor: 60, // 0-60%
      },
      reports: (data?.ReportPages ?? []).flatMap((page) => {
        const selectedReportPageMetric = page.ReportPageMetrics.find(
          (rpm) => rpm.MetricLimits.length,
        );
        const selectedLimit = selectedReportPageMetric?.MetricLimits[0];

        return (
          selectedReportPageMetric?.ReportReportPageMetrics ?? []
        ).flatMap((rpm) => {
          const weight = selectedLimit?.Weight ?? 0;
          let value = selectedLimit?.DivideByTargetValue
            ? (rpm.Value * 100) / selectedLimit.TargetValue
            : ((selectedLimit?.TargetValue ?? 1) * 100) / rpm.Value;

          if (value === Number.POSITIVE_INFINITY) {
            value = 0;
          }

          return {
            id: uuidv4(),
            reportPageTitle: page.Name,
            weight,
            date: lower(rpm.Report.Period),
            value,
          };
        });
      }),
    }),
    [data?.ReportPages],
  );

  return (
    <ReportingPage loading={fetching} reportTitle="Management summary">
      <Subtitle value="Compliance" />
      <div className="h-96 w-full">
        <LineChart data={summaryData} />
      </div>
    </ReportingPage>
  );
}
