import { type MarginProps, Themes } from '@/common/types';
import Axis from '@/generic/components/Chart/Axis';
import LoadingSpinner from '@/generic/components/LoadingSpinner';
import {
  type MqttBeaconHistoryQuery,
  useMqttBeaconHistoryQuery,
} from '@/graphql/types';
import useStore from '@/model/store';
import localize from '@/utils/format';
import getColor from '@/utils/getColor';
import useHasuraHeader, {
  HasuraPermissions,
} from '@/utils/graphql/useHasuraHeaders';
import { curveStep } from '@visx/curve';
import { localPoint } from '@visx/event';
import { Group } from '@visx/group';
import { ParentSize } from '@visx/responsive';
import { scaleLinear, scaleTime } from '@visx/scale';
import { AreaClosed, Bar, Line } from '@visx/shape';
import { TooltipWithBounds, defaultStyles, useTooltip } from '@visx/tooltip';
import { bisector, extent } from 'd3-array';
import {
  addDays,
  addHours,
  differenceInDays,
  differenceInHours,
  isSameDay,
  isSameHour,
  subDays,
  subMonths,
} from 'date-fns';
import { useCallback, useMemo, useState } from 'react';
import { FormattedMessage } from 'translations/Intl';
import { lower, upper } from 'utils/date';

interface ResponsiveStatusChartProps {
  margin?: MarginProps;
  beaconName: string;
}

interface StatusChartProps extends ResponsiveStatusChartProps {
  height: number;
  width: number;
}

interface ChartDateProps {
  date: Date;
  dates: Date[];
  status: number;
}

// accessors
const getDate = (d: ChartDateProps) => d.date;
const getStatus = (d: ChartDateProps) => d.status;
const bisectDate = bisector<ChartDateProps, Date>((d) => new Date(d.date)).left;
const getParsedDate = (
  d: MqttBeaconHistoryQuery['MqttBeaconHistories'][number],
) => ({
  start: lower(d.Duration),
  end: upper(d.Duration),
});

function StatusChart({
  height,
  width,
  margin = { top: 10, left: 0, right: 0, bottom: 30 },
  beaconName,
}: StatusChartProps) {
  const hasuraHeader = useHasuraHeader();
  const userRoles = useStore((state) => state.user)?.roles;
  const [generatedDays] = useState(
    Array.from(Array(29)).map((_, i) => ({
      date: new Date(addDays(subDays(new Date(), 30), i)),
      dates: [],
      status: 1,
    })),
  );
  const theme = useStore((state) => state.userSettings.theme);

  const [{ data: mqttBeaconHistory, fetching }] = useMqttBeaconHistoryQuery({
    context: useMemo(
      () =>
        hasuraHeader(
          userRoles?.includes(HasuraPermissions.READ_ALL)
            ? HasuraPermissions.READ_ALL
            : HasuraPermissions.READ,
        ),
      [hasuraHeader, userRoles],
    ),
    variables: useMemo(
      () => ({
        BeaconName: beaconName,
        Start: subMonths(new Date(), 2),
      }),
      [beaconName],
    ),
  });

  const { tooltipLeft, tooltipTop, tooltipData, hideTooltip, showTooltip } =
    useTooltip<ChartDateProps>();

  // bounds
  const innerWidth = width - margin.left - margin.right;
  const innerHeight = height - margin.top - margin.bottom;

  const data = useMemo(() => {
    if (mqttBeaconHistory && mqttBeaconHistory.MqttBeaconHistories.length > 0) {
      const sortedData = mqttBeaconHistory.MqttBeaconHistories.sort(
        (a, b) =>
          getParsedDate(a).start.getTime() - getParsedDate(b).start.getTime(),
      );

      // Get the min and max value in order to construct an array of evenly spaced dates
      // as there are only entries on every change from Offline to Online and vice versa
      const min = sortedData[0]?.Duration
        ? lower(sortedData[0].Duration)
        : new Date();

      const max = sortedData[sortedData.length - 1]?.Duration
        ? upper(sortedData[sortedData.length - 1]!.Duration)
        : new Date();

      const hoursBetween = differenceInHours(max, min);
      const daysBetween = differenceInDays(max, min);
      const useHours = daysBetween < 4;

      return Array.from({
        length: useHours ? hoursBetween : daysBetween,
      })
        .map((_, day) => {
          const dayDate = useHours
            ? new Date(addHours(min, day))
            : new Date(addDays(min, day));

          // Find the original entry in order to get the tooltip data
          // for the dates and set the status for the chart
          const correspondingHistoryEntry = sortedData.find(
            (m) =>
              // If offline data is available for that day prefer it over online data
              // as the beacon is usually online and not offline -> highlight offline
              ((useHours
                ? isSameHour(dayDate, getParsedDate(m).start)
                : isSameDay(dayDate, getParsedDate(m).start)) &&
                !m.Online) ||
              (getParsedDate(m).start <= dayDate &&
                dayDate < getParsedDate(m).end),
          );

          return {
            date: dayDate,
            dates: [
              correspondingHistoryEntry
                ? getParsedDate(correspondingHistoryEntry).start
                : dayDate,
              correspondingHistoryEntry
                ? getParsedDate(correspondingHistoryEntry).end
                : dayDate,
            ],
            status: correspondingHistoryEntry?.Online ? 1 : 0,
          };
        })
        .sort((a, b) => a.date.getTime() - b.date.getTime());
    }
    return generatedDays;
  }, [mqttBeaconHistory, generatedDays]);

  // Scales
  const xScale = useMemo(
    () =>
      scaleTime({
        range: [0, innerWidth],
        domain: extent(data, getDate) as [Date, Date],
      }),
    [innerWidth, data],
  );
  const yScale = useMemo(
    () =>
      scaleLinear({
        range: [innerHeight, margin.top],
        domain: [0, 1],
      }),
    [innerHeight, margin.top],
  );

  // Tooltip handler
  const handleTooltip = useCallback(
    (
      event:
        | React.TouchEvent<SVGRectElement>
        | React.MouseEvent<SVGRectElement>,
    ) => {
      const { x } = localPoint(event) || { x: 0 };
      const x0 = xScale.invert(x - margin.left);
      const index = bisectDate(data, x0, 1);

      const d0 = data[index - 1] ? data[index - 1] : null;
      const d1 = data[index];
      let d = d0;
      if (d0 && d1) {
        d =
          x0.valueOf() - getDate(d0).valueOf() >
          getDate(d1).valueOf() - x0.valueOf()
            ? d1
            : d0;
      }
      if (d) {
        showTooltip({
          tooltipData: d,
          tooltipLeft: x,
          tooltipTop: yScale(getStatus(d)),
        });
      }
    },
    [xScale, margin.left, yScale, data, showTooltip],
  );

  const tooltipColor = useMemo(
    () => (tooltipData?.status === 0 ? 'RED' : 'GREEN'),
    [tooltipData?.status],
  );

  return (
    <div className="relative" data-test-id="status-chart">
      <LoadingSpinner loading={fetching} />
      <svg width={width} height={height}>
        <Group top={margin.top} left={margin.left}>
          <AreaClosed<ChartDateProps>
            data={data}
            x={(d) => xScale(getDate(d))}
            y={(d) => yScale(getStatus(d))}
            yScale={yScale}
            strokeWidth={2}
            stroke={getColor('GREEN')}
            fill={getColor('GREEN', '.4')}
            curve={curveStep}
          />
          <AreaClosed<ChartDateProps>
            data={data}
            x={(d) => xScale(getDate(d))}
            // Reverse the values so it is shown in full height
            y={(d) => yScale(getStatus(d) === 0 ? 1 : 0)}
            yScale={yScale}
            strokeWidth={2}
            stroke={getColor('RED')}
            fill={getColor('RED', '.4')}
            curve={curveStep}
          />
          {width > 0 && height > 0 && (
            // Just used for showing the tooltip
            <Bar
              y={margin.top}
              width={innerWidth}
              height={innerHeight}
              fill="transparent"
              onTouchStart={handleTooltip}
              onTouchMove={handleTooltip}
              onMouseMove={handleTooltip}
              onMouseLeave={() => hideTooltip()}
            />
          )}
          {tooltipData && (
            <g>
              <Line
                from={{ x: tooltipLeft, y: margin.top }}
                to={{ x: tooltipLeft, y: innerHeight + margin.top }}
                stroke={
                  theme.color === Themes.LIGHT
                    ? getColor('NEUTRAL600')
                    : getColor('NEUTRAL300')
                }
                strokeWidth={2}
                pointerEvents="none"
              />
              <circle
                cx={tooltipLeft}
                cy={(tooltipTop ?? 0) + 1}
                r={4}
                fill="black"
                fillOpacity={0.1}
                stroke="black"
                strokeOpacity={0.1}
                strokeWidth={2}
                pointerEvents="none"
              />
              <circle
                cx={tooltipLeft}
                cy={tooltipTop}
                r={4}
                fill={tooltipColor}
                stroke="white"
                strokeWidth={2}
                pointerEvents="none"
              />
            </g>
          )}
          <Axis
            lowLevelChart
            top={innerHeight}
            scale={xScale}
            orientation="bottom"
          />
        </Group>
      </svg>
      {tooltipData && (
        <div>
          <TooltipWithBounds
            top={margin.top - 40}
            left={tooltipLeft ?? 0}
            style={{
              ...defaultStyles,
              background:
                theme.color === Themes.LIGHT
                  ? getColor('WHITE')
                  : getColor('NEUTRAL800'),
            }}
          >
            <p className="dark:text-neutral-200">
              {getStatus(tooltipData) > 0 ? (
                <FormattedMessage id="Online" />
              ) : (
                <FormattedMessage id="Offline" />
              )}
            </p>
          </TooltipWithBounds>
          <TooltipWithBounds
            className="z-30"
            top={innerHeight + margin.top + 20}
            left={tooltipLeft ?? 0}
            style={{
              ...defaultStyles,
              backgroundColor:
                theme.color === Themes.LIGHT
                  ? getColor('WHITE')
                  : getColor('NEUTRAL800'),
              minWidth: 72,
              textAlign: 'center',
            }}
          >
            <p className="dark:text-neutral-200">
              {tooltipData.dates[0] && tooltipData.dates[1]
                ? `${localize(tooltipData.dates[0], 'Pp')} - ${localize(
                    tooltipData.dates[1],
                    'Pp',
                  )}`
                : localize(getDate(tooltipData), 'Pp')}
            </p>
          </TooltipWithBounds>
        </div>
      )}
    </div>
  );
}

export default function ResponsiveStatusChart(
  props: ResponsiveStatusChartProps,
) {
  return (
    <ParentSize>
      {({ height, width }) => (
        <StatusChart {...props} width={width} height={height} />
      )}
    </ParentSize>
  );
}
