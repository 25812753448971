import { type Table, flexRender } from '@tanstack/react-table';
import Filter from './components/Filter/Filter';

export const SELECT_ID = 'select';

interface ContentProps<T> {
  table: Table<T>;
}

export default function Content<T>({ table }: ContentProps<T>) {
  // TODO: Remove when updated https://github.com/TanStack/table/issues/5567
  'use no memo';
  return (
    <>
      {table.getHeaderGroups().map((headerGroup) =>
        headerGroup.headers
          .filter(
            (header) => header.id !== SELECT_ID && header.column.getCanFilter(),
          )
          .map((header) => {
            const firstValue = table
              .getPreFilteredRowModel()
              .flatRows.find(
                (f) =>
                  // Check first "real" value in order to decide what type it is
                  ![undefined, null, '-', ''].includes(
                    f.getValue(header.column.id),
                  ),
              )
              ?.getValue(header.column.id);

            return (
              (typeof firstValue === 'string' ||
                typeof firstValue === 'number') && (
                <div key={header.id} className="flex flex-col space-y-1">
                  <div className="text-base font-bold">
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )}
                  </div>
                  <Filter<T> column={header.column} firstValue={firstValue} />
                </div>
              )
            );
          }),
      )}
    </>
  );
}
