interface PillProps {
  visible?: boolean;
  setVisible?: (visible: boolean) => void;
  className?: string;
  children: React.ReactNode;
  ref?: React.RefObject<HTMLDivElement>;
}

export default function Pill({
  ref,
  visible,
  setVisible,
  className,
  children,
  ...rest
}: PillProps) {
  const getClass = () => {
    if (visible === undefined) {
      return 'bg-primary-200 text-primary-500';
    }

    if (visible) {
      return 'bg-primary-200 hover:line-through text-primary-500';
    }

    // Not visible
    return 'line-through bg-neutral-300 hover:no-underline text-white';
  };
  return setVisible ? (
    <div
      ref={ref}
      // biome-ignore lint/a11y/useSemanticElements: Can't use as a button as otherwise it would be descendant of the button
      role="button"
      onClick={() => setVisible(!visible)}
      onKeyDown={() => setVisible(!visible)}
      tabIndex={0}
      className={`${getClass()} transition-all flex items-center text-xxs rounded-full py-1 px-2 ${className ?? ''}`}
      {...rest}
    >
      {children}
    </div>
  ) : (
    <div
      ref={ref}
      className={`${getClass()} transition-all flex items-center text-xxs rounded-full py-1 px-2 ${className ?? ''}`}
      {...rest}
    >
      {children}
    </div>
  );
}
