import Transition from '@/generic/components/Transition';
import {
  endOfDay,
  endOfMonth,
  endOfWeek,
  endOfYear,
  startOfDay,
  startOfMonth,
  startOfWeek,
  startOfYear,
  subMonths,
  subWeeks,
  subYears,
} from 'date-fns';
import { German } from 'flatpickr/dist/l10n/de';
import type { CustomLocale } from 'flatpickr/dist/types/locale';
import useStore from 'model/store';
import { type Ref, useMemo, useState } from 'react';
import Flatpickr from 'react-flatpickr';
import { HiOutlineCalendar } from 'react-icons/hi2';
import {
  FormattedMessage,
  type IntlMessageKeys,
  useIntl,
} from 'translations/Intl';

import Select from '../Select';

interface DatePickerProps {
  label?: IntlMessageKeys;
}

interface SelectProps {
  id: string;
  value: string;
  dates: { start: string; end: string };
}

export function DatePickerInput({
  label,
  required = false,
  options,
  ...rest
}: {
  label?: IntlMessageKeys;
  required?: boolean;
  options?: Partial<{
    static: boolean;
    locale: CustomLocale | { firstDayOfWeek: number };
    mode: 'range' | 'single';
    dateFormat: string;
    defaultDate: Date[];
    prevArrow: string;
    nextArrow: string;
    enableTime: boolean;
    minTime: string;
    maxTime: string;
    minDate: string | Date;
    maxDate: string | Date;
    onChange: (selectedDate: Date[]) => void;
    flatpickrRef?: Ref<Flatpickr>;
  }>;
}) {
  const language = useStore((state) => state.userSettings.language);

  const flatpickrOptions = {
    static: true,
    // Set firstDayOfWeek: (see https://flatpickr.js.org/localization/)
    locale: language === 'de' ? German : { firstDayOfWeek: 1 },
    dateFormat: 'M j, Y',
    prevArrow:
      '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M5.4 10.8l1.4-1.4-4-4 4-4L5.4 0 0 5.4z" /></svg>',
    nextArrow:
      '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M1.4 10.8L0 9.4l4-4-4-4L1.4 0l5.4 5.4z" /></svg>',
    ...options,
  };

  return (
    <div className="print:hidden">
      {label && (
        <span className="block text-sm text-neutral-700 dark:text-white">
          <FormattedMessage id={label} />
          {required && <span className="text-red-700"> *</span>}
        </span>
      )}

      <div className={`${label ? 'mt-1' : 'mt-0'} relative rounded-md`}>
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <HiOutlineCalendar className="size-5 z-10 text-neutral-500 dark:text-neutral-200" />
        </div>
        <Flatpickr
          className="font-medium focus:ring-primary-500 w-36 sm:w-full focus:border-primary-500 block pl-10 bg-white text-neutral-700 hover:bg-neutral-50 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:border-neutral-700 border-neutral-200 dark:text-neutral-400 dark:hover:text-white rounded-md text-sm"
          options={flatpickrOptions}
          ref={options?.flatpickrRef}
          data-test-id="datepicker"
          {...rest}
        />
      </div>
    </div>
  );
}

export default function DatePicker({ label = 'Date' }: DatePickerProps) {
  const intl = useIntl();
  const dateRange = useStore((state) => state.userSettings.dateRange);
  const setDateRange = useStore((state) => state.userApi.setDateRange);
  const date = useMemo(() => new Date(dateRange.start), [dateRange.start]);
  const dateTo = useMemo(
    () => (dateRange.end ? new Date(dateRange.end) : null),
    [dateRange.end],
  );
  const [internalDate, setInternalDate] = useState(date);
  const [internalDateTo, setInternalDateTo] = useState(dateTo);
  const [now] = useState(startOfDay(new Date()));
  const [timeSlots] = useState<SelectProps[]>([
    {
      id: 'currentWeek',
      value: intl.formatMessage({
        id: 'Current week',
      }),
      dates: {
        start: startOfWeek(now, { weekStartsOn: 1 }).toISOString(),
        end: endOfDay(now).toISOString(),
      },
    },
    {
      id: 'lastWeek',
      value: intl.formatMessage({
        id: 'Last week',
      }),
      dates: {
        start: startOfWeek(subWeeks(now, 1), { weekStartsOn: 1 }).toISOString(),
        end: endOfWeek(subWeeks(now, 1), { weekStartsOn: 1 }).toISOString(),
      },
    },
    {
      id: 'currentMonth',
      value: intl.formatMessage({
        id: 'Current month',
      }),
      dates: {
        start: startOfMonth(now).toISOString(),
        end: endOfDay(now).toISOString(),
      },
    },
    {
      id: 'lastMonth',
      value: intl.formatMessage({
        id: 'Last month',
      }),
      dates: {
        start: startOfMonth(subMonths(now, 1)).toISOString(),
        end: endOfMonth(subMonths(now, 1)).toISOString(),
      },
    },
    {
      id: 'currentYear',
      value: intl.formatMessage({
        id: 'Current year',
      }),
      dates: {
        start: startOfYear(now).toISOString(),
        end: endOfDay(now).toISOString(),
      },
    },
    {
      id: 'lastYear',
      value: intl.formatMessage({
        id: 'Last year',
      }),
      dates: {
        start: startOfYear(subYears(now, 1)).toISOString(),
        end: endOfYear(subYears(now, 1)).toISOString(),
      },
    },
    {
      id: 'custom',
      value: intl.formatMessage({
        id: 'Custom',
      }),
      dates: {
        start: date.toISOString(),
        end: dateTo?.toISOString() ?? now.toISOString(),
      },
    },
  ]);
  const [selectedTimeSlot, setSelectedTimeslot] = useState<SelectProps>(
    timeSlots.find(
      (t) =>
        JSON.stringify(t.dates) === JSON.stringify(dateRange) ||
        t.id === 'custom',
    ) ?? {
      id: 'custom',
      value: '',
      dates: {
        start: startOfDay(now).toISOString(),
        end: endOfDay(now).toISOString(),
      },
    },
  );

  const options = {
    // As const is used for TS purposes: https://stackoverflow.com/questions/37978528/typescript-type-string-is-not-assignable-to-type
    mode: dateTo ? ('range' as const) : ('single' as const),
    defaultDate: internalDateTo
      ? [internalDate, internalDateTo]
      : [internalDate],
    onChange: (selectedDate: Date[]) => {
      if (selectedDate.length === 1 && !dateTo && selectedDate[0]) {
        setDateRange({
          start: startOfDay(selectedDate[0]).toISOString(),
          end: undefined,
        });
      }

      if (
        selectedDate[0] &&
        selectedDate[1] &&
        selectedTimeSlot.id === 'custom'
      ) {
        const start = startOfDay(selectedDate[0]);
        const end = endOfDay(selectedDate[1]);
        setDateRange({
          start: start.toISOString(),
          end: end.toISOString(),
        });

        setInternalDate(start);
        setInternalDateTo(end);
      }
    },
  };

  return (
    <div className="flex items-end space-x-2">
      {!!dateTo && (
        <Select
          value={selectedTimeSlot}
          label="Timespan"
          onChangeSelected={(selected) => {
            if (selected) {
              // Select the dates of the preselected ranges (not custom ones)
              if (selected.dates.start && selected.dates.end) {
                setSelectedTimeslot(selected);
                setDateRange({
                  start: selected.dates.start,
                  end: selected.dates.end,
                });
              }
            }
          }}
          options={timeSlots}
          isDeselectable={false}
          renderValue={(t) => t.value}
          keyParameter="id"
        />
      )}
      <Transition show={selectedTimeSlot.id === 'custom'}>
        <DatePickerInput options={options} label={label} />
      </Transition>
    </div>
  );
}
