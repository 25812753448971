import Card from 'generic/components/Card';

interface UtlizationCardProps {
  value: number;
  title: string;
  loading: boolean;
  infoText?: string;
}

function Skeleton() {
  return (
    <div className="animate-pulse p-2 h-full flex justify-center items-center">
      <div className="w-1/2 bg-neutral-200 rounded-md h-8" />
    </div>
  );
}

export default function UtlizationCard({
  value,
  title,
  loading,
  infoText,
  ...rest
}: UtlizationCardProps) {
  return (
    <Card
      infoText={infoText}
      title={title}
      className="relative w-full h-24 lg:h-full pb-2 px-2 py-2"
    >
      {loading ? (
        <Skeleton />
      ) : (
        <div
          {...rest}
          className="flex text-4xl h-full w-full items-center justify-center"
        >
          {value.toFixed(0)}%
        </div>
      )}
    </Card>
  );
}
