import { type MarginProps, Themes } from '@/common/types';
import Axis from '@/generic/components/Chart/Axis';
import Legend from '@/generic/components/Chart/Legend';
import useStore from '@/model/store';
import { REPORTING_RIGHT_MARGIN } from '@/pages/ReportingView/components/Reports/Reports';
import getColor from '@/utils/getColor';
import GridRows from '@visx/grid/lib/grids/GridRows';
import { Group } from '@visx/group';
import { ParentSize } from '@visx/responsive';
import { scaleBand, scaleLinear, scaleOrdinal } from '@visx/scale';
import { BarStackHorizontal } from '@visx/shape';
import { TooltipWithBounds, defaultStyles, useTooltip } from '@visx/tooltip';
import AnimatedRect from 'generic/components/Chart/AnimatedRect';
import Subtitle from 'generic/components/Subtitle';
import {
  FormattedMessage,
  type IntlMessageKeys,
  useIntl,
} from 'translations/Intl';

interface Data {
  roomName: string;
  acceptablePercentage: number;
  goodPercentage: number;
  poorPercentage: number;
}

type TooltipData = {
  data?: Data;
  key: 'acceptablePercentage' | 'goodPercentage' | 'poorPercentage';
};

interface ResponsiveBarChart {
  margin?: MarginProps;
  data: Data[];
  sensorType: string;
}

interface BarChart extends ResponsiveBarChart {
  height: number;
  width: number;
}

function BarChart({
  height,
  width,
  margin = {
    top: 30,
    left: 180,
    right: REPORTING_RIGHT_MARGIN,
    bottom: 60,
  },
  data,
  sensorType,
}: BarChart) {
  const intl = useIntl();
  const theme = useStore((state) => state.userSettings.theme);
  const keys: (keyof Data)[] = [
    'goodPercentage',
    'acceptablePercentage',
    'poorPercentage',
  ];

  // Bounds
  const xMax = Math.max(width - margin.left - margin.right, 0);
  const yMax = height - margin.top - margin.bottom;

  // Scales
  const yScale = scaleBand<string>({
    range: [yMax, 0],
    domain: data.map((d) => d.roomName).sort((a, b) => a.localeCompare(b)),
    paddingInner: 0.2,
  });

  const xScale = scaleLinear<number>({
    range: [0, xMax],
    domain: [0, 100],
    nice: true,
  });

  const colorScale = scaleOrdinal({
    domain: keys,
    range: [getColor('GREEN'), getColor('YELLOW'), getColor('RED')],
  });

  const {
    tooltipOpen,
    tooltipLeft,
    tooltipTop,
    tooltipData,
    hideTooltip,
    showTooltip,
  } = useTooltip<TooltipData>();

  return (
    <div className="relative">
      <Subtitle
        value={intl.formatMessage({
          id: sensorType as IntlMessageKeys,
        })}
      />
      <svg width={width} height={height}>
        <Group top={margin.top} left={margin.left}>
          <GridRows
            numTicks={10}
            scale={yScale}
            width={xMax}
            height={yMax}
            strokeDasharray="1,3"
            stroke={getColor('NEUTRAL600')}
            strokeOpacity={0.6}
          />
          <g>
            <BarStackHorizontal
              data={data}
              keys={keys}
              y={(d) => d.roomName}
              xScale={xScale}
              yScale={yScale}
              color={colorScale}
            >
              {(barStacks) =>
                barStacks.map((barStack) =>
                  barStack.bars.map((bar) => (
                    <AnimatedRect
                      horizontal
                      bar={bar}
                      key={`bar-stack-${barStack.index}-${bar.index}`}
                      data-test-id={`bar-occupancy-${barStack.key}-${bar.bar.data.roomName}-${sensorType}`}
                      onMouseMove={(event) => {
                        if (
                          barStack.key === 'acceptablePercentage' ||
                          barStack.key === 'goodPercentage' ||
                          barStack.key === 'poorPercentage'
                        ) {
                          showTooltip({
                            tooltipData: {
                              data: data[bar.index],
                              key: barStack.key,
                            },
                            tooltipTop: event?.y,
                            tooltipLeft: event?.x,
                          });
                        }
                      }}
                      onMouseOut={hideTooltip}
                    />
                  )),
                )
              }
            </BarStackHorizontal>
          </g>
          <Axis
            lowLevelChart
            top={yMax}
            scale={xScale}
            orientation="bottom"
            tickFormat={(y) => `${y}%`}
          />
          <Axis orientation="left" lowLevelChart scale={yScale} />
        </Group>
      </svg>
      <div className="flex items-center justify-evenly absolute top-8 w-full space-y-2">
        <div className="flex">
          <Legend
            scaleType="ordinal"
            scale={colorScale}
            labelFormat={(d) => intl.formatMessage({ id: d })}
          />
        </div>
      </div>
      {tooltipOpen && tooltipData?.data && (
        <TooltipWithBounds
          top={tooltipTop}
          left={tooltipLeft}
          style={{
            ...defaultStyles,
            background:
              theme.color === Themes.LIGHT
                ? getColor('WHITE')
                : getColor('NEUTRAL900'),
          }}
        >
          <div style={{ color: colorScale(tooltipData.key) }}>
            <strong>
              <FormattedMessage id={tooltipData.key as IntlMessageKeys} />
            </strong>
          </div>
          <div
            className="dark:text-neutral-200 "
            data-test-id="report-compliance-tooltip"
          >
            <div>
              <FormattedMessage id="Compliance" />:{' '}
              {`${tooltipData.data[tooltipData.key].toFixed(2)}%`}
            </div>
          </div>
        </TooltipWithBounds>
      )}
    </div>
  );
}

export default function ResponsiveBarChart(props: ResponsiveBarChart) {
  return (
    <ParentSize>
      {({ height, width }) => (
        <BarChart {...props} width={width} height={height} />
      )}
    </ParentSize>
  );
}
