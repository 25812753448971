import Button from 'generic/components/Form/Button';
import type { ButtonProps } from 'generic/components/Form/Button/Button';
import Tooltip from 'generic/components/Tooltip';

interface CanvasButtonProps extends ButtonProps {
  active?: boolean;
  tooltip: React.ReactNode;
  children: React.ReactNode;
}

export default function CanvasButton({
  ref,
  tooltip,
  active,
  children,
  ...rest
}: CanvasButtonProps) {
  return (
    <Tooltip
      content={
        <Button
          className={`size-10 ${
            active
              ? 'bg-primary-600 dark:bg-primary-700 disabled:hover:bg-primary-600 dark:disabled:hover:bg-primary-700 text-white'
              : 'bg-primary-200 dark:bg-primary-400 dark:text-white hover:bg-primary-600 dark:hover:bg-primary-700 disabled:hover:bg-primary-200 dark:disabled:hover:bg-primary-400 text-primary-500 hover:text-white disabled:hover:text-primary-500 dark:disabled:hover:text-white'
          } focus:outline-none rounded-full shadow-md flex items-center justify-center`}
          {...rest}
        >
          {children}
        </Button>
      }
    >
      {tooltip}
    </Tooltip>
  );
}
