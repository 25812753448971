import {
  type MeetingRoomsCapacityOccupancyHistory,
  useRoomSizeOccupancyHistoryQuery,
} from 'graphql/types';
import { useIntl } from 'translations/Intl';
import useReportFilter from 'utils/graphql/useReportFilter';
import BarChart from '../BarChart';
import ReportingPage from '../ReportingPage';
import LineChart from './components/LineChart';

export default function ReportRoomSize() {
  const reportFilter = useReportFilter();
  const intl = useIntl();
  const [{ data, fetching }] = useRoomSizeOccupancyHistoryQuery(reportFilter);

  const typedData = (data?.ReportCharts[0]?.Data ?? { data: [] }) as {
    data: MeetingRoomsCapacityOccupancyHistory[];
  };

  const occupancyData = typedData.data.sort(
    (a, b) => a.Occupancy - b.Occupancy,
  );

  const capacityData = occupancyData.map((o) => ({
    numberOfPeople: o.Occupancy,
    occupancyPercent: o.DurationPercent,
    capacity: o.Capacity,
  }));

  // Remove duplicates
  const filteredOccupancyData = [
    ...new Map(
      occupancyData
        .map((d) => ({
          Capacity: d.Capacity,
          AvgOccupancy: d.AvgOccupancy,
          AvgOccupancyAll: d.AvgOccupancyAll,
        }))
        .sort((a, b) => a.Capacity - b.Capacity)
        .map((item) => [item.Capacity, item]),
    ).values(),
  ];

  return (
    <ReportingPage
      loading={fetching}
      reportPageId={data?.ReportCharts[0]?.ReportPageId}
      reportTitle="Meeting rooms size"
      kpi={intl.formatMessage({
        id: 'Is the size of the meeting rooms correct?',
      })}
    >
      <div className="h-96 w-full">
        <BarChart
          data={filteredOccupancyData}
          yScaleAccessor={(d) => d.AvgOccupancy}
          yScaleLabel={intl.formatMessage({ id: 'Average person occupancy' })}
          groupAccessor={(d) => d.Capacity.toString()}
          xAxisLabel={intl.formatMessage({ id: 'Capacity' })}
          averageValue={occupancyData?.[0]?.AvgOccupancyAll}
          averageLabel={intl.formatMessage({ id: 'Average person occupancy' })}
          usePercentage={false}
        />
      </div>
      <div className="h-96 w-full">
        <LineChart data={capacityData} />
      </div>
    </ReportingPage>
  );
}
