import useStore from '@/model/store';
import { FaroErrorBoundary } from '@grafana/faro-react';
import { Themes } from 'common/types';
import { IntlProvider } from 'react-intl';
import { Outlet } from 'react-router-dom';
import { Toaster } from 'sonner';
import translations from './translations';

export default function App() {
  const language = useStore((state) => state.userSettings.language);
  const theme = useStore((state) => state.userSettings.theme);

  return (
    <FaroErrorBoundary>
      <IntlProvider locale={language} messages={translations[language]}>
        <Toaster
          position="top-right"
          closeButton
          theme={theme.color === Themes.DARK ? 'dark' : 'light'}
          toastOptions={{
            classNames: {
              toast:
                'bg-neutral-50/90 dark:bg-neutral-600/90 backdrop-blur font-sans',
              error: '[&>[data-icon]]:!text-red-500',
              info: '[&>[data-icon]]:!text-blue-500',
              success: '[&>[data-icon]]:!text-green-500',
              warning: '[&>[data-icon]]:!text-yellow-500',
              closeButton: 'dark:!bg-neutral-700',
            },
          }}
        />
        <Outlet />
      </IntlProvider>
    </FaroErrorBoundary>
  );
}
