import {
  CloseButton,
  Dialog,
  DialogBackdrop,
  DialogPanel,
  Transition,
} from '@headlessui/react';
import type { ReactNode } from 'react';
import { HiOutlineXMark } from 'react-icons/hi2';
import useDeviceDetect from 'utils/useDeviceDetect';
import Button from '../Form/Button';

interface FooterProps {
  children: ReactNode;
}

function Footer({ children }: FooterProps): React.JSX.Element {
  return (
    <div className="border-t border-neutral-200 dark:border-neutral-700 px-4 py-3 flex items-center justify-between">
      {children}
    </div>
  );
}

interface PanelProps {
  children: ReactNode;
  open: boolean;
  setOpen: (open: boolean) => void;
  title: string | ReactNode;
  className?: string;
  isRelative?: boolean;
}

function Panel({
  open,
  setOpen,
  title,
  className,
  children,
  isRelative,
  ...rest
}: PanelProps) {
  const { isMobile } = useDeviceDetect();

  if (isRelative) {
    return (
      <Transition
        show={open}
        enter="transition ease-in-out duration-500 sm:duration-700"
        enterFrom="translate-x-full"
        enterTo="translate-x-0"
        leave="transition ease-in-out duration-500 sm:duration-700"
        leaveFrom="translate-x-0"
        leaveTo="translate-x-full"
      >
        <div
          className={
            isMobile ? 'relative' : 'absolute top-0 bottom-0 right-0 z-20'
          }
          data-test-id="panel"
          {...rest}
        >
          <div
            className={`ml-auto relative max-w-md w-full h-full bg-white dark:bg-neutral-800 backdrop-blur bg-opacity-90 pt-4 flex flex-col border-neutral-200 dark:border-neutral-700 ${
              isMobile ? 'border rounded-md' : 'border-l rounded-r-2xl'
            }`}
          >
            <div className="px-4 flex items-center justify-between">
              <h2 className="text-lg font-medium text-neutral-900 dark:text-neutral-100">
                {title}
              </h2>
              <Button
                data-test-id="close-panel"
                className="-mr-2 size-10 bg-white dark:bg-neutral-900 backdrop-blur bg-opacity-90 p-2 rounded-md flex items-center justify-center text-neutral-400"
                onClick={() => setOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <HiOutlineXMark className="size-6" aria-hidden="true" />
              </Button>
            </div>
            <div
              className={`flex flex-col h-full justify-between mt-4 border-t border-neutral-200 dark:border-neutral-700 px-4 py-3 overflow-y-auto ${className ?? ''}`}
            >
              {children}
            </div>
          </div>
        </div>
      </Transition>
    );
  }
  return (
    <Dialog
      open={open}
      className="fixed inset-0 flex z-40"
      data-test-id="panel"
      onClose={setOpen}
      {...rest}
    >
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-black/30 duration-700 opacity-100 transition-opacity ease-in-out data-[closed]:opacity-0 "
      />
      <DialogPanel
        transition
        className="ml-auto relative max-w-md w-full h-full bg-white dark:bg-neutral-800 backdrop-blur bg-opacity-90 pt-4 flex flex-col shadow-xl transition ease-in-out duration-700 data-[enter]:duration-0 data-[enter]:translate-x-full data-[leave]:duration-700 data-[leave]:data-[closed]:translate-x-full"
      >
        <div className="px-4 flex items-center justify-between">
          <h2 className="text-lg font-medium text-neutral-900 dark:text-neutral-100">
            {title}
          </h2>
          <CloseButton
            data-test-id="close-panel"
            className="-mr-2 size-10 bg-white dark:bg-neutral-900 backdrop-blur bg-opacity-90 p-2 rounded-md flex items-center justify-center text-neutral-400"
            as="button"
          >
            <span className="sr-only">Close menu</span>
            <HiOutlineXMark className="size-6" aria-hidden="true" />
          </CloseButton>
        </div>
        <div
          className={`flex flex-col h-full justify-between mt-4 border-t border-neutral-200 dark:border-neutral-700 px-4 py-3 overflow-y-auto ${className ?? ''}`}
        >
          {children}
        </div>
      </DialogPanel>
    </Dialog>
  );
}

Panel.Footer = Footer;
export default Panel;
