import { type MarginProps, Themes } from '@/common/types';
import Axis from '@/generic/components/Chart/Axis';
import Legend from '@/generic/components/Chart/Legend';
import useStore from '@/model/store';
import { REPORTING_RIGHT_MARGIN } from '@/pages/ReportingView/components/Reports/Reports';
import localize from '@/utils/format';
import getColor from '@/utils/getColor';
import GridRows from '@visx/grid/lib/grids/GridRows';
import { Group } from '@visx/group';
import { ParentSize } from '@visx/responsive';
import { scaleBand, scaleLinear, scaleOrdinal } from '@visx/scale';
import { BarStack } from '@visx/shape';
import type { SeriesPoint } from '@visx/shape/lib/types';
import { TooltipWithBounds, defaultStyles, useTooltip } from '@visx/tooltip';
import AnimatedRect from 'generic/components/Chart/AnimatedRect';
import { useMemo } from 'react';
import {
  FormattedMessage,
  type IntlMessageKeys,
  useIntl,
} from 'translations/Intl';

let tooltipTimeout: NodeJS.Timeout;

interface Data {
  date: Date;
  warmPercentage: number;
  hotPercentage: number;
}

interface ResponsiveBarChart {
  margin?: MarginProps;
  data: Data[];
}

interface BarChart extends ResponsiveBarChart {
  height: number;
  width: number;
}

type TooltipData = {
  bar: SeriesPoint<Data>;
  key: keyof Omit<Data, 'date'>;
  index: number;
  height: number;
  width: number;
  x: number;
  y: number;
  color: string;
};

// accessors
const getDate = (d: Data) => d.date.toString();

function BarChart({
  height,
  width,
  margin = {
    top: 60,
    left: 70,
    right: REPORTING_RIGHT_MARGIN,
    bottom: 40,
  },
  data,
}: BarChart) {
  const intl = useIntl();
  const theme = useStore((state) => state.userSettings.theme);
  const datesRange = useMemo(() => data.map((d) => d.date.toString()), [data]);
  const keys: (keyof Omit<Data, 'date'>)[] = [
    'hotPercentage',
    'warmPercentage',
  ];

  // bounds
  const xMax = Math.max(width - margin.left - margin.right, 0);
  const yMax = height - margin.top - margin.bottom;

  // scales
  const xScale = scaleBand<string>({
    range: [0, xMax],
    domain: datesRange,
    paddingInner: 0.2,
  });

  const yScale = scaleLinear<number>({
    range: [yMax, 0],
    domain: [0, 100],
    nice: true,
  });

  const colorScale = scaleOrdinal({
    domain: keys,
    range: [getColor('RED'), getColor('YELLOW')],
  });

  const {
    tooltipOpen,
    tooltipLeft,
    tooltipTop,
    tooltipData,
    hideTooltip,
    showTooltip,
  } = useTooltip<TooltipData>();

  return (
    <div className="relative">
      <svg width={width} height={height}>
        <Group top={margin.top} left={margin.left}>
          <GridRows
            numTicks={10}
            scale={yScale}
            width={xMax}
            height={yMax}
            strokeDasharray="1,3"
            stroke={getColor('NEUTRAL600')}
            strokeOpacity={0.6}
          />
          <BarStack
            data={data}
            keys={keys}
            x={getDate}
            xScale={xScale}
            yScale={yScale}
            color={colorScale}
          >
            {(barStacks) =>
              barStacks.map((barStack) =>
                barStack.bars.map((bar) => (
                  <AnimatedRect
                    data-test-id={`bar-occupancy-${barStack.key}-${bar.bar.data.date}`}
                    bar={bar}
                    key={`bar-stack-${barStack.index}-${bar.index}`}
                    onMouseMove={(event) => {
                      if (tooltipTimeout) clearTimeout(tooltipTimeout);
                      const left = bar.x + bar.width / 2;
                      showTooltip({
                        tooltipData: bar,
                        tooltipTop: event?.y,
                        tooltipLeft: left,
                      });
                    }}
                    onMouseOut={() => {
                      tooltipTimeout = setTimeout(() => {
                        hideTooltip();
                      }, 300);
                    }}
                  />
                )),
              )
            }
          </BarStack>
          <Axis
            lowLevelChart
            top={yMax}
            scale={xScale}
            numTicks={width > 768 ? 10 : 2}
            tickFormat={(v: string) => localize(new Date(v), 'eeeeee do LLL')}
            orientation="bottom"
          />
          <Axis
            orientation="left"
            lowLevelChart
            tickFormat={(y) => `${y}%`}
            scale={yScale}
            label={intl.formatMessage({ id: 'Occupancy' })}
          />
        </Group>
      </svg>
      <div className="flex items-center justify-evenly absolute top-8 w-full space-y-2">
        <div className="flex">
          <Legend
            scaleType="ordinal"
            labelFormat={(d) =>
              intl.formatMessage({
                id: d,
              })
            }
            scale={colorScale}
          />
        </div>
      </div>
      {tooltipOpen && tooltipData && (
        <TooltipWithBounds
          top={tooltipTop}
          left={tooltipLeft}
          style={{
            ...defaultStyles,
            background:
              theme.color === Themes.LIGHT
                ? getColor('WHITE')
                : getColor('NEUTRAL900'),
          }}
        >
          <div style={{ color: colorScale(tooltipData.key) }}>
            <strong>
              <FormattedMessage id={tooltipData.key as IntlMessageKeys} />{' '}
            </strong>
          </div>
          <div
            className="dark:text-neutral-200"
            data-test-id="report-flex-bar-tooltip"
          >
            {`${tooltipData.bar.data[tooltipData.key].toFixed(2)}%`}
          </div>
          <div className="dark:text-neutral-200">
            <small>{localize(new Date(tooltipData.bar.data.date))}</small>
          </div>
        </TooltipWithBounds>
      )}
    </div>
  );
}

export default function ResponsiveBarChart(props: ResponsiveBarChart) {
  return (
    <ParentSize>
      {({ height, width }) => (
        <BarChart {...props} width={width} height={height} />
      )}
    </ParentSize>
  );
}
