interface BadgeProps {
  messageLength?: number;
  className?: string;
}

export default function Badge({ messageLength, className = '' }: BadgeProps) {
  return messageLength ? (
    <div
      className={`bg-primary-500 rounded-full text-white text-xs flex items-center justify-center ${
        messageLength > 9 ? 'size-6' : 'size-5'
      } ${className ?? ''}`}
    >
      {messageLength > 9 ? '9+' : messageLength}
    </div>
  ) : null;
}
