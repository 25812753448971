const getTrackConfig = ({
  error,
  source,
  target,
  disabled,
}: {
  error: boolean;
  source: Destination;
  target: Destination;
  disabled: boolean;
}) => {
  const basicStyle = {
    left: `${source.percent}%`,
    width: `calc(${target.percent - source.percent}% - 1px)`,
  };

  if (disabled) return basicStyle;

  const coloredTrackStyle = error
    ? {
        backgroundColor: 'rgba(214,0,11,0.5)',
        borderLeft: '1px solid rgba(214,0,11,0.5)',
        borderRight: '1px solid rgba(214,0,11,0.5)',
      }
    : {
        backgroundColor: 'rgba(98, 203, 102, 0.5)',
        borderLeft: '1px solid #62CB66',
        borderRight: '1px solid #62CB66',
      };

  return { ...basicStyle, ...coloredTrackStyle };
};

export type Destination = {
  id?: string;
  value: number;
  percent: number;
};

interface TrackProps {
  source: Destination;
  target: Destination;
  getTrackProps: () => any;
  disabled?: boolean;
  error?: boolean;
  className?: string;
}

export default function Track({
  source,
  target,
  getTrackProps,
  disabled = false,
  error = false,
  className = '',
}: TrackProps) {
  return (
    <div
      className={`react_time_range__track${
        disabled ? '__disabled' : ''
      } ${className ?? ''}`}
      style={getTrackConfig({ error, source, target, disabled })}
      {...getTrackProps()}
    />
  );
}
