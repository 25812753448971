import { type MarginProps, Themes } from '@/common/types';
import Axis from '@/generic/components/Chart/Axis';
import Legend from '@/generic/components/Chart/Legend';
import useStore from '@/model/store';
import { REPORTING_RIGHT_MARGIN } from '@/pages/ReportingView/components/Reports/Reports';
import getColor from '@/utils/getColor';
import GridRows from '@visx/grid/lib/grids/GridRows';
import { Group } from '@visx/group';
import { ParentSize } from '@visx/responsive';
import { scaleBand, scaleLinear, scaleOrdinal } from '@visx/scale';
import { BarGroup, BarStack } from '@visx/shape';
import { TooltipWithBounds, defaultStyles, useTooltip } from '@visx/tooltip';
import AnimatedRect from 'generic/components/Chart/AnimatedRect';
import type { RoomClimateHistory } from 'graphql/types';
import {
  FormattedMessage,
  type IntlMessageKeys,
  useIntl,
} from 'translations/Intl';
import { lower } from 'utils/date';
import format from 'utils/format';
import useReportFilter from 'utils/graphql/useReportFilter';

type TooltipData = {
  data?: Pick<
    RoomClimateHistory,
    'GoodPercentageAll' | 'AcceptablePercentageAll'
  >;
  key: 'AcceptablePercentageAll' | 'GoodPercentageAll';
  date?: string;
};

interface ResponsiveStackedBarChart {
  margin?: MarginProps;
  data: RoomClimateHistory[];
}

interface StackedBarChart extends ResponsiveStackedBarChart {
  height: number;
  width: number;
}

interface ChartData {
  [key: number]: number; // Sensor type
  date: string;
}

const getDate = (d: ChartData) => d.date;

function StackedBarChart({
  height,
  width,
  margin = {
    top: 60,
    left: 70,
    right: REPORTING_RIGHT_MARGIN,
    bottom: 60,
  },
  data,
}: StackedBarChart) {
  const intl = useIntl();
  const theme = useStore((state) => state.userSettings.theme);
  const { variables } = useReportFilter();

  const sensorTypeOccupancyData = data?.flatMap((d) => [
    {
      [d.SensorType]: d.AcceptablePercentage,
      date: format(
        variables.Period ? lower(variables.Period) : new Date(),
        'LLL y',
      ),
    },
  ]);

  const chartData: ChartData[] = [
    sensorTypeOccupancyData?.reduce(
      (r, c) => Object.assign(r, c),
      {} as ChartData,
    ) ?? {
      date: format(new Date(), 'LLL y'),
    },
  ];

  const keys = Object.keys(chartData[0] ?? {}).filter((d) => d !== 'date');
  const sensorTypeKeys: (keyof (typeof data)[number])[] = [
    'GoodPercentageAll',
    'AcceptablePercentageAll',
  ];

  // Bounds
  const xMax = Math.max(width - margin.left - margin.right, 0);
  const yMax = height - margin.top - margin.bottom;

  const dateScale = scaleBand<string>({
    domain: chartData.map(getDate),
    padding: 0.2,
    range: [0, xMax],
  });

  const sensorTypeScale = scaleBand<string>({
    domain: keys,
    padding: 0.1,
    range: [0, dateScale.bandwidth()],
  });

  const occupancyScale = scaleLinear<number>({
    domain: [0, 100],
    range: [yMax, 0],
  });

  const colorScale = scaleOrdinal({
    // If it is not cast as string then BarGroup expects the wrong type for "keys" variable
    domain: sensorTypeKeys as string[],
    range: [getColor('GREEN'), getColor('YELLOW')],
  });

  // It will add the sensor types to the color scale if it isn't copied
  const colorScaleCopy = colorScale.copy();

  const {
    tooltipOpen,
    tooltipLeft,
    tooltipTop,
    tooltipData,
    hideTooltip,
    showTooltip,
  } = useTooltip<TooltipData>();

  return (
    <div className="relative">
      <svg width={width} height={height}>
        <Group top={margin.top} left={margin.left}>
          <GridRows
            numTicks={10}
            scale={occupancyScale}
            width={xMax}
            height={yMax}
            strokeDasharray="1,3"
            stroke={getColor('NEUTRAL600')}
            strokeOpacity={0.6}
          />
          <BarGroup
            data={chartData}
            keys={keys}
            height={yMax}
            x0={getDate}
            x0Scale={dateScale}
            x1Scale={sensorTypeScale}
            yScale={occupancyScale}
            color={colorScale}
          >
            {(barGroups) =>
              barGroups.map((barGroup) => (
                <Group
                  key={`flex-bar-group-${barGroup.index}-${barGroup.x0}`}
                  left={barGroup.x0}
                >
                  {barGroup.bars.map((bar) => {
                    return (
                      <BarStack
                        key={bar.index}
                        data={[
                          // Just select the first element, as all rooms have the same "All" values
                          data.find((d) => d.SensorType === bar.key) ?? {
                            SensorType: bar.key,
                            AcceptablePercentageAll: 0,
                            GoodPercentageAll: 0,
                          },
                        ]}
                        keys={sensorTypeKeys}
                        x={(d) => d.SensorType}
                        xScale={sensorTypeScale}
                        yScale={occupancyScale}
                        color={colorScale}
                      >
                        {(barStacks) =>
                          barStacks.map((barStack) =>
                            barStack.bars.map((bar) => (
                              <AnimatedRect
                                bar={bar}
                                key={`bar-stack-${barStack.index}-${bar.index}`}
                                data-test-id={`bar-occupancy-${barStack.key}-${bar.bar.data.SensorType}`}
                                onMouseMove={(event) => {
                                  const eventData = data.find(
                                    (d) =>
                                      d.SensorType === bar.bar.data.SensorType,
                                  );

                                  if (
                                    eventData &&
                                    (barStack.key ===
                                      'AcceptablePercentageAll' ||
                                      barStack.key === 'GoodPercentageAll')
                                  ) {
                                    const {
                                      GoodPercentageAll,
                                      AcceptablePercentageAll,
                                    } = eventData;
                                    showTooltip({
                                      tooltipData: {
                                        data: {
                                          GoodPercentageAll,
                                          AcceptablePercentageAll,
                                        },
                                        key: barStack.key,
                                        date: chartData[barGroup.index]?.date,
                                      },
                                      tooltipTop: event?.y,
                                      tooltipLeft: event?.x,
                                    });
                                  }
                                }}
                                onMouseOut={hideTooltip}
                              />
                            )),
                          )
                        }
                      </BarStack>
                    );
                  })}
                  <Axis
                    lowLevelChart
                    top={yMax}
                    scale={sensorTypeScale}
                    tickFormat={(d) => intl.formatMessage({ id: d })}
                    orientation="bottom"
                  />
                </Group>
              ))
            }
          </BarGroup>
          <Axis
            lowLevelChart
            top={yMax}
            scale={dateScale}
            orientation="bottom"
            tickProps={{ dy: 20 }}
          />
          <Axis
            orientation="left"
            lowLevelChart
            scale={occupancyScale}
            tickFormat={(y) => `${y}%`}
            label={intl.formatMessage({ id: 'Compliance' })}
          />
        </Group>
      </svg>
      <div className="flex items-center justify-evenly absolute top-8 w-full space-y-2">
        <div className="flex">
          <Legend
            scaleType="ordinal"
            scale={colorScaleCopy}
            labelFormat={(d) => intl.formatMessage({ id: d })}
          />
        </div>
      </div>
      {tooltipOpen && tooltipData?.data && (
        <TooltipWithBounds
          top={tooltipTop}
          left={tooltipLeft}
          style={{
            ...defaultStyles,
            background:
              theme.color === Themes.LIGHT
                ? getColor('WHITE')
                : getColor('NEUTRAL900'),
          }}
        >
          <div style={{ color: colorScale(tooltipData.key) }}>
            <strong>
              <FormattedMessage id={tooltipData.key as IntlMessageKeys} />
            </strong>
          </div>
          <div
            className="dark:text-neutral-200 "
            data-test-id="report-compliance-tooltip"
          >
            <div>
              <FormattedMessage id="Compliance" />:{' '}
              {`${tooltipData.data[tooltipData.key].toFixed(2)}%`}
            </div>
          </div>
          <div className="dark:text-neutral-200">
            <small>{tooltipData.date}</small>
          </div>
        </TooltipWithBounds>
      )}
    </div>
  );
}

export default function ResponsiveStackedBarChart(
  props: ResponsiveStackedBarChart,
) {
  return (
    <ParentSize>
      {({ height, width }) => (
        <StackedBarChart {...props} width={width} height={height} />
      )}
    </ParentSize>
  );
}
