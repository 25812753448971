import {
  Action,
  type Floor,
  GeometryType,
  type RoomFeatures,
  type RoomToRemove,
  RoomTypes,
} from '@/common/types';
import Button from '@/generic/components/Form/Button';
import Input from '@/generic/components/Form/Input';
import Select from '@/generic/components/Form/Select';
import ModalFooter from '@/generic/components/ModalFooter';
import Panel from '@/generic/components/Panel';
import {
  type LabelPartsFragment,
  type RoomTypePartsFragment,
  useInsertRoomMutation,
  useLabelsQuery,
  useRoomSensorsQuery,
  useRoomTypesQuery,
  useUpdateRoomMutation,
} from '@/graphql/types';
import useHasuraHeader, {
  HasuraPermissions,
} from '@/utils/graphql/useHasuraHeaders';
import useToast from '@/utils/graphql/useToast';
import Accordion from 'generic/components/Accordion';
import Switch from 'generic/components/Form/Switch/Switch';
import Tooltip from 'generic/components/Tooltip';
import Transition from 'generic/components/Transition';
import useStore from 'model/store';
import type OLMap from 'ol/Map';
import type Polygon from 'ol/geom/Polygon';
import { useEffect, useMemo, useState } from 'react';
import {
  HiOutlineCheckBadge,
  HiOutlineCube,
  HiOutlineExclamationCircle,
  HiOutlineInformationCircle,
  HiOutlineTrash,
} from 'react-icons/hi2';
import { TfiRulerAlt } from 'react-icons/tfi';
import { Link } from 'react-router-dom';
import {
  FormattedMessage,
  type IntlMessageKeys,
  useIntl,
} from 'translations/Intl';
import renderSensorIcon from 'utils/renderSensorIcon';
import { beaconsLayer } from '../../mapElements';
import { getIntersectedElements } from '../../utils/helpers';
import AAWSelection from './components/AAWSelection';
import PrivateRoomModal from './components/PrivateRoomModal';
import RemoveRoomModal from './components/RemoveRoomModal';
import RoomLabelInput from './components/RoomLabelInput';

interface AddRoomCardProps {
  open: boolean;
  floor: Floor;
  geometry?: Polygon | null;
  selectedRoom?: RoomFeatures;
  setSelectedRoom: (selectedRoom?: RoomFeatures) => void;
  setIsAddingRoom: (isAddingRoom: boolean) => void;
  setIsSaving: (isSaving: boolean) => void;
  map: OLMap;
  onClose: (isSaving: boolean) => void;
  activateMeasureDistanceInteraction: boolean;
  setActivateMeasureDistanceInteraction: (checked: boolean) => void;
}

export default function AddRoomCard({
  open,
  floor,
  geometry,
  selectedRoom,
  setSelectedRoom,
  setIsAddingRoom,
  setIsSaving,
  map,
  onClose,
  activateMeasureDistanceInteraction,
  setActivateMeasureDistanceInteraction,
}: AddRoomCardProps): React.JSX.Element {
  const intl = useIntl();
  const toast = useToast();
  const hasuraHeader = useHasuraHeader();
  const AAWActivated = useStore((state) => state.AAWActivated);
  const userRoles = useStore((state) => state.user)?.roles;
  const [{ data: labelData }] = useLabelsQuery({
    context: useMemo(() => ({ additionalTypenames: ['Labels'] }), []),
  });
  const [aloneAtWorkIsActivated, setAloneAtWorkIsActivated] = useState(false);
  const [privateRoom, setPrivateRoom] = useState(false);
  const [openPrivateRoomWarning, setOpenPrivateRoomWarning] = useState(false);
  const [roomName, setRoomName] = useState(
    selectedRoom?.getProperties().Name ?? '',
  );
  const [roomType, setRoomType] = useState<RoomTypePartsFragment | undefined>(
    undefined,
  );
  const [roomTypes, setRoomTypes] = useState<RoomTypePartsFragment[]>([]);
  const [roomCapacity, setRoomCapacity] = useState(
    selectedRoom?.getProperties().Capacity ?? 0,
  );
  const roomGeometry = useMemo(
    () => selectedRoom?.getProperties().Geometry ?? geometry,
    [selectedRoom, geometry],
  );
  const [roomLabelsToAdd, setRoomLabelsToAdd] = useState<string[] | null>([]);
  const [roomLabelsToDelete, setRoomLabelsToDelete] = useState<
    LabelPartsFragment[]
  >([]);

  const [roomToRemove, setRoomToRemove] = useState<RoomToRemove | null>(null);
  const isNew = useMemo(() => !selectedRoom, [selectedRoom]);
  const [{ data }] = useRoomTypesQuery();

  const [{ data: roomSensorData }] = useRoomSensorsQuery({
    variables: {
      RoomName: selectedRoom?.getProperties().Name ?? '',
      FloorId: floor.Id,
    },
    requestPolicy: 'network-only', // to ensure having correct sensors information
    pause: isNew || !selectedRoom?.getProperties().Name,
  });

  const AAWRequiresFloorDistance = useMemo(
    () =>
      aloneAtWorkIsActivated &&
      (floor.GeometryUnitPerMeter === undefined ||
        floor.GeometryUnitPerMeter === null),
    [floor, aloneAtWorkIsActivated],
  );

  const roomSensors = useMemo(
    () =>
      !isNew
        ? roomSensorData?.MqttBeacons.map((mb) => ({
            beacon: mb.Name,
            sensors: mb.Sensors.sort((a, b) => a.Index - b.Index).map((s) => ({
              value: s.Value,
              index: s.Index,
              sensorType: s.SensorType.Name,
              unit: s.SensorType.Unit,
            })),
          }))
        : undefined,
    [roomSensorData?.MqttBeacons, isNew],
  );

  useEffect(() => {
    const { Name, RoomType, Capacity, IsPrivate, AloneAtWorkActivated } =
      selectedRoom?.getProperties() ?? {};

    // Update form values
    if (Name) {
      setRoomName(Name);
    } else {
      setRoomName('');
    }

    if (data) {
      setRoomType(RoomType ?? roomTypes[0]);
    } else {
      setRoomType(RoomType ?? undefined);
    }
    setRoomCapacity(Capacity ?? 1);
    setPrivateRoom(IsPrivate ?? false);
    setAloneAtWorkIsActivated(AloneAtWorkActivated ?? false);
  }, [data, roomTypes[0], selectedRoom]);

  const [{ fetching: isAdding }, addRoom] = useInsertRoomMutation();
  const [{ fetching: isUpdating }, updateRoom] = useUpdateRoomMutation();

  useEffect(() => {
    if (data) {
      setRoomTypes(data.RoomTypes);
      if (!selectedRoom) {
        setRoomType(data.RoomTypes[0]);
      }
    }
  }, [data, selectedRoom]);

  useEffect(() => {
    if (!open) {
      setRoomName('');
      setPrivateRoom(false);
      setRoomCapacity(1);
    }
  }, [open]);

  const privateRoomDisabled = useMemo(() => {
    // New rooms that have at least two intersecting beacons can be set as private
    if (
      isNew &&
      geometry &&
      beaconsLayer.features &&
      getIntersectedElements(geometry, beaconsLayer.features).length > 1
    ) {
      return false;
    }
    if (privateRoom) {
      return (roomSensors?.length ?? 0) < 2;
    }
    return false;
  }, [privateRoom, roomSensors, isNew, geometry]);

  return (
    <>
      <Panel
        open={open}
        setOpen={onClose}
        isRelative
        data-test-id="add-room-panel"
        className="w-full min-w-fit md:w-[350px]"
        title={
          <div className="flex flex-row items-center space-x-4 flex-wrap gap-4">
            {isNew ? (
              <FormattedMessage id="Add Room" />
            ) : (
              <>
                <FormattedMessage id="Edit Room" />
                <Button
                  id={`remove-${selectedRoom?.getProperties().Name}`}
                  data-test-id={`remove-${selectedRoom?.getProperties().Name}`}
                  className="transition-all p-1 rounded-full bg-primary-200 dark:bg-primary-400 dark:text-white hover:bg-primary-600 dark:hover:bg-primary-700 disabled:hover:bg-primary-200 text-primary-500 hover:text-white disabled:hover:text-primary-500"
                  onClick={() => {
                    if (selectedRoom) {
                      setRoomToRemove({
                        Id: selectedRoom.getProperties().Id,
                        Name: selectedRoom.getProperties().Name,
                        IsPrivate: selectedRoom.getProperties().IsPrivate,
                        Floor: {
                          Number: floor.Number,
                        },
                      });
                      setIsAddingRoom(false);
                      onClose(false);
                    }
                  }}
                  title={intl.formatMessage({
                    id: 'Remove',
                  })}
                >
                  <HiOutlineTrash className="size-5" />
                </Button>
              </>
            )}
          </div>
        }
      >
        <div id="add-room-card" className="flex flex-col gap-2">
          <Input
            type="text"
            label={intl.formatMessage({
              id: 'Room name',
            })}
            placeholder={intl.formatMessage({
              id: 'Room name',
            })}
            data-test-id="room-name"
            value={roomName}
            renderIcon={({ className }) => (
              <HiOutlineCube className={className} />
            )}
            onChangeValue={(v) => setRoomName(v)}
            required
          />

          <Select
            value={roomTypes.filter((rt) => rt.Name === roomType?.Name)[0]}
            label="Room type"
            dataTestId="select-room-type"
            onChangeSelected={(selected) => selected && setRoomType(selected)}
            options={roomTypes}
            isDeselectable={false}
            renderValue={(type) => type?.Name ?? ''}
            keyParameter="Id"
            required
          />

          <RoomLabelInput
            key={selectedRoom?.getProperties().Id}
            setRoomLabelsToDelete={setRoomLabelsToDelete}
            setRoomLabelsToAdd={setRoomLabelsToAdd}
            roomId={selectedRoom?.getProperties().Id}
          />
          <AAWSelection
            aloneAtWorkIsActivated={aloneAtWorkIsActivated}
            setAloneAtWorkIsActivated={setAloneAtWorkIsActivated}
            show={roomType?.Name === RoomTypes.DESKS}
          />
          <Transition show={roomType?.Name === RoomTypes.MEETING}>
            <Input
              type="number"
              label={intl.formatMessage({
                id: 'Capacity',
              })}
              data-test-id="room-capacity"
              min={0}
              renderIcon={({ className }) => (
                <HiOutlineCube className={className} />
              )}
              required
              value={roomCapacity}
              onChangeValue={(v) =>
                setRoomCapacity(v !== '' ? Number.parseInt(v, 10) : 0)
              }
            />
          </Transition>
          <Transition show={roomType?.Name === RoomTypes.DESKS}>
            <Switch
              data-test-id="privacy-mode"
              isEnabled={privateRoom}
              onSetEnable={() => {
                if (privateRoom && !isNew) {
                  // Open a warning before removing private
                  setOpenPrivateRoomWarning(true);
                } else {
                  setPrivateRoom(!privateRoom);
                }
              }}
              label={
                <div className="flex space-x-1 items-center">
                  <div>
                    <FormattedMessage id="Private room" />
                  </div>
                  <Tooltip>
                    <FormattedMessage id="Private room description" />
                  </Tooltip>
                </div>
              }
              labelClassName="text-base md:text-sm"
            />
          </Transition>

          <Transition
            show={!!roomGeometry}
            className="flex flex-row items-center"
          >
            <HiOutlineCheckBadge className="mr-2 size-6 text-green-500" />

            <div className="block text-sm  text-neutral-700 dark:text-white">
              <FormattedMessage id="Drawn successfully" />
            </div>
          </Transition>

          <Transition
            show={!roomGeometry}
            className="flex flex-row items-center"
          >
            <HiOutlineExclamationCircle className="shrink-0 mr-2 size-6 text-red-500" />
            <div className="block text-sm  text-neutral-700 dark:text-white">
              <FormattedMessage id="Missing drawing" />
            </div>
          </Transition>
          <Transition
            show={privateRoomDisabled}
            className="flex flex-row items-center"
          >
            <HiOutlineExclamationCircle className="shrink-0 mr-2 size-6 text-red-500" />
            <div
              data-test-id="private-room-warning-beacon-number"
              className="block text-sm  text-neutral-700 dark:text-white"
            >
              <FormattedMessage id="Missing beacon for private room" />
            </div>
          </Transition>

          <div className="flex flex-row items-center">
            <HiOutlineInformationCircle className="shrink-0 mr-2 size-6 text-orange-300" />
            <div className="block text-sm  text-neutral-700 dark:text-white">
              <FormattedMessage id="Hold 'Ctrl' key in order to draw straight lines" />
            </div>
          </div>

          <Transition show={!!selectedRoom}>
            <div className="flex flex-row items-center">
              <HiOutlineInformationCircle className="shrink-0 mr-2 size-6 text-orange-300" />
              <div className="block text-sm  text-neutral-700 dark:text-white">
                <FormattedMessage id="Hold 'Alt' key in order to delete vertices" />
              </div>
            </div>
          </Transition>

          <Transition
            show={AAWRequiresFloorDistance}
            className="flex flex-row items-center"
          >
            <HiOutlineExclamationCircle className="shrink-0 mr-2 size-6 text-red-500" />
            <div className="block text-sm text-neutral-700 dark:text-white">
              <FormattedMessage id="Missing-Measure" />
              <Button
                onClick={() => {
                  onClose(true);
                  setActivateMeasureDistanceInteraction(
                    !activateMeasureDistanceInteraction,
                  );
                }}
                className="bg-red-200 text-red-500 py-1 px-2 rounded-full flex gap-2"
              >
                <TfiRulerAlt className="w-5 h-5" />
                <FormattedMessage id="Define reference line" />
              </Button>
            </div>
          </Transition>

          <Transition show={!isNew && !!roomSensors?.length}>
            <Accordion
              title={<FormattedMessage id="Linked beacons" />}
              initialStateOpen={false}
            >
              <div className="flex items-center flex-col space-y-2">
                {roomSensors?.map((beacon) => (
                  <div
                    className="flex flex-col border border-neutral-200 dark:border-neutral-700 rounded-md p-2 gap-1 w-full divide-y"
                    key={beacon.beacon}
                  >
                    {userRoles?.includes(HasuraPermissions.VIEW_STATUS) ? (
                      <Link
                        className="text-primary-500"
                        data-test-id="desk-name"
                        to={`/status?beacon=${beacon.beacon}`}
                      >
                        {beacon.beacon}
                      </Link>
                    ) : (
                      <div>{beacon.beacon}</div>
                    )}
                    <div className="pt-2 flex flex-wrap gap-2">
                      {beacon.sensors
                        .sort((a, b) =>
                          a.sensorType.localeCompare(b.sensorType),
                        )
                        .map((sensor) => (
                          <Tooltip
                            key={sensor.sensorType + sensor.index}
                            content={
                              <div className="flex w-fit items-center justify-center rounded-full p-2 focus:outline-none bg-neutral-100 dark:bg-neutral-700 space-x-2">
                                <div>
                                  {renderSensorIcon(
                                    sensor.sensorType,
                                    'size-5',
                                  )}
                                </div>
                                <div>
                                  {sensor.value} {sensor.unit}{' '}
                                  {sensor.index > 0 ? `(${sensor.index})` : ''}
                                </div>
                              </div>
                            }
                          >
                            <FormattedMessage
                              id={sensor.sensorType as IntlMessageKeys}
                            />
                          </Tooltip>
                        ))}
                    </div>
                  </div>
                ))}
              </div>
            </Accordion>
          </Transition>
        </div>
        <Panel.Footer>
          <ModalFooter
            action={isNew ? Action.ADD : Action.UPDATE}
            disabled={
              privateRoomDisabled ||
              roomName === '' ||
              !roomGeometry ||
              AAWRequiresFloorDistance
            }
            isLoading={isAdding || isUpdating}
            onProceed={() => {
              (map.getTarget() as HTMLElement).focus();
              if (roomGeometry) {
                setIsSaving(true);
                if (isNew) {
                  const labels = roomLabelsToAdd?.map((rL) => ({
                    LabelsId: labelData?.Labels?.find((l) => l.Name === rL)?.Id,
                  }));

                  addRoom(
                    {
                      Rooms: [
                        {
                          Name: roomName,
                          Capacity:
                            roomType?.Name === RoomTypes.MEETING
                              ? roomCapacity
                              : null,
                          Geometry: {
                            type: GeometryType.POLYGON,
                            coordinates: (
                              roomGeometry as Polygon
                            ).getCoordinates(),
                          },
                          FloorId: floor.Id,
                          RoomTypeId: roomType?.Id,
                          IsPrivate: privateRoom,
                          AloneAtWorkActivated: aloneAtWorkIsActivated,
                          RoomLabels: labels
                            ? {
                                data: labels,
                              }
                            : undefined,
                        },
                      ],
                      Input: {
                        floorId: floor.Id,
                      },
                      AAWActivated,
                    },
                    hasuraHeader(HasuraPermissions.WRITE_ROOM, [
                      'LiveRoomOccupancy',
                      'SensorAverage',
                    ]),
                  )
                    .then((insertRoomResp) => {
                      if (insertRoomResp.error) {
                        setIsSaving(false);
                        onClose(false);
                      } else {
                        onClose(true);
                      }
                      toast(insertRoomResp);
                    })
                    .catch(() => {
                      setIsSaving(false);
                      onClose(false);
                    });
                } else {
                  const labelsToAdd = roomLabelsToAdd
                    ?.map((rL) => ({
                      LabelsId: (labelData?.Labels || []).find(
                        (l) => l.Name === rL,
                      )?.Id,
                      RoomsId: selectedRoom?.getProperties().Id,
                    }))
                    .filter((rL) => rL.LabelsId && rL.RoomsId);
                  const labelsToDelete = roomLabelsToDelete
                    .map((rL) => ({
                      LabelsId: (labelData?.Labels || []).find(
                        (l) => l.Name === rL.Name,
                      )?.Id,
                      RoomsId: selectedRoom?.getProperties().Id,
                    }))
                    .filter((rL) => rL.LabelsId && rL.RoomsId);
                  if (selectedRoom?.getProperties().Id) {
                    updateRoom(
                      {
                        RoomId: selectedRoom.getProperties().Id,
                        Room: {
                          Name: roomName,
                          Capacity:
                            roomType?.Name === RoomTypes.MEETING
                              ? roomCapacity
                              : null,
                          RoomTypeId: roomType?.Id,
                          IsPrivate: privateRoom,
                          AloneAtWorkActivated: aloneAtWorkIsActivated,
                          Geometry: {
                            type: GeometryType.POLYGON,
                            coordinates: selectedRoom
                              .getGeometry()
                              ?.getCoordinates() ?? [[[0]]],
                          },
                        },
                        RoomLabelsToAdd: labelsToAdd ?? [],
                        RoomLabelsToDelete:
                          labelsToDelete.length > 0
                            ? {
                                _or: labelsToDelete.map((l) => ({
                                  LabelsId: { _eq: l.LabelsId },
                                  RoomsId: { _eq: l.RoomsId },
                                })),
                              }
                            : {
                                // force a false boolean to ensure not removing the whole RoomLabel table
                                LabelsId: { _is_null: true },
                              },
                        Input: {
                          floorId: floor.Id,
                        },
                        AAWActivated,
                      },
                      hasuraHeader(HasuraPermissions.WRITE_ROOM, [
                        'LiveRoomOccupancy',
                        'SensorAverage',
                      ]),
                    )
                      .then((d) => {
                        if (d.error) {
                          setIsSaving(false);
                          onClose(false);
                        } else {
                          onClose(true);
                        }
                        toast(d);
                      })
                      .catch(() => {
                        setIsSaving(false);
                        onClose(false);
                      });
                  }
                }
              }
            }}
            onCancel={() => {
              onClose(false);
            }}
          />
        </Panel.Footer>
      </Panel>

      <PrivateRoomModal
        open={openPrivateRoomWarning}
        onConfirm={() => {
          setPrivateRoom(false);
          setOpenPrivateRoomWarning(false);
        }}
        setShowModal={setOpenPrivateRoomWarning}
      />
      <RemoveRoomModal
        setIsSaving={setIsSaving}
        toRemove={roomToRemove}
        setToRemove={setRoomToRemove}
        setSelectedRoom={setSelectedRoom}
      />
    </>
  );
}
