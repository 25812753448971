import Button from 'generic/components/Form/Button/Button';
import Panel from 'generic/components/Panel/Panel';
import { FormattedMessage } from 'translations/Intl';

import { Menu, MenuButton, MenuItems } from '@headlessui/react';
import type { Table } from '@tanstack/react-table';
import { useState } from 'react';
import { HiOutlineBeaker } from 'react-icons/hi2';

import Content from './components/Content/Content';

interface FilterMenuProps<T> {
  table: Table<T>;
}

export default function FilterMenu<T>({ table }: FilterMenuProps<T>) {
  // TODO: Remove when updated https://github.com/TanStack/table/issues/5567
  'use no memo';
  const [panelOpen, setPanelOpen] = useState(false);
  const filteredColumns = table
    .getHeaderGroups()
    .flatMap((headerGroup) =>
      headerGroup.headers.filter((header) => header.column.getIsFiltered()),
    ).length;

  return (
    <>
      {/* Desktop */}
      <Menu>
        <div className="lg:inline-block text-left hidden">
          <MenuButton className="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border font-medium bg-white text-neutral-700 align-middle hover:bg-neutral-50 transition-all text-sm dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-400 dark:hover:text-white">
            <HiOutlineBeaker className="size-5" />
            <FormattedMessage id="Filter" />
            <span className="pl-2 text-xs font-semibold text-primary-600 border-l border-neutral-200 dark:border-neutral-700 dark:text-primary-500">
              {filteredColumns}
            </span>
          </MenuButton>

          <MenuItems
            transition
            portal
            anchor="bottom start"
            className="w-max mt-1 z-30 overflow-y-auto dark:ring-neutral-700 bg-white dark:bg-neutral-800 rounded-md shadow-lg max-h-96 ring-1 ring-black ring-opacity-5 focus:outline-none text-sm transition duration-200 ease-out data-[closed]:scale-95 data-[closed]:opacity-0"
          >
            <div className="max-w-sm w-full">
              <div className="justify-between items-center p-4 border-b dark:border-neutral-700 gap-4 flex">
                <div className="text-base font-bold sticky">
                  <FormattedMessage id="Filters" />
                </div>
              </div>
              <div className="flex flex-col relative overscroll-contain py-2 px-4 space-y-2">
                <Content table={table} />
              </div>
            </div>
          </MenuItems>
        </div>
      </Menu>
      {/* Mobile */}
      <div className="block lg:hidden">
        <Button
          onClick={() => setPanelOpen(!panelOpen)}
          className="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border font-medium bg-white text-neutral-700 shadow-sm align-middle hover:bg-neutral-50 transition-all text-sm dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-400 dark:hover:text-white dark:focus:ring-offset-neutral-800"
        >
          <HiOutlineBeaker className="size-5" />
          <FormattedMessage id="Filter" />
          <span className="pl-2 text-xs font-semibold text-primary-600 border-l border-neutral-200 dark:border-neutral-700 dark:text-primary-500">
            {filteredColumns}
          </span>
        </Button>
        <Panel
          open={panelOpen}
          setOpen={setPanelOpen}
          title={<FormattedMessage id="Filters" />}
        >
          <div className="flex flex-col relative overscroll-contain space-y-2">
            <Content table={table} />
          </div>
        </Panel>
      </div>
    </>
  );
}
