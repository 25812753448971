import { Field, Label, Radio, RadioGroup } from '@headlessui/react';
import Card from 'generic/components/Card';
import Title from 'generic/components/Title';
import Transition from 'generic/components/Transition';
import type { ReportMetricsQuery } from 'graphql/types';
import { useEffect, useState } from 'react';
import type { IntlMessageKeys } from 'translations/Intl';
import DivisionSwitch from './components/DivisionSwitch';
import TargetValueInput from './components/TargetValueInput';
import WeightInput from './components/WeightInput';

interface ReportEntryProps {
  enabledMetrics: Map<string, boolean>;
  setEnabledMetrics: React.Dispatch<React.SetStateAction<Map<string, boolean>>>;
  data: ReportMetricsQuery['ReportPages'][number];
  setData: React.Dispatch<
    React.SetStateAction<
      Map<
        string,
        {
          targetValue: number;
          divideByTargetValue: boolean;
          weight: number;
        }
      >
    >
  >;
  validWeight: boolean;
}

export default function ReportEntry({
  data,
  setData,
  enabledMetrics,
  setEnabledMetrics,
  validWeight,
}: ReportEntryProps) {
  const [targetValue, setTargetValue] = useState(100);
  const [weight, setWeight] = useState(100);
  const [divideByTargetValue, setDivideByTargetValue] = useState(false);

  const [selected, setSelected] = useState(
    data.ReportPageMetrics.find((d) => d.MetricLimits.length),
  );

  useEffect(() => {
    if (selected?.Id) {
      setData(
        (prev) =>
          new Map([
            ...prev,
            [
              selected.Id,
              {
                targetValue,
                divideByTargetValue,
                weight,
              },
            ],
          ]),
      );
      setEnabledMetrics((prev) => {
        const newMap = new Map([...prev]);
        for (const rpm of data.ReportPageMetrics) {
          if (rpm.Id === selected.Id) {
            newMap.set(rpm.Id, true);
          } else {
            newMap.set(rpm.Id, false);
          }
        }
        return newMap;
      });
    }
  }, [
    selected?.Id,
    divideByTargetValue,
    targetValue,
    weight,
    setData,
    setEnabledMetrics,
    data.ReportPageMetrics,
  ]);

  return (
    <Card className="col-span-1 space-y-2" key={data.Id}>
      <Title value={data.Name as IntlMessageKeys} />
      <RadioGroup
        by="Id"
        value={selected}
        onChange={(select) => setSelected(select)}
      >
        {data.ReportPageMetrics.map((rpm) => (
          <Field key={rpm.Id} className="flex items-start gap-2">
            <Radio
              value={rpm}
              data-test-id={`radio-${data.Name}-${rpm.Metric.Name}`}
              className="group flex size-5 items-center justify-center rounded-full border bg-white data-[checked]:bg-primary-600"
            >
              <span className="invisible size-2 rounded-full bg-white group-data-[checked]:visible" />
            </Radio>
            <div>
              <Label>{rpm.Metric.Name}</Label>
              <Transition
                show={!!enabledMetrics.get(rpm.Id)}
                className="mb-2 flex flex-col space-y-2"
              >
                <WeightInput
                  valid={validWeight}
                  data={rpm.MetricLimits[0]}
                  callback={(weightValue) => setWeight(weightValue)}
                  data-test-id={`weight-input-${data.Name}-${rpm.Metric.Name}`}
                />
                <TargetValueInput
                  data={rpm.MetricLimits[0]}
                  callback={(target) => setTargetValue(target)}
                  data-test-id={`value-input-${data.Name}-${rpm.Metric.Name}`}
                />
                <DivisionSwitch
                  data={rpm}
                  callback={(divide) => setDivideByTargetValue(divide)}
                  targetValue={targetValue}
                  data-test-id={`switch-${data.Name}-${rpm.Metric.Name}`}
                />
              </Transition>
            </div>
          </Field>
        ))}
      </RadioGroup>
    </Card>
  );
}
