import {
  type DesksFloorHistoryOccupancy,
  type DesksUsagePerDay,
  useFlexOccupancyHistoryQuery,
} from 'graphql/types';
import { useIntl } from 'translations/Intl';
import useReportFilter from 'utils/graphql/useReportFilter';
import ReportingPage from '../ReportingPage';
import BarChart from './components/BarChart';
import GroupedChart from './components/GroupedChart';

export default function ReportFlexOccupancy() {
  const intl = useIntl();
  const reportFilter = useReportFilter();
  const [{ data, fetching }] = useFlexOccupancyHistoryQuery(reportFilter);

  const floorData = (data?.floorOccupancy[0]?.Data ?? { data: [] }) as {
    data: DesksFloorHistoryOccupancy[];
  };

  const hotWarmData = (data?.hotOccupancy[0]?.Data ?? { data: [] }) as {
    data: DesksUsagePerDay[];
  };

  const occupancyData = floorData?.data.sort((a, b) => a.Floor - b.Floor);
  const dailyData = hotWarmData?.data.map((d) => ({
    date: d.Date,
    warmPercentage: d.PercentageWarmMinutes,
    hotPercentage: d.PercentageHotMinutes,
  }));

  return (
    <ReportingPage
      loading={fetching}
      reportTitle="Desks flex"
      reportPageId={data?.floorOccupancy[0]?.ReportPageId}
      kpi={intl.formatMessage({
        id: 'Is the number of desks correct?',
      })}
    >
      <div className="w-full justify-center">
        <div className="h-96 w-full">
          <GroupedChart data={occupancyData} />
        </div>
        <div className="h-96 w-full">
          <BarChart data={dailyData} />
        </div>
      </div>
    </ReportingPage>
  );
}
