import useStore from 'model/store';
import { FormattedMessage } from 'translations/Intl';

import { RoomTypes } from '@/common/types';
import Pill from '@/generic/components/Pill';
import { useEffect, useState } from 'react';
import { FaChalkboardTeacher } from 'react-icons/fa';
import { HiOutlineCube } from 'react-icons/hi2';

export default function Filter(): React.JSX.Element {
  const roomTypes = useStore((state) => state.userSettings.roomTypes);
  const setRoomTypes = useStore((state) => state.userApi.setRoomTypes);

  const [showMeetingRooms, setShowMeetingRooms] = useState(
    roomTypes.includes(RoomTypes.MEETING),
  );
  const [showDesks, setShowDesks] = useState(
    roomTypes.includes(RoomTypes.DESKS),
  );

  useEffect(() => {
    if (showMeetingRooms && !showDesks) {
      setRoomTypes([RoomTypes.MEETING]);
    } else if (!showMeetingRooms && showDesks) {
      setRoomTypes([RoomTypes.DESKS]);
    } else if (!showMeetingRooms && !showDesks) {
      setRoomTypes([]);
    } else {
      setRoomTypes([RoomTypes.DESKS, RoomTypes.MEETING]);
    }
  }, [setRoomTypes, showDesks, showMeetingRooms]);

  return (
    <div className="flex space-x-2">
      <Pill
        data-test-id="meeting-room-pill"
        visible={showMeetingRooms}
        setVisible={setShowMeetingRooms}
      >
        <HiOutlineCube className="size-3 mr-1" />
        <FormattedMessage id="Meeting rooms" />
      </Pill>
      <Pill
        data-test-id="desks-pill"
        visible={showDesks}
        setVisible={setShowDesks}
      >
        <FaChalkboardTeacher className="size-3 mr-1" />
        <FormattedMessage id="Desks" />
      </Pill>
    </div>
  );
}
