import Button from '@/generic/components/Form/Button';
import { MqttSystems, useSetSceneIdentifyMutation } from '@/graphql/types';
import useHasuraHeader, {
  HasuraPermissions,
} from '@/utils/graphql/useHasuraHeaders';
import parseBluerangeTopic from '@/utils/parseBluerangeTopic';
import Tooltip from 'generic/components/Tooltip';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'translations/Intl';
import {
  Mda2MessageType,
  generateMda2Topic,
} from '../../../SelectNewBeaconCard/components/EnrollDevices/components/TopicCell/TopicCell';

interface IdentifyButtonProps {
  beaconName: string;
  mqttTopic: string;
  mqttSystem: MqttSystems;
  children?: React.JSX.Element | string | number;
}

export default function IdentifyButton({
  beaconName,
  mqttTopic,
  mqttSystem = MqttSystems.Bluerange,
  children,
}: IdentifyButtonProps): React.JSX.Element {
  const intl = useIntl();
  const hasuraHeader = useHasuraHeader();
  const [isBlinking, setIsBlinking] = useState(false);
  const [, setIdentifySceneMutation] = useSetSceneIdentifyMutation();

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsBlinking(false);
    }, 10000); // 10 seconds blink is in azure function configured

    return () => clearTimeout(timer);
  }, []);

  return (
    <Tooltip
      content={
        <Button
          id={`identify-${beaconName}`}
          className={`${
            isBlinking ? 'animate-pulse' : ''
          } bg-neutral-100 hover:bg-neutral-200 dark:bg-neutral-700 transition duration-150 rounded-full p-2 dark:text-black`}
          onClick={() => {
            setIdentifySceneMutation(
              {
                Topics: [
                  mqttSystem === MqttSystems.Bluerange
                    ? parseBluerangeTopic(mqttTopic)
                    : generateMda2Topic(mqttTopic, Mda2MessageType.CMD),
                ],
                MqttSystem: mqttSystem,
              },
              hasuraHeader(HasuraPermissions.VIEW_ADMIN),
            ).then(() => {
              setIsBlinking(true);
            });
          }}
          title={intl.formatMessage({
            id: 'Identify',
          })}
        >
          {children}
        </Button>
      }
    >
      <FormattedMessage id="Identify scene" />
    </Tooltip>
  );
}
