import { RAINBOW } from '@/constants';
import { scaleOrdinal } from '@visx/scale';
import Legend from 'generic/components/Chart/Legend';
import PieChart from 'generic/components/PieChart';
import {
  type FixFlexFloorHistoryOccupancy,
  useFixFlexOccupancyHistoryQuery,
} from 'graphql/types';
import { useIntl } from 'translations/Intl';
import useReportFilter from 'utils/graphql/useReportFilter';
import Description from '../Description';
import ReportingPage from '../ReportingPage';
import BarChart from './components/BarChart';

export default function ReportFixOccupancy() {
  const intl = useIntl();
  const reportFilter = useReportFilter();
  const [{ data, fetching }] = useFixFlexOccupancyHistoryQuery(reportFilter);

  const typedData = (data?.ReportCharts[0]?.Data ?? { data: [] }) as {
    data: FixFlexFloorHistoryOccupancy[];
  };

  const occupancyData = typedData.data.sort((a, b) => a.Floor - b.Floor);

  return (
    <ReportingPage
      loading={fetching}
      reportPageId={data?.ReportCharts[0]?.ReportPageId}
      reportTitle="Desks fix"
      kpi={intl.formatMessage({
        id: 'Is it possible to transform fix to flex desks?',
      })}
    >
      <div className="flex">
        <div className="w-1/2">
          <div className="w-full h-48" data-test-id="fix-pie">
            <PieChart
              showLabel
              colors={RAINBOW}
              dataFormat={(d) =>
                intl.formatMessage({ id: '{desks} desks' }, { desks: d.value })
              }
              data={
                occupancyData?.map((d) => ({
                  id: `${intl.formatMessage(
                    { id: '{number} Floor' },
                    { number: d.Floor },
                  )}:`,
                  value: d.NumberOfFixDesks,
                })) ?? []
              }
            />
          </div>
          <Description>
            {intl.formatMessage({
              id: 'Distribution fix desks',
            })}
          </Description>
          <Legend
            scaleType="ordinal"
            scale={scaleOrdinal({
              domain: occupancyData?.map((d) => d.Floor),
              range: RAINBOW,
            })}
            labelFormat={(d) =>
              intl.formatMessage(
                {
                  id: '{number} Floor',
                },
                { number: d },
              )
            }
          />
        </div>
        <div className="w-1/2 items-center">
          <div className="w-full h-48" data-test-id="flex-pie">
            <PieChart
              showLabel
              colors={RAINBOW}
              dataFormat={(d) =>
                intl.formatMessage({ id: '{desks} desks' }, { desks: d.value })
              }
              data={
                occupancyData?.map((d) => ({
                  id: `${intl.formatMessage(
                    { id: '{number} Floor' },
                    { number: d.Floor },
                  )}:`,
                  value: d.NumberOfFlexDesks,
                })) ?? []
              }
            />
          </div>
          <Description>
            {intl.formatMessage({
              id: 'Distribution flex desks',
            })}
          </Description>
          <Legend
            scaleType="ordinal"
            scale={scaleOrdinal({
              domain: occupancyData?.map((d) => d.Floor),
              range: RAINBOW,
            })}
            labelFormat={(d) =>
              intl.formatMessage(
                {
                  id: '{number} Floor',
                },
                { number: d },
              )
            }
          />
        </div>
      </div>
      <div className="h-96 w-full">
        <BarChart data={occupancyData} />
      </div>
    </ReportingPage>
  );
}
