import Tooltip from 'generic/components/Tooltip';
import Transition from 'generic/components/Transition';
import type { InputHTMLAttributes } from 'react';

interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  checked?: boolean;
  setChecked?: (checked: boolean) => void;
  label?: string;
  tooltip?: string;
  ref?: React.RefObject<HTMLInputElement | null>;
}

export default function Checkbox({
  ref,
  checked,
  setChecked,
  label,
  tooltip,
  ...rest
}: CheckboxProps) {
  return (
    <label
      htmlFor={label}
      className="flex items-center justify-start text-left space-x-2 text-base md:text-sm text-neutral-600 dark:text-neutral-200 w-fit"
    >
      <input
        id={label}
        data-test-id={label}
        ref={ref}
        name="role"
        type="checkbox"
        checked={checked}
        onChange={(evt) => setChecked?.(evt.target.checked)}
        className="focus:ring-primary-400 size-4 text-primary-500 border-neutral-300 dark:border-neutral-700 rounded cursor-pointer disabled:bg-neutral-200 disabled:hover:bg-neutral-200 disabled:dark:bg-neutral-700 disabled:cursor-default disabled:dark:hover:bg-neutral-700"
        {...rest}
      />
      <div className="flex space-x-1 items-center">
        <div className="text-left font-medium text-base md:text-sm">
          {label}
        </div>
        <Transition as="div" show={!!tooltip}>
          <Tooltip>{tooltip}</Tooltip>
        </Transition>
      </div>
    </label>
  );
}
