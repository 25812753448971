import { Action } from '@/common/types';
import Input from '@/generic/components/Form/Input/Input';
import Modal from '@/generic/components/Modal';
import ModalFooter from '@/generic/components/ModalFooter';
import {
  MqttMessageMappings_Constraint,
  MqttMessageMappings_Update_Column,
  useAddMqttMessageMappingMutation,
  useDeleteDeviceTypeMutation,
  useInsertDeviceTypeMutation,
  useMqttMessageMappingInfoQuery,
  useResetPasswordMutation,
  useTestMessageMappingMutation,
  useUpdateDeviceTypeMutation,
} from '@/graphql/types';
import useHasuraHeader, {
  HasuraPermissions,
} from '@/utils/graphql/useHasuraHeaders';
import useToast from '@/utils/graphql/useToast';
import ClipboardButton from 'generic/components/ClipboardButton';
import StyledButton from 'generic/components/Form/Button/StyledButton';
import Select from 'generic/components/Form/Select';
import TextArea from 'generic/components/Form/TextArea';
import Subtitle from 'generic/components/Subtitle';
import Transition from 'generic/components/Transition';
import useStore from 'model/store';
import { generatePassword } from 'pages/SettingsView/components/UserManagementView/components/UsersTable/components/AddUserModal/AddUserModal';
import {
  type RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FaCircleNotch } from 'react-icons/fa';
import {
  HiOutlineCalculator,
  HiOutlineClock,
  HiOutlineCodeBracket,
  HiOutlineFingerPrint,
  HiOutlineIdentification,
  HiOutlinePlus,
  HiXCircle,
} from 'react-icons/hi2';
import {
  FormattedMessage,
  type IntlMessageKeys,
  useIntl,
} from 'translations/Intl';
import { uuidv4 } from 'utils/uuid';
import type { MqttMessageMapping } from '../RemoveMappingModal/RemoveMappingModal';

interface AddMappingModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  selectedMapping?: MqttMessageMapping;
  setSelectedMapping: (selectedMapping?: MqttMessageMapping) => void;
}

function BrokerInfo({
  text,
  textRef,
  label,
  ...rest
}: {
  text: string;
  textRef: RefObject<HTMLParagraphElement | null>;
  label: IntlMessageKeys;
}) {
  return (
    <div {...rest} className="flex flex-col">
      <div className="font-bold">
        <FormattedMessage id={label} />:
      </div>
      <div className="flex space-x-1 justify-between">
        <div ref={textRef}>{text}</div>
        <ClipboardButton elementRef={textRef} />
      </div>
    </div>
  );
}

export default function AddMappingModal({
  open,
  setOpen,
  selectedMapping,
  setSelectedMapping,
}: AddMappingModalProps): React.JSX.Element {
  const intl = useIntl();
  const usernameRef = useRef<HTMLParagraphElement>(null);
  const passwordRef = useRef<HTMLParagraphElement>(null);
  const topicRef = useRef<HTMLParagraphElement>(null);
  const brokerRef = useRef<HTMLParagraphElement>(null);
  const [name, setName] = useState('mapping_name');
  const [keepAliveDeviceUuidField, setKeepAliveDeviceUuidField] = useState<
    string | undefined
  >();
  const [mappingValidated, setMappingValidated] = useState<
    boolean | undefined
  >();
  const [mappingErrorMessage, setMappingErrorMessage] = useState<
    string | undefined
  >();
  const { organizationUuid, offlineMinutes } = useStore(
    (state) => state.organizationSettings,
  );
  const [id, setId] = useState(uuidv4());
  const [mappings, setMappings] = useState<
    MqttMessageMapping['MqttMessageMappingFields']
  >([]);
  const [fieldMappingsToDelete, setFieldMappingsToDelete] = useState<
    string[] | undefined
  >();
  const [selectedFieldMappingId, setSelectedFieldMappingId] =
    useState<MqttMessageMapping['MqttMessageMappingFields'][number]['Id']>();
  const [json, setJson] = useState('');
  const [selectedDeviceType, setSelectedDeviceType] = useState<
    string | undefined
  >();
  const [jsonOutput, setJsonOutput] = useState('');
  const [password, setPassword] = useState<string | undefined>();
  const [resetPassword, setResetPassword] = useState<string | undefined>();
  const toast = useToast();
  const hasuraHeader = useHasuraHeader();

  const [{ data, fetching: loading }] = useMqttMessageMappingInfoQuery({
    context: useMemo(
      () => hasuraHeader(HasuraPermissions.WRITE_MQTTMAPPING, ['DeviceTypes']),
      [hasuraHeader],
    ),
  });
  const [{ fetching }, addMapping] = useAddMqttMessageMappingMutation();
  const [, testMapping] = useTestMessageMappingMutation();
  const [, addDeviceType] = useInsertDeviceTypeMutation();
  const [, editDeviceType] = useUpdateDeviceTypeMutation();
  const [, deleteDeviceType] = useDeleteDeviceTypeMutation();
  const [{ fetching: loadingReset }, resetPasswordMutation] =
    useResetPasswordMutation();

  const setDefaultMapping = useCallback(() => {
    const uuid = uuidv4();
    const entry = {
      Id: uuid,
      SensorTypeId: data?.SensorTypes[0]?.Id ?? 0,
      DeviceTypeId: data?.DeviceTypes[0]?.Id ?? 0,
      Value: '',
      DeviceId: '',
      DeviceUuid: '',
      Timestamp: '',
      SensorType: {
        Name: data?.SensorTypes[0]?.Name ?? '',
      },
      DeviceType: {
        Name: data?.DeviceTypes[0]?.Name ?? '',
      },
    };
    setMappings(mappings ? [...mappings, entry] : [entry]);
    setSelectedFieldMappingId(uuid);
  }, [
    data?.DeviceTypes[0]?.Id,
    data?.SensorTypes[0]?.Id,
    data?.DeviceTypes[0]?.Name,
    data?.SensorTypes[0]?.Name,
    mappings,
  ]);

  useEffect(() => {
    if (selectedMapping) {
      const {
        Id,
        Name,
        DeviceType,
        KeepAliveDeviceUuidField,
        MqttMessageMappingFields,
      } = selectedMapping;
      setId(Id);
      setName(Name);
      setKeepAliveDeviceUuidField(KeepAliveDeviceUuidField ?? undefined);
      setMappings(MqttMessageMappingFields);
      setSelectedDeviceType(DeviceType?.Name);

      if (MqttMessageMappingFields[0]) {
        setSelectedFieldMappingId(MqttMessageMappingFields[0].Id);
      }
    }
  }, [selectedMapping, selectedMapping?.MqttMessageMappingFields[0]]);

  useEffect(() => {
    // No mapping yet -> use default one
    if (!selectedMapping && !mappings.length && data?.SensorTypes.length) {
      setDefaultMapping();
    }
  }, [setDefaultMapping, selectedMapping, data?.SensorTypes, mappings]);

  useEffect(() => {
    if (!loading && !data?.Users.length) {
      const password = generatePassword();
      setPassword(password);
    }
  }, [loading, data?.Users]);

  const resetValues = () => {
    setName('mapping_name');
    setMappings([]);
    setSelectedFieldMappingId(undefined);
    setFieldMappingsToDelete(undefined);
    setJson('');
    setJsonOutput('');
    setResetPassword(undefined);
    setSelectedMapping(undefined);
    setOpen(false);
  };

  const filteredMapping = useMemo(
    () => mappings.find((m) => m.Id === selectedFieldMappingId),
    [selectedFieldMappingId, mappings],
  );

  return (
    <Modal
      action={selectedMapping ? Action.UPDATE : Action.ADD}
      title={intl.formatMessage(
        {
          id: selectedMapping ? 'Edit mapping' : 'Add mapping',
        },
        { name: selectedMapping?.Name },
      )}
      wrapperClassName="sm:max-w-screen-sm md:max-w-screen-md lg:max-w-screen-lg"
      open={open}
      setShowModal={resetValues}
      footer={
        <ModalFooter
          disabled={
            (selectedMapping &&
              (name === '' ||
                !filteredMapping?.SensorTypeId ||
                filteredMapping?.DeviceId === '' ||
                filteredMapping?.DeviceUuid === '' ||
                loading)) ||
            !mappings.length
          }
          action={selectedMapping ? Action.UPDATE : Action.ADD}
          isLoading={fetching}
          onProceed={() => {
            addMapping(
              {
                MqttMessageMappingFieldsToDelete: fieldMappingsToDelete ?? [],
                MqttMessageMappingFieldsToAdd: mappings.map((mapping) => ({
                  MqttMessageMapping: {
                    data: {
                      Id: id,
                      Name: name,
                      DeviceTypeId: data?.DeviceTypes.find(
                        (d) => d.Name === selectedDeviceType,
                      )?.Id,
                      KeepAliveDeviceUuidField: keepAliveDeviceUuidField,
                    },
                    on_conflict: {
                      constraint:
                        MqttMessageMappings_Constraint.MqttMessageMappingsPkey,
                      update_columns: [
                        MqttMessageMappings_Update_Column.Name,
                        MqttMessageMappings_Update_Column.DeviceTypeId,
                        MqttMessageMappings_Update_Column.KeepAliveDeviceUuidField,
                      ],
                    },
                  },
                  Id: mapping.Id,
                  SensorTypeId: mapping.SensorTypeId,
                  Value: mapping.Value,
                  DeviceId: mapping.DeviceId,
                  DeviceUuid: mapping.DeviceUuid,
                  Timestamp: mapping.Timestamp,
                  SensorTypeCondition: mapping.SensorTypeCondition,
                })),
                User: { user_password: password },
                AddUser: !!password,
              },
              hasuraHeader(HasuraPermissions.WRITE_MQTTMAPPING),
            ).then((data) => {
              if (data.error) {
                toast(data);
              } else {
                setPassword(undefined);
                toast(data, {
                  message: {
                    type: 'success',
                    content: intl.formatMessage(
                      {
                        id: selectedMapping
                          ? 'Updated mapping'
                          : 'Added mapping',
                      },
                      {
                        name: name || selectedMapping?.Name,
                      },
                    ),
                  },
                });
                resetValues();
              }
            });
          }}
          onCancel={resetValues}
        />
      }
    >
      <div className="grid grid-cols-2 gap-4">
        <div className="col-span-1 text-sm text-neutral-700 dark:text-white space-y-4">
          <Input
            type="text"
            value={name}
            label={intl.formatMessage({
              id: 'Name',
            })}
            placeholder={intl.formatMessage({
              id: 'Name',
            })}
            renderIcon={({ className }) => (
              <HiOutlineIdentification className={className} />
            )}
            onChangeValue={(val) => setName(val.trim())}
            required
            data-test-id="mapping-name"
          />
          <Select
            tooltipText={intl.formatMessage({
              id: 'Add device type',
            })}
            value={selectedDeviceType}
            label="Device type"
            onChangeSelected={(selected) =>
              setSelectedDeviceType(selected ?? undefined)
            }
            options={data?.DeviceTypes.map((d) => d.Name) ?? []}
            renderValue={(type) => type ?? ''}
            onOptionAdd={(newDeviceType) =>
              addDeviceType(
                { Name: newDeviceType },
                hasuraHeader(HasuraPermissions.WRITE_MQTTMAPPING),
              ).then((resp) => {
                if (!resp.error) {
                  setSelectedDeviceType(newDeviceType);
                }
                toast(resp);
              })
            }
            onOptionEdit={(deviceType: string, oldDeviceType: string) => {
              const deviceTypeToEdit = data?.DeviceTypes.find(
                (l) => l.Name === oldDeviceType,
              );

              return deviceTypeToEdit
                ? editDeviceType(
                    {
                      Id: deviceTypeToEdit.Id,
                      Name: deviceType,
                    },
                    hasuraHeader(HasuraPermissions.WRITE_MQTTMAPPING),
                  ).then((data) => {
                    setSelectedDeviceType(deviceType);
                    toast(data);
                  })
                : undefined;
            }}
            onOptionDelete={(deviceType) => {
              const deviceTypeToDelete = data?.DeviceTypes.find(
                (l) => l.Name === deviceType,
              );

              return deviceTypeToDelete
                ? deleteDeviceType(
                    {
                      Id: deviceTypeToDelete.Id,
                    },
                    hasuraHeader(HasuraPermissions.WRITE_MQTTMAPPING),
                  ).then((data) => {
                    setSelectedDeviceType(undefined);
                    toast(data);
                  })
                : undefined;
            }}
          />
          <Input
            tooltipText={intl.formatMessage(
              {
                id: 'KeepAlive description',
              },
              { offlineMinutes },
            )}
            error={mappingErrorMessage?.includes('keepAliveDeviceUuidField')}
            type="text"
            value={keepAliveDeviceUuidField}
            label={intl.formatMessage({
              id: 'KeepAlive Device Uuid',
            })}
            placeholder={intl.formatMessage({
              id: 'KeepAlive Device Uuid',
            })}
            renderIcon={({ className }) => (
              <HiOutlineFingerPrint className={className} />
            )}
            onChangeValue={setKeepAliveDeviceUuidField}
          />
          <div>
            <FormattedMessage id="Mapping description" />
          </div>
          <Subtitle value={intl.formatMessage({ id: 'Sensors' })} />
          <div className="flex space-x-2">
            {mappings.map((mapping) => (
              <div key={mapping.Id} className="relative">
                <HiXCircle
                  data-test-id={`${mapping.SensorType.Name}-remove-mapping-field-button`}
                  className="size-5 absolute -top-2 -right-2 cursor-pointer text-red-500"
                  onClick={() => {
                    setFieldMappingsToDelete(
                      fieldMappingsToDelete
                        ? [...fieldMappingsToDelete, mapping.Id]
                        : [mapping.Id],
                    );
                    setMappings(mappings.filter((m) => m.Id !== mapping.Id));
                  }}
                />
                <StyledButton
                  data-test-id={`${mapping.SensorType.Name}-mapping-field-button`}
                  className={
                    selectedFieldMappingId === mapping.Id
                      ? 'bg-primary-500 text-white hover:!bg-primary-500 dark:hover:!bg-neutral-800 cursor-default'
                      : '!bg-neutral-100 !text-black dark:!text-white dark:!bg-neutral-700 hover:!text-white hover:!bg-primary-500 dark:hover:!bg-neutral-800 !border-neutral-200 dark:!border-neutral-900'
                  }
                  onClick={() => {
                    const selected = mappings.find((m) => m.Id === mapping.Id);
                    if (selected) {
                      setSelectedFieldMappingId(selected.Id);
                    }
                  }}
                >
                  <FormattedMessage
                    id={mapping.SensorType.Name as IntlMessageKeys}
                  />
                </StyledButton>
              </div>
            ))}
            <StyledButton
              data-test-id="add-mapping-field-button"
              onClick={setDefaultMapping}
            >
              <HiOutlinePlus className="size-5" />
            </StyledButton>
          </div>
          <Input
            error={mappingErrorMessage?.includes('value')}
            type="text"
            value={filteredMapping?.Value}
            label={intl.formatMessage({
              id: 'Value',
            })}
            placeholder={intl.formatMessage({
              id: 'Value',
            })}
            renderIcon={({ className }) => (
              <HiOutlineCalculator className={className} />
            )}
            onChangeValue={(e) =>
              setMappings(
                mappings.map((m) => ({
                  ...m,
                  Value: m.Id === selectedFieldMappingId ? e : m.Value,
                })),
              )
            }
            required
            data-test-id="mapping-value"
          />
          <Input
            error={mappingErrorMessage?.includes('deviceId')}
            type="text"
            value={filteredMapping?.DeviceId}
            label={intl.formatMessage({
              id: 'Device Id',
            })}
            placeholder={intl.formatMessage({
              id: 'Device Id',
            })}
            renderIcon={({ className }) => (
              <HiOutlineFingerPrint className={className} />
            )}
            onChangeValue={(e) =>
              setMappings(
                mappings.map((m) => ({
                  ...m,
                  DeviceId: m.Id === selectedFieldMappingId ? e : m.DeviceId,
                })),
              )
            }
            required
            data-test-id="mapping-device-id"
          />
          <Input
            error={mappingErrorMessage?.includes('deviceUuid')}
            type="text"
            value={filteredMapping?.DeviceUuid}
            label={intl.formatMessage({
              id: 'Device Uuid',
            })}
            placeholder={intl.formatMessage({
              id: 'Device Uuid',
            })}
            renderIcon={({ className }) => (
              <HiOutlineFingerPrint className={className} />
            )}
            onChangeValue={(e) =>
              setMappings(
                mappings.map((m) => ({
                  ...m,
                  DeviceUuid:
                    m.Id === selectedFieldMappingId ? e : m.DeviceUuid,
                })),
              )
            }
            required
            data-test-id="mapping-device-uuid"
          />
          <Input
            error={mappingErrorMessage?.includes('timestamp')}
            tooltipText={intl.formatMessage({
              id: 'If no timestamp is provided then the time when the message was processed will be used',
            })}
            type="text"
            value={filteredMapping?.Timestamp ?? undefined}
            label={intl.formatMessage({
              id: 'Timestamp',
            })}
            placeholder={intl.formatMessage({
              id: 'Timestamp',
            })}
            renderIcon={({ className }) => (
              <HiOutlineClock className={className} />
            )}
            onChangeValue={(e) =>
              setMappings(
                mappings.map((m) => ({
                  ...m,
                  Timestamp: m.Id === selectedFieldMappingId ? e : m.Timestamp,
                })),
              )
            }
            data-test-id="mapping-timestamp"
          />
          <Select
            value={data?.SensorTypes.find(
              (s) => s.Id === filteredMapping?.SensorTypeId,
            )}
            dataTestId="sensor-types-select"
            label="Sensor type"
            onChangeSelected={(selected) =>
              selected &&
              setMappings(
                mappings.map((m) => ({
                  ...m,
                  SensorType:
                    m.Id === selectedFieldMappingId
                      ? (data?.SensorTypes.find(
                          (s) => s.Id === selected.Id,
                        ) ?? {
                          Name: '',
                        })
                      : m.SensorType,
                  SensorTypeId:
                    m.Id === selectedFieldMappingId
                      ? selected.Id
                      : m.SensorTypeId,
                })),
              )
            }
            options={data?.SensorTypes ?? []}
            isDeselectable={false}
            renderValue={(type) =>
              type?.Name
                ? intl.formatMessage({ id: type.Name as IntlMessageKeys })
                : ''
            }
            keyParameter="Id"
            required
          />
          <Input
            error={
              mappingErrorMessage?.includes('typePath') ||
              mappingErrorMessage?.includes('typeValue')
            }
            tooltipText={intl.formatMessage({
              id: 'Sensor type condition - description',
            })}
            type="text"
            value={filteredMapping?.SensorTypeCondition ?? ''}
            label={intl.formatMessage({
              id: 'Sensor type condition',
            })}
            placeholder={intl.formatMessage({
              id: 'Sensor type condition',
            })}
            renderIcon={({ className }) => (
              <HiOutlineCodeBracket className={className} />
            )}
            onChangeValue={(e) =>
              setMappings(
                mappings.map((m) => ({
                  ...m,
                  SensorTypeCondition:
                    m.Id === selectedFieldMappingId ? e : m.SensorTypeCondition,
                })),
              )
            }
          />
          <div className="flex flex-col">
            <div>
              <FormattedMessage id="Mapping setup" />
            </div>
            <BrokerInfo
              data-test-id="broker-url"
              text={`mqtts://${import.meta.env.VITE_MQTT_BROKER}:${
                import.meta.env.VITE_MQTT_PORT
              }`}
              textRef={brokerRef}
              label="MQTT broker"
            />
            <BrokerInfo
              data-test-id="broker-topic"
              text={`external/${organizationUuid}/${name}`}
              textRef={topicRef}
              label="MQTT topic"
            />
            <BrokerInfo
              data-test-id="broker-user"
              text={organizationUuid ?? ''}
              textRef={usernameRef}
              label="MQTT user"
            />

            <div data-test-id="broker-password" className="flex flex-col">
              <div className="font-bold">
                <FormattedMessage id="MQTT password" />:
              </div>
              {password || resetPassword ? (
                <div className="flex justify-between">
                  <div ref={passwordRef}>{password || resetPassword}</div>
                  <ClipboardButton elementRef={passwordRef} />
                </div>
              ) : (
                <>
                  <span className="italic">
                    <FormattedMessage
                      id="Saved password"
                      values={{ user: organizationUuid }}
                    />
                  </span>
                  <StyledButton
                    data-test-id="reset-password-button"
                    className="!py-1 !px-1"
                    onClick={() => {
                      const generatedPassword = generatePassword();

                      resetPasswordMutation(
                        {
                          User: { user_password: generatedPassword },
                        },
                        hasuraHeader(HasuraPermissions.WRITE_MQTTMAPPING),
                      ).then((data) => {
                        if (data.error) {
                          toast(data);
                        } else {
                          setResetPassword(generatedPassword);
                          toast(data, {
                            message: {
                              type: 'success',
                              content: intl.formatMessage({
                                id: 'Reset password',
                              }),
                            },
                          });
                        }
                      });
                    }}
                  >
                    {loadingReset && (
                      <FaCircleNotch className="animate-spin size-5 text-neutral-200" />
                    )}
                    <FormattedMessage id="Reset" />
                  </StyledButton>
                </>
              )}
              <Transition show={!!password || !!resetPassword}>
                <p className="text-red-500">
                  <FormattedMessage id="Copy password" />
                </p>
              </Transition>
            </div>
          </div>
        </div>
        <div className="col-span-1">
          <div className="flex flex-col space-y-4">
            <TextArea
              data-test-id="mapping-input"
              text={json}
              label={intl.formatMessage({ id: 'Input' })}
              onChange={(e) => {
                setJson(e.target.value);
                setMappingValidated(undefined);
                setMappingErrorMessage(undefined);
              }}
            />
            <StyledButton
              data-test-id="validate-button"
              disabled={!json || !filteredMapping}
              onClick={() => {
                setJsonOutput('');
                setMappingValidated(undefined);
                setMappingErrorMessage(undefined);
                if (filteredMapping) {
                  testMapping(
                    {
                      Input: {
                        content: json,
                        mapping: {
                          value: filteredMapping.Value,
                          deviceId: filteredMapping.DeviceId,
                          deviceUuid: filteredMapping.DeviceUuid,
                          timestamp: filteredMapping.Timestamp,
                          type:
                            data?.SensorTypes.find(
                              (s) => s.Id === filteredMapping.SensorTypeId,
                            )?.Name ?? '',
                          typeCondition: filteredMapping.SensorTypeCondition,
                        },
                        mqttTopic: `external/${organizationUuid}/${name}`,
                      },
                    },
                    hasuraHeader(HasuraPermissions.WRITE_MQTTMAPPING),
                  )
                    .then((data) => {
                      if (data.error) {
                        setMappingValidated(false);
                        setMappingErrorMessage(
                          data.error.graphQLErrors[0]?.message,
                        );
                      } else {
                        setMappingValidated(true);
                        if (data.data?.TransformMqttMessage) {
                          const { __typename, ...rest } =
                            data.data.TransformMqttMessage;
                          setJsonOutput(JSON.stringify(rest, null, 2));
                        }
                      }
                    })
                    .catch(() => setMappingValidated(false));
                }
              }}
            >
              <FormattedMessage id="Validate" />
            </StyledButton>
            {typeof mappingValidated === 'boolean' && (
              <>
                <Transition show={mappingValidated}>
                  <p className="text-green-500" data-test-id="valid-mapping">
                    <FormattedMessage id="Mapping is valid" />
                  </p>
                </Transition>
                <Transition show={!mappingValidated}>
                  <p className="text-red-500">
                    <FormattedMessage id="Mapping is invalid" />
                  </p>
                </Transition>
              </>
            )}
            <TextArea
              data-test-id="mapping-output"
              text={jsonOutput}
              label={intl.formatMessage({ id: 'Output' })}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}
