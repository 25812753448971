import Tooltip from 'generic/components/Tooltip';
import { FormattedMessage, type IntlMessageKeys } from 'translations/Intl';

import StyledButton from '@/generic/components/Form/Button/StyledButton';
import { type ReactNode, useEffect, useState } from 'react';

import ActionModal from '../ActionModal';

interface SetSceneButtonProps {
  renderIcon: () => React.JSX.Element;
  title: IntlMessageKeys;
  onProceed: () => void;
  children: ReactNode;
  onCancel?: () => void;
  timeout?: boolean;
}

export default function SetSceneButton({
  renderIcon,
  title,
  onProceed,
  onCancel,
  timeout,
  children,
}: SetSceneButtonProps) {
  const [isBlinking, setIsBlinking] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (timeout && isBlinking) {
      const timer = setTimeout(() => setIsBlinking(false), 10000); // 10 seconds blink is in azure function configured

      return () => clearTimeout(timer);
    }
    return undefined;
  }, [timeout, isBlinking]);

  return (
    <>
      <Tooltip
        content={
          <StyledButton
            className={isBlinking ? 'animate-pulse' : ''}
            onClick={() => setIsOpen(true)}
          >
            {renderIcon()}
          </StyledButton>
        }
      >
        <FormattedMessage id={title} />
      </Tooltip>

      <ActionModal
        isOpen={isOpen}
        renderIcon={renderIcon}
        setIsOpen={setIsOpen}
        onProceed={() => {
          if (timeout) setIsBlinking(true);
          onProceed();
          if (!timeout) setIsOpen(false);
        }}
        title={title}
        disabled={isBlinking}
        onCancel={onCancel}
      >
        {children}
      </ActionModal>
    </>
  );
}
