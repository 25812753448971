import { Themes } from '@/common/types';
import useStore from '@/model/store';
import getColor from '@/utils/getColor';
import { motion } from 'motion/react';

interface AnimatedLineProps {
  xMax: number;
  y: number;
  color?: string;
  strokeDasharray?: string;
}

export default function AnimatedLine({
  xMax,
  y,
  color,
  strokeDasharray,
}: AnimatedLineProps) {
  const theme = useStore((state) => state.userSettings.theme);

  return (
    <motion.line
      stroke={
        color ??
        (theme.color === Themes.LIGHT
          ? getColor('NEUTRAL600')
          : getColor('NEUTRAL300'))
      }
      strokeWidth={2}
      initial={{ x2: 0 }}
      animate={{ x2: xMax }}
      transition={{ ease: 'easeInOut', duration: 1 }}
      x1={0}
      y1={y}
      y2={y}
      strokeDasharray={strokeDasharray}
    />
  );
}
