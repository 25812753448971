import { FormattedMessage } from 'translations/Intl';

import type { MarginProps } from '@/common/types';
import LoadingSpinner from '@/generic/components/LoadingSpinner';
import {
  type OfflineBeaconsQuery,
  useOfflineBeaconsQuery,
} from '@/graphql/types';
import useStore from '@/model/store';
import localize from '@/utils/format';
import getColor from '@/utils/getColor';
import useHasuraHeader, {
  HasuraPermissions,
} from '@/utils/graphql/useHasuraHeaders';
import { curveBasis } from '@visx/curve';
import { ParentSize } from '@visx/responsive';
import { AnimatedAreaSeries, Tooltip, XYChart } from '@visx/xychart';
import { useMemo } from 'react';
import useXYChartTheme from 'utils/useXYChartTheme';

interface ResponsiveOfflineChartProps {
  margin?: MarginProps;
  beacons: string[] | null;
}

interface OfflineChartProps extends ResponsiveOfflineChartProps {
  height: number;
}

export type ChartData =
  OfflineBeaconsQuery['f_history_beacons_offline_daily'][number];

function OfflineChart({
  height,
  margin = { top: 2, left: 0, right: 0, bottom: 2 },
  beacons,
}: OfflineChartProps) {
  const theme = useXYChartTheme({
    colorRange: [getColor('GREEN'), getColor('RED')],
  });
  const userRoles = useStore((state) => state.user)?.roles;
  const hasuraHeader = useHasuraHeader();

  const [{ data: offlineData, fetching: loadingOffline }] =
    useOfflineBeaconsQuery({
      variables: {
        Beacons: beacons
          ? JSON.stringify(beacons).replace('[', '{').replace(']', '}')
          : null,
      },
      context: useMemo(
        () =>
          hasuraHeader(
            userRoles?.includes(HasuraPermissions.READ_ALL)
              ? HasuraPermissions.READ_ALL
              : HasuraPermissions.VIEW_STATUS,
          ),
        [hasuraHeader, userRoles],
      ),
    });

  const data = useMemo(
    () =>
      offlineData?.f_history_beacons_offline_daily.map((d) => ({
        ...d,
        Date: new Date(d.Date),
      })) ?? [{ Date: new Date(), OfflineCount: 0, OnlineCount: 0 }],
    [offlineData?.f_history_beacons_offline_daily],
  );

  return (
    <div>
      <LoadingSpinner loading={loadingOffline} />
      <XYChart
        margin={margin}
        theme={theme}
        height={height}
        xScale={{
          type: 'time',
        }}
        yScale={{
          type: 'linear',
          domain: [
            Math.min(
              ...[
                ...data.map((c) => c.OfflineCount),
                ...data.map((c) => c.OnlineCount),
              ],
            ),
            Math.max(
              ...[
                ...data.map((c) => c.OfflineCount),
                ...data.map((c) => c.OnlineCount),
              ],
            ),
          ],
          zero: false,
        }}
      >
        <>
          <AnimatedAreaSeries
            dataKey="OnlineCount"
            data={data}
            xAccessor={(d) => d.Date}
            yAccessor={(d) => d.OnlineCount}
            fillOpacity={0.4}
            curve={curveBasis}
          />
          <AnimatedAreaSeries
            dataKey="OfflineCount"
            data={data}
            xAccessor={(d) => d.Date}
            yAccessor={(d) => d.OfflineCount}
            fillOpacity={0.4}
            curve={curveBasis}
          />
        </>
        <Tooltip<ChartData>
          showVerticalCrosshair
          renderTooltip={({ tooltipData, colorScale }) => (
            <div className="dark:text-neutral-200">
              {localize(
                tooltipData?.nearestDatum?.datum.Date ?? new Date(),
                'eeeeee do LLL',
              )}
              <div data-test-id="offline-chart-tooltip">
                {Object.values(tooltipData?.datumByKey ?? {}).map((datum) => {
                  const count =
                    datum.key === 'OnlineCount'
                      ? tooltipData?.nearestDatum?.datum.OnlineCount
                      : tooltipData?.nearestDatum?.datum.OfflineCount;

                  return (
                    <div key={datum.key}>
                      <span
                        style={{
                          color: colorScale?.(datum.key),
                          textDecoration:
                            tooltipData?.nearestDatum?.key === datum.key
                              ? 'underline'
                              : undefined,
                        }}
                      >
                        <FormattedMessage
                          id={
                            datum.key === 'OnlineCount'
                              ? 'Online count'
                              : 'Offline count'
                          }
                        />
                      </span>{' '}
                      {Number.isNaN(count) ? '–' : count}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        />
      </XYChart>
    </div>
  );
}

export default function ResponsiveOfflineChart(
  props: ResponsiveOfflineChartProps,
) {
  return (
    <ParentSize>
      {({ height }) => <OfflineChart {...props} height={height} />}
    </ParentSize>
  );
}
