import { RAINBOW } from '@/constants';
import AnimatedPath from '@/generic/components/AnimatedPath';
import { REPORTING_RIGHT_MARGIN } from '@/pages/ReportingView/components/Reports/Reports';
import { GridRows } from '@visx/grid';
import { Group } from '@visx/group';
import { LegendItem, LegendLabel, LegendOrdinal } from '@visx/legend';
import { ParentSize } from '@visx/responsive';
import { scaleBand, scaleLinear, scaleOrdinal } from '@visx/scale';
import { BarGroup, Line, LinePath } from '@visx/shape';
import { TooltipWithBounds, defaultStyles, useTooltip } from '@visx/tooltip';
import { type MarginProps, Themes } from 'common/types';
import AnimatedRect from 'generic/components/Chart/AnimatedRect';
import Axis from 'generic/components/Chart/Axis';
import Legend from 'generic/components/Chart/Legend';
import type { FixFlexFloorHistoryOccupancy } from 'graphql/types';
import useStore from 'model/store';
import { FormattedMessage, useIntl } from 'translations/Intl';
import getColor from 'utils/getColor';

export enum RoomTypes {
  FIX = 'fix',
  FLEX = 'flex',
}

type TooltipData = {
  bar: { data?: FixFlexFloorHistoryOccupancy; roomType?: RoomTypes };
  floor?: string;
};

export interface ChartData {
  [key: number]: number; // Floor number
  roomType: RoomTypes;
}

interface ResponsiveBarChartProps {
  margin?: MarginProps;
  data: FixFlexFloorHistoryOccupancy[];
  colors?: string[];
}

interface BarChartProps extends ResponsiveBarChartProps {
  height: number;
  width: number;
}

function BarChart({
  height,
  width,
  margin = {
    top: 80,
    left: 70,
    right: REPORTING_RIGHT_MARGIN,
    bottom: 50,
  },
  data,
  colors,
}: BarChartProps) {
  const intl = useIntl();
  const theme = useStore((state) => state.userSettings.theme);

  const roomTypeOccupancyData = data?.flatMap((d) => [
    {
      [d.Floor]: d.AvgDailyFixOccupancy,
      roomType: RoomTypes.FIX,
    },
    {
      [d.Floor]: d.AvgDailyFlexOccupancy,
      roomType: RoomTypes.FLEX,
    },
  ]);

  const chartData: ChartData[] = [
    roomTypeOccupancyData
      ?.filter((d) => d.roomType === RoomTypes.FIX)
      .reduce((r, c) => Object.assign(r, c), {} as ChartData) ?? {
      roomType: RoomTypes.FIX,
    },
    roomTypeOccupancyData
      ?.filter((d) => d.roomType === RoomTypes.FLEX)
      .reduce((r, c) => Object.assign(r, c), {} as ChartData) ?? {
      roomType: RoomTypes.FLEX,
    },
  ];

  const keys = Object.keys(chartData[0] ?? {}).filter((d) => d !== 'roomType');
  const xMax = Math.max(width - margin.left - margin.right, 0);
  const yMax = height - margin.top - margin.bottom;

  const roomTypeScale = scaleBand<RoomTypes>({
    domain: [RoomTypes.FIX, RoomTypes.FLEX],
    padding: 0.2,
    range: [0, xMax],
  });
  const floorScale = scaleBand<string>({
    domain: keys,
    padding: 0.1,
    range: [0, roomTypeScale.bandwidth()],
  });
  const occupancyScale = scaleLinear<number>({
    domain: [0, 100],
    range: [yMax, 0],
  });
  const colorScale = scaleOrdinal<string, string>({
    domain: keys,
    range: RAINBOW || colors,
  });
  const shapeScale = scaleOrdinal<string, React.FC | React.ReactNode>({
    domain: [intl.formatMessage({ id: 'Average daily occupancy' })],
    range: [
      <Line
        key={intl.formatMessage({ id: 'Average daily occupancy' })}
        from={{ x: 0, y: 8 }}
        to={{ x: 10, y: 8 }}
        strokeWidth={2}
        stroke={
          theme.color === Themes.LIGHT
            ? getColor('NEUTRAL600')
            : getColor('NEUTRAL300')
        }
      />,
    ],
  });

  const {
    tooltipOpen,
    tooltipLeft,
    tooltipTop,
    tooltipData,
    hideTooltip,
    showTooltip,
  } = useTooltip<TooltipData>();

  return (
    <div className="relative">
      <svg width={width} height={height}>
        <Group top={margin.top} left={margin.left}>
          <GridRows
            numTicks={10}
            scale={occupancyScale}
            width={xMax}
            height={yMax}
            strokeDasharray="1,3"
            stroke={getColor('NEUTRAL600')}
            strokeOpacity={0.6}
          />
          <BarGroup
            data={chartData}
            keys={keys}
            height={yMax}
            x0={(x) => x.roomType}
            x0Scale={roomTypeScale}
            x1Scale={floorScale}
            yScale={occupancyScale}
            color={colorScale}
          >
            {(barGroups) =>
              barGroups.map((barGroup) => (
                <Group
                  key={`bar-group-${barGroup.index}-${barGroup.x0}`}
                  left={barGroup.x0}
                >
                  <g>
                    {barGroup.bars.map((bar) => (
                      <AnimatedRect
                        bar={bar}
                        key={`bar-group-bar-${barGroup.index}-${bar.index}-${bar.value}-${bar.key}`}
                        data-test-id={`flex-bar-group-${chartData[barGroup.index]?.roomType}-${bar.key}`}
                        onMouseMove={(event) => {
                          showTooltip({
                            tooltipData: {
                              bar: {
                                data: data.find(
                                  (d) =>
                                    Number.parseInt(bar.key, 10) === d.Floor,
                                ),
                                roomType: chartData[barGroup.index]?.roomType,
                              },
                              floor: bar.key,
                            },
                            tooltipTop: event?.y,
                            tooltipLeft: event?.x,
                          });
                        }}
                        onMouseOut={hideTooltip}
                      />
                    ))}
                    <LinePath
                      data={barGroup.bars}
                      x={(d) => d.x + d.width / 2}
                      y={
                        chartData[barGroup.index]?.roomType === RoomTypes.FIX
                          ? occupancyScale(
                              data[0]?.AvgDailyFixOccupancyAll ?? 0,
                            )
                          : occupancyScale(
                              data[1]?.AvgDailyFlexOccupancyAll ?? 0,
                            )
                      }
                    >
                      {({ path }) => (
                        <AnimatedPath path={path} data={barGroup.bars} />
                      )}
                    </LinePath>
                  </g>
                </Group>
              ))
            }
          </BarGroup>
          <Axis
            lowLevelChart
            orientation="left"
            scale={occupancyScale}
            tickFormat={(y) => `${y}%`}
            label={intl.formatMessage({ id: 'Average Occupancy' })}
          />
          <Axis
            lowLevelChart
            orientation="bottom"
            top={yMax}
            scale={roomTypeScale}
            tickFormat={(x) => intl.formatMessage({ id: x })}
            label={intl.formatMessage({ id: 'Room type' })}
          />
        </Group>
      </svg>
      <div className="flex items-center justify-evenly absolute top-8 w-full space-y-2">
        <div className="flex flex-col items-center">
          <div className="flex">
            <Legend
              scaleType="ordinal"
              labelFormat={(d) =>
                intl.formatMessage(
                  {
                    id: '{number} Floor',
                  },
                  { number: d },
                )
              }
              scale={colorScale}
            />
          </div>
          <div className="flex">
            <LegendOrdinal
              direction="row"
              labelMargin="0 15px 0 0"
              scale={shapeScale}
            >
              {(groups) => (
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  {groups.map((label, i) => {
                    const shape = shapeScale(label.datum);
                    return (
                      <LegendItem margin="0 5px" key={`legend-quantile-${i}`}>
                        <svg width="16" height="16" viewBox="0 0 16 16">
                          {shape as React.ReactElement}
                        </svg>
                        <LegendLabel align="left" className="text-xs">
                          {label.text}
                        </LegendLabel>
                      </LegendItem>
                    );
                  })}
                </div>
              )}
            </LegendOrdinal>
          </div>
        </div>
      </div>
      {tooltipOpen && tooltipData && (
        <TooltipWithBounds
          top={tooltipTop}
          left={tooltipLeft}
          style={{
            ...defaultStyles,
            background:
              theme.color === Themes.LIGHT
                ? getColor('WHITE')
                : getColor('NEUTRAL900'),
          }}
        >
          <div className="text-primary-500">
            <strong>
              <FormattedMessage id="All" />
            </strong>
          </div>
          <div
            className="dark:text-neutral-200 "
            data-test-id="report-fix-tooltip-all"
          >
            <div>
              <FormattedMessage id="Average daily occupancy" />:{' '}
              {`${(tooltipData.bar.roomType === RoomTypes.FIX ? tooltipData.bar.data?.AvgDailyFixOccupancyAll : tooltipData.bar.data?.AvgDailyFlexOccupancyAll)?.toFixed(2)}%`}
            </div>
          </div>
          {tooltipData.floor && (
            <>
              <div style={{ color: colorScale(tooltipData.floor) }}>
                <strong>
                  <FormattedMessage
                    id="{number} Floor"
                    values={{ number: tooltipData.floor }}
                  />
                </strong>
              </div>
              <div
                className="dark:text-neutral-200 "
                data-test-id="report-fix-tooltip"
              >
                <div>
                  <FormattedMessage id="Average daily occupancy" />:{' '}
                  {`${(tooltipData.bar.roomType === RoomTypes.FIX ? tooltipData.bar.data?.AvgDailyFixOccupancy : tooltipData.bar.data?.AvgDailyFlexOccupancy)?.toFixed(2)}%`}
                </div>
              </div>
            </>
          )}
        </TooltipWithBounds>
      )}
    </div>
  );
}

export default function ResponsiveBarChart(props: ResponsiveBarChartProps) {
  return (
    <ParentSize>
      {({ height, width }) => (
        <BarChart {...props} height={height} width={width} />
      )}
    </ParentSize>
  );
}
