import type { DeviceTypes } from '@/common/types';
import BreadCrumbs from '@/generic/components/BreadCrumbs';
import LastPolled from '@/generic/components/LastPolled';
import LivePing from '@/generic/components/LivePing';
import PrivateWrapper from '@/generic/components/PrivateWrapper';
import Title from '@/generic/components/Title';
import Loader from '@/generic/components/layout/BarLoader';
import { type MqttSystems, useLiveBeaconsQuery } from '@/graphql/types';
import useStore from '@/model/store';
import { formattedDistance } from '@/utils/date';
import useHasuraHeader, {
  HasuraPermissions,
} from '@/utils/graphql/useHasuraHeaders';
import usePolling from '@/utils/graphql/usePolling';
import useDecodedLocation from '@/utils/useDecodedLocation';
import type { ColumnDef, Row } from '@tanstack/react-table';
import StyledButton from 'generic/components/Form/Button/StyledButton';
import Table, { DELETE_ID } from 'generic/components/Table/Table';
import Tooltip from 'generic/components/Tooltip';
import { useCallback, useMemo, useState } from 'react';
import { HiOutlineBugAnt, HiOutlineCog, HiOutlineTrash } from 'react-icons/hi2';
import {
  FormattedMessage,
  type IntlMessageKeys,
  useIntl,
} from 'translations/Intl';
import { getStatus } from '../ReportingView/components/DefectiveBeacons/components/ReportingMap';
import {
  AutoUpdateCell,
  BeaconStatusCell,
  CompliantCell,
  DeleteCell,
  DeviceModeCell,
  DeviceTypeCell,
  FWPackageCell,
  SensorTypesCell,
  StatusCell,
  getIncompliantFirmwares,
} from './components/Cells';
import ConfigureBeaconModal from './components/ConfigureBeaconModal';
import type { ConfigurableBeacons } from './components/ConfigureBeaconModal/ConfigureBeaconModal';
import MaintenanceModal from './components/MaintenanceModal/MaintenanceModal';
import OfflineChart from './components/OfflineChart';
import OrganizationStatus from './components/OrganizationStatus';
import RemoveBeaconModal from './components/RemoveBeaconModal';
import type { LiveBeaconsQueryData } from './components/RemoveBeaconModal/RemoveBeaconModal';
import RowSubComponent from './components/RowSubComponent';
import SendRPCModal from './components/SendRPCModal';
import type { RPCBeacons } from './components/SendRPCModal/SendRPCModal';
import StatusSkeleton from './components/StatusSkeleton';

const joinArray = (array: string[]) => {
  const joinedArray = [
    ...new Set(
      array.filter((rS) => rS.length), // filter empty relations
    ),
  ]
    .sort((a, b) => a.localeCompare(b))
    .join(',');

  if (joinedArray === '') return '-';

  return joinedArray;
};

export default function StatusView() {
  const [maintenanceModeModalOpen, setMaintenanceModalOpen] = useState(false);
  const [lastUpdate, setLastUpdate] = useState(new Date());
  const [resetSelection, setResetSelection] = useState<() => void>();
  const [filteredOrganizations, setFilteredOrganizations] = useState<
    { name: string; online: number; offline: number }[]
  >([]);
  const [beacons, setBeacons] = useState<string[]>([]);
  const userRoles = useStore((state) => state.user)?.roles;
  const [resultsFiltered, setResultsFiltered] = useState<boolean>(false);
  const [beaconsToRemove, setBeaconsToRemove] = useState<
    Row<LiveBeaconsQueryData>[] | undefined
  >();
  const [beaconsToSendRPC, setBeaconsToSendRPC] = useState<
    RPCBeacons[] | undefined
  >();
  const [beaconsToConfigure, setBeaconsToConfigure] = useState<
    ConfigurableBeacons[] | undefined
  >();
  const beaconName = useDecodedLocation('beacon');
  const building = useDecodedLocation('building');
  const floor = useDecodedLocation('floor');
  const status = useDecodedLocation('status');
  const intl = useIntl();
  const hasuraHeader = useHasuraHeader();

  const [{ data: liveBeacons, fetching: loading }, reexecuteQuery] =
    useLiveBeaconsQuery({
      context: useMemo(
        () =>
          hasuraHeader(
            userRoles?.includes(HasuraPermissions.READ_ALL)
              ? HasuraPermissions.READ_ALL
              : HasuraPermissions.READ,
          ),
        [hasuraHeader, userRoles],
      ),
      variables: {
        Admin: !!userRoles?.includes(HasuraPermissions.READ_ALL),
      },
    });
  usePolling(loading, reexecuteQuery, 1000 * 30, setLastUpdate);

  const beaconData = useMemo(
    () => liveBeacons?.MqttBeacons,
    [liveBeacons?.MqttBeacons],
  );

  const dataCallback = useCallback(
    (data: Row<LiveBeaconsQueryData>[]) => {
      setBeacons(data.map((s) => s.original.UniqueIdentifier));

      // Sending all beacons in the params is the same as sending none. However, Hasura is much slower
      // when there are Beacons in the params, so by default sending none
      if (data && data.length !== beaconData?.length) {
        setResultsFiltered(true);
      } else {
        setResultsFiltered(false);
      }

      const uniqueOrganizations = [
        ...new Set(
          data
            .map((d) => d.original.MqttOrganization.Organization.Name)
            .sort((a, b) => a.localeCompare(b)),
        ),
      ];

      setFilteredOrganizations(
        uniqueOrganizations.map((organization) => ({
          name: organization,
          online: data.filter(
            (p) =>
              p.original.MqttOrganization.Organization.Name === organization &&
              !p.original.IsOffline,
          ).length,
          offline: data.filter(
            (p) =>
              p.original.MqttOrganization.Organization.Name === organization &&
              p.original.IsOffline,
          ).length,
        })),
      );
    },
    [beaconData],
  );

  const columns: ColumnDef<LiveBeaconsQueryData>[] = useMemo(
    () => [
      {
        header: intl.formatMessage({ id: 'Organization' }),
        id: 'Organization',
        accessorFn: (row) => row.MqttOrganization.Organization.Name,
      },
      {
        header: intl.formatMessage({ id: 'Device Type' }),
        id: 'DeviceType',
        accessorFn: (row) =>
          row.DeviceType?.Name ?? intl.formatMessage({ id: 'Unknown' }),
        cell: DeviceTypeCell,
      },
      {
        header: intl.formatMessage({ id: 'Beacon' }),
        id: 'BeaconName',
        accessorFn: (row) => row.Name,
      },
      {
        header: intl.formatMessage({ id: 'Unique Identifier' }),
        id: 'BeaconIdentifier',
        accessorFn: (row) => row.UniqueIdentifier,
      },
      {
        header: intl.formatMessage({ id: 'Building' }),
        id: 'BuildingName',
        accessorFn: (row) => row.Floor?.Building.Name ?? '-',
      },
      {
        header: intl.formatMessage({ id: 'Floor' }),
        id: 'FloorNumber',
        accessorFn: (row) => row.Floor?.Number,
      },
      {
        header: intl.formatMessage({ id: 'Room' }),
        id: 'RoomName',
        accessorFn: (row) =>
          joinArray(
            row.Sensors.flatMap((s) => s.RoomSensors.map((rs) => rs.Room.Name)),
          ),
      },
      {
        header: intl.formatMessage({ id: 'Room labels' }),
        id: 'RoomLabels',
        accessorFn: (row) =>
          joinArray(
            row.Sensors.flatMap((s) =>
              s.RoomSensors.flatMap((rs) =>
                rs.Room.RoomLabels.map((rl) => rl.Label.Name),
              ),
            ),
          ),
      },
      {
        header: intl.formatMessage({ id: 'Status' }),
        id: 'Status',
        accessorFn: (row) =>
          intl.formatMessage({
            id: row.IsOffline ? 'Offline' : 'Online',
          }),
        cell: StatusCell,
      },
      {
        header: intl.formatMessage({ id: 'Device Modes' }),
        id: 'DeviceMode',
        accessorFn: (row) =>
          joinArray(
            row.MqttBeaconModes.map((s) =>
              intl.formatMessage({
                id: s.Mode.Name as IntlMessageKeys,
              }),
            ),
          ),
        cell: DeviceModeCell,
      },
      {
        header: intl.formatMessage({ id: 'Organization updates' }),
        id: 'OrganizationUpdates',
        accessorFn: (row) =>
          row.IsExcludedFromUpdates
            ? intl.formatMessage({ id: 'No' })
            : intl.formatMessage({ id: 'Yes' }),
        cell: AutoUpdateCell,
      },
      {
        header: intl.formatMessage({ id: 'Sensor policy' }),
        id: 'sensorPolicy',
        accessorFn: (row) => row.SensorPolicy?.Name ?? '-',
      },
      {
        header: intl.formatMessage({ id: 'Bluerange OS' }),
        id: 'BluerangeOS',
        accessorFn: (row) => row.BluerangeInfo?.OS ?? '-',
      },
      {
        header: intl.formatMessage({ id: 'Bluerange FW' }),
        id: 'BluerangeFW',
        accessorFn: (row) =>
          row.MqttBeaconFirmwares?.find(
            (f) => f.Firmware.Module.Name === 'clbtm',
          )?.Firmware.Version ??
          row.BluerangeInfo?.FW ??
          '-',
      },
      {
        header: intl.formatMessage({ id: 'Bluerange Status' }),
        id: 'BluerangeStatus',
        accessorFn: (row) => row.BluerangeInfo?.Status ?? '-',
      },
      {
        header: intl.formatMessage({ id: 'Status code' }),
        id: 'MqttBeaconStatus',
        accessorFn: (row) =>
          getStatus(row.StatusCode, row.DeviceType?.Name as DeviceTypes),
        cell: BeaconStatusCell,
      },
      {
        header: intl.formatMessage({ id: 'Beacon source' }),
        id: 'MqttBeaconSource',
        accessorFn: (row) => row.MqttBeaconSource.Name ?? '-',
      },
      {
        header: intl.formatMessage({ id: 'Available desks' }),
        id: 'MqttBeaconAvailableDesks',
        accessorFn: (row) => row.NumberOfAvailableDesks,
      },
      {
        header: intl.formatMessage({ id: 'Sensor types' }),
        id: 'SensorTypes',
        accessorFn: (row) =>
          joinArray(
            row.Sensors.map((s) =>
              intl.formatMessage({
                id: s.SensorType.Name as IntlMessageKeys,
              }),
            ),
          ),
        cell: SensorTypesCell,
      },
      {
        accessorFn: (row) =>
          row.MqttBeaconHistories_aggregate.aggregate?.count ?? 0,
        id: 'changes',
        header: intl.formatMessage({ id: 'State changes' }),
      },
      {
        header: 'FW Package',
        id: 'FWPackage',
        accessorFn: (row) => row.FirmwarePackage?.Version ?? '-',
        cell: FWPackageCell,
      },
      {
        header: intl.formatMessage({ id: 'Compliant' }),
        id: 'FWPackageCompliant',
        accessorFn: (row) =>
          getIncompliantFirmwares(row).length === 0
            ? intl.formatMessage({ id: 'Yes' })
            : intl.formatMessage({ id: 'No' }),
        cell: CompliantCell,
      },
      {
        header: 'FW CLM',
        id: 'FWCLM',
        accessorFn: (row) =>
          row.MqttBeaconFirmwares?.find((f) => f.Firmware.Module.Name === 'clm')
            ?.Firmware.Version ?? '-',
      },
      {
        header: 'FW eCLM',
        id: 'FWeCLM',
        accessorFn: (row) =>
          row.MqttBeaconFirmwares?.find(
            (f) => f.Firmware.Module.Name === 'eclm',
          )?.Firmware.Version ?? '-',
      },
      {
        header: 'FW ControlUnit',
        id: 'FWControlUnit',
        accessorFn: (row) =>
          row.MqttBeaconFirmwares?.find(
            (f) => f.Firmware.Module.Name === 'controlunit',
          )?.Firmware.Version ?? '-',
      },
      {
        header: 'FW ControlUnit M4',
        id: 'FWControlUnitM4',
        accessorFn: (row) =>
          row.MqttBeaconFirmwares?.find(
            (f) => f.Firmware.Module.Name === 'controlunit_m4',
          )?.Firmware.Version ?? '-',
      },
      {
        header: 'FW AloneAtWork',
        id: 'FWAloneAtWork',
        accessorFn: (row) =>
          row.MqttBeaconFirmwares?.find(
            (f) => f.Firmware.Module.Name === 'aloneatwork',
          )?.Firmware.Version ?? '-',
      },
      {
        header: 'FW Panel 0',
        id: 'FWPanel0',
        accessorFn: (row) =>
          row.MqttBeaconFirmwares?.find(
            (f) => f.Firmware.Module.Name === 'panel' && f.Index === 0,
          )?.Firmware.Version ?? '-',
      },
      {
        header: 'FW Panel 1',
        id: 'FWPanel1',
        accessorFn: (row) =>
          row.MqttBeaconFirmwares?.find(
            (f) => f.Firmware.Module.Name === 'panel' && f.Index === 1,
          )?.Firmware.Version ?? '-',
      },
      {
        header: 'FW SensorModule 0',
        id: 'FWSensorModule0',
        accessorFn: (row) =>
          row.MqttBeaconFirmwares?.find(
            (f) => f.Firmware.Module.Name === 'sensormodule' && f.Index === 0,
          )?.Firmware.Version ?? '-',
      },
      {
        header: 'FW SensorModule 1',
        id: 'FWSensorModule1',
        accessorFn: (row) =>
          row.MqttBeaconFirmwares?.find(
            (f) => f.Firmware.Module.Name === 'sensormodule' && f.Index === 1,
          )?.Firmware.Version ?? '-',
      },
      {
        header: 'FW SensorModule 2',
        id: 'FWSensorModule2',
        accessorFn: (row) =>
          row.MqttBeaconFirmwares?.find(
            (f) => f.Firmware.Module.Name === 'sensormodule' && f.Index === 2,
          )?.Firmware.Version ?? '-',
      },
      {
        header: 'FW SensorModule 3',
        id: 'FWSensorModule3',
        accessorFn: (row) =>
          row.MqttBeaconFirmwares?.find(
            (f) => f.Firmware.Module.Name === 'sensormodule' && f.Index === 3,
          )?.Firmware.Version ?? '-',
      },
      {
        header: 'FW SmartCore',
        id: 'FWSmartCore',
        accessorFn: (row) =>
          row.MqttBeaconFirmwares?.find(
            (f) => f.Firmware.Module.Name === 'smartcore',
          )?.Firmware.Version ?? '-',
      },
      {
        header: 'FW Xovis',
        id: 'FWXovis',
        accessorFn: (row) =>
          row.MqttBeaconFirmwares?.find(
            (f) => f.Firmware.Module.Name === 'xovis',
          )?.Firmware.Version ?? '-',
      },
      {
        header: 'FW EspSens',
        id: 'FWEspSens',
        accessorFn: (row) =>
          row.MqttBeaconFirmwares?.find(
            (f) => f.Firmware.Module.Name === 'esp_sens',
          )?.Firmware.Version ?? '-',
      },
      {
        header: intl.formatMessage({ id: 'Last updated' }),
        id: 'LastSeen',
        accessorFn: (row) => new Date(row.LastHeartbeat),
        cell: ({ row }) =>
          formattedDistance(new Date(row.original.LastHeartbeat)),
        enableColumnFilter: false,
      },
      {
        header: intl.formatMessage({ id: 'Created at' }),
        id: 'CreatedAt',
        accessorFn: (row) => new Date(row.CreatedAt),
        cell: ({ row }) => formattedDistance(new Date(row.original.CreatedAt)),
        enableColumnFilter: false,
      },
      {
        enableGrouping: false,
        // Make an edit/delete cell
        id: DELETE_ID,
        cell: ({ row }) => (
          <DeleteCell onClick={() => setBeaconsToRemove([row])} />
        ),
      },
    ],
    [intl.formatMessage],
  );

  const organizations = useMemo(
    () => [
      ...new Set<string>(
        beaconData?.map((d) => d.MqttOrganization.Organization.Name),
      ),
    ],
    [beaconData],
  );

  const columnFilters = useMemo(() => {
    const filters: { id: string; value: string }[] = [];

    if (beaconName) filters.push({ id: 'BeaconName', value: beaconName });
    if (status) filters.push({ id: 'Status', value: status });
    if (building) filters.push({ id: 'BuildingName', value: building });
    if (floor) filters.push({ id: 'FloorNumber', value: floor });

    return filters;
  }, [beaconName, building, status, floor]);

  const filteredColumns = useMemo(
    () =>
      organizations.length < 2
        ? columns.filter(
            (c) =>
              c.id !== 'Organization' &&
              // Do not show bluerange, FW infos and Beacon Source for other organizations
              !c.id?.startsWith('Bluerange') &&
              !c.id?.startsWith('FW') &&
              c.id !== 'MqttBeaconSource' &&
              c.id !== 'BeaconIdentifier' &&
              c.id !== 'OrganizationUpdates',
          )
        : columns,
    [organizations, columns],
  );

  const filteredData = useMemo(
    () =>
      organizations.length < 2
        ? // Hide gateways for other organizations
          (beaconData?.filter((d) => d.DeviceType?.Name !== 'Gateway') ?? [])
        : (beaconData ?? []),
    [organizations, beaconData],
  );

  const initialState = useMemo(
    () => ({
      columnFilters,
      sorting:
        organizations.length < 2
          ? [
              { id: 'BuildingName', desc: false },
              { id: 'FloorNumber', desc: false },
              { id: 'BeaconName', desc: false },
            ]
          : [
              { id: 'Organization', desc: false },
              { id: 'BuildingName', desc: false },
              { id: 'FloorNumber', desc: false },
              { id: 'BeaconName', desc: false },
            ],
    }),
    [columnFilters, organizations.length],
  );

  return (
    <>
      <Loader loading={loading} />
      <div className="space-y-4">
        <div>
          <div className="flex items-center space-x-3">
            <LivePing />
            <Title value="Status" />
          </div>
          <LastPolled lastPoll={lastUpdate} />
        </div>
        <div className="flex space-x-2 justify-between">
          <BreadCrumbs
            items={[
              {
                text: intl.formatMessage({
                  id: 'Status',
                }),
              },
            ]}
          />
          <PrivateWrapper roleRequired={HasuraPermissions.READ_ALL}>
            <div className="flex space-x-2">
              <Tooltip
                content={
                  <StyledButton onClick={() => setMaintenanceModalOpen(true)}>
                    <HiOutlineBugAnt className="size-5" />
                  </StyledButton>
                }
              >
                <FormattedMessage id="Enter maintenance mode" />
              </Tooltip>
            </div>
          </PrivateWrapper>
        </div>
        <div className="space-y-2 w-full">
          {filteredOrganizations.length > 0 ? (
            <div
              className={
                organizations.length === 1
                  ? 'flex space-x-2 items-center'
                  : 'space-y-2'
              }
            >
              <div className="flex flex-wrap gap-1 md:gap-2">
                {filteredOrganizations.map(({ name, online, offline }) => (
                  <div key={name}>
                    <OrganizationStatus
                      organization={name}
                      offlineBeacons={offline}
                      onlineBeacons={online}
                    />
                  </div>
                ))}
              </div>
              <div className="h-32 relative w-full">
                <OfflineChart beacons={resultsFiltered ? beacons : null} />
              </div>
            </div>
          ) : (
            <StatusSkeleton loading={loading} />
          )}
          <Table<LiveBeaconsQueryData>
            id="statusview"
            enabledFeatures={{
              enableRowSelection: true,
            }}
            getRowId={(row) => row.Id.toString()}
            columns={filteredColumns}
            data={filteredData}
            dataCallback={dataCallback}
            loading={loading}
            renderSelectedAction={(selected, resetRowSelection) => [
              {
                onClick: () =>
                  setBeaconsToConfigure(
                    selected.map((s) => ({
                      id: s.original.Id,
                      name: s.original.Name,
                      topic: s.original.MqttTopic,
                      organization:
                        s.original.MqttOrganization.Organization.Name,
                      mqttSystem: s.original.MqttBeaconSource
                        .Name as MqttSystems,
                    })),
                  ),
                text: intl.formatMessage({
                  id: 'Configure selected beacons',
                }),
                icon: <HiOutlineCog className="size-5" />,
                permission: HasuraPermissions.VIEW_ADMIN,
              },
              {
                onClick: () => {
                  setBeaconsToRemove(selected);
                  setResetSelection(resetRowSelection);
                },
                text: intl.formatMessage({ id: 'Delete selected beacons' }),
                icon: <HiOutlineTrash className="size-5" />,
                permission: HasuraPermissions.DELETE_MQTTBEACON,
              },
              {
                onClick: () =>
                  setBeaconsToSendRPC(
                    selected.map((s) => ({
                      mqttTopic: s.original.MqttTopic,
                      mqttSystem: s.original.MqttBeaconSource
                        .Name as MqttSystems,
                    })),
                  ),
                text: intl.formatMessage({ id: 'Send RPC' }),
                icon: <HiOutlineBugAnt className="size-5" />,
                permission: HasuraPermissions.READ_ALL,
              },
            ]}
            renderRowSubComponent={{
              render: RowSubComponent,
              expanderColumnId: 'BeaconName',
            }}
            initialState={initialState}
          />
        </div>
      </div>
      <PrivateWrapper roleRequired={HasuraPermissions.DELETE_MQTTBEACON}>
        <RemoveBeaconModal
          resetSelection={resetSelection}
          beaconsToRemove={beaconsToRemove ?? []}
          setBeaconsToRemove={setBeaconsToRemove}
        />
      </PrivateWrapper>

      <PrivateWrapper roleRequired={HasuraPermissions.VIEW_ADMIN}>
        <ConfigureBeaconModal
          beaconsToConfigure={beaconsToConfigure}
          setBeaconsToConfigure={setBeaconsToConfigure}
        />
      </PrivateWrapper>

      <PrivateWrapper roleRequired={HasuraPermissions.READ_ALL}>
        <SendRPCModal
          beaconsToSendRPC={beaconsToSendRPC}
          setBeaconsToSendRPC={setBeaconsToSendRPC}
        />
      </PrivateWrapper>

      <PrivateWrapper roleRequired={HasuraPermissions.READ_ALL}>
        <MaintenanceModal
          open={maintenanceModeModalOpen}
          setOpen={setMaintenanceModalOpen}
        />
      </PrivateWrapper>
    </>
  );
}
