import Input from '@/generic/components/Form/Input';
import Switch from '@/generic/components/Form/Switch';
import Tooltip from '@/generic/components/Tooltip';
import Transition from '@/generic/components/Transition';
import type { Mda2ConfigurationType } from 'common/types';
import Checkbox from 'generic/components/Form/Checkbox';
import Select from 'generic/components/Form/Select/Select';
import {
  type Mda2FirmwarePackageConfigurationQuery,
  type Mda2SensorPolicyConfigurationQuery,
  useMda2FirmwarePackageConfigurationQuery,
  useMda2SensorPolicyConfigurationQuery,
} from 'graphql/types';
import useStore from 'model/store';
import { useEffect, useMemo, useState } from 'react';
import {
  HiOutlineChatBubbleLeftEllipsis,
  HiOutlineServerStack,
  HiOutlineSquaresPlus,
  HiOutlineUser,
  HiOutlineWifi,
} from 'react-icons/hi2';
import { FormattedMessage, useIntl } from 'translations/Intl';
import useHasuraHeader, {
  HasuraPermissions,
} from 'utils/graphql/useHasuraHeaders';

interface Mda2ConfigurationProps {
  setConfiguration: React.Dispatch<
    React.SetStateAction<Map<string, Mda2ConfigurationType>>
  >;
  setValid?: (valid: boolean) => void;
  organization: string;
}

function LoadingSkeleton() {
  return (
    <div className="w-full animate-pulse flex flex-col space-y-2">
      <div className="w-12 bg-neutral-200 h-4 rounded-md" />
      <div className="w-full bg-neutral-200 h-4 rounded-md" />
    </div>
  );
}

export function Mda2WiFiConfiguration({
  setConfiguration,
  organization,
  update = false,
}: Mda2ConfigurationProps & { update?: boolean }) {
  const intl = useIntl();
  const enrollmentConfiguration = useStore(
    (state) => state.enrollmentConfiguration,
  );
  const uuid = useStore((state) => state.organizationSettings.organizationUuid);
  const [organizationUuid, setOrganizationUuid] = useState(
    enrollmentConfiguration?.configuration?.organizationUuid ?? uuid,
  );
  const [offlineEnrollment, setOfflineEnrollment] = useState(
    enrollmentConfiguration?.configuration?.offlineEnrollment ?? false,
  );
  const [onPremises, setOnPremises] = useState(
    enrollmentConfiguration?.configuration?.onPremises ?? false,
  );
  const [ssid, setSsid] = useState(
    enrollmentConfiguration?.configuration?.ssid,
  );
  const [wifiPassword, setWifiPassword] = useState(
    enrollmentConfiguration?.configuration?.wifiPassword,
  );
  const [mqttBroker, setMqttBroker] = useState(
    enrollmentConfiguration?.configuration?.mqttBroker ??
      `mqtts://${import.meta.env.VITE_MQTT_BROKER}:${
        import.meta.env.VITE_MQTT_PORT
      }`,
  );
  const [mqttUser, setMqttUser] = useState(
    enrollmentConfiguration?.configuration?.mqttUser ?? undefined,
  );
  const [mqttPassword, setMqttPassword] = useState(
    enrollmentConfiguration?.configuration?.mqttPassword ?? undefined,
  );

  useEffect(() => {
    if (!onPremises && !update) {
      setOrganizationUuid(uuid);
      setMqttBroker(
        `mqtts://${import.meta.env.VITE_MQTT_BROKER}:${
          import.meta.env.VITE_MQTT_PORT
        }`,
      );
    }
  }, [onPremises, uuid, update]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: setConfiguration changes on every render and we only need it for callback
  useEffect(() => {
    setConfiguration(
      (prev) =>
        new Map([
          ...prev,
          [
            organization,
            // If updating then only wifi credentials are set
            update
              ? {
                  ...prev.get(organization),
                  ...{
                    ssid,
                    wifiPassword,
                  },
                }
              : {
                  ...prev.get(organization),
                  ...{
                    onPremises,
                    ssid,
                    wifiPassword,
                    mqttBroker,
                    mqttUser,
                    mqttPassword,
                    offlineEnrollment,
                    organizationUuid,
                  },
                },
          ],
        ]),
    );
  }, [
    onPremises,
    ssid,
    wifiPassword,
    mqttBroker,
    mqttUser,
    mqttPassword,
    offlineEnrollment,
  ]);

  return (
    <div className="flex flex-col space-y-2">
      <Input
        required={!update}
        type="text"
        data-test-id="ssid-input"
        label={intl.formatMessage({
          id: 'SSID',
        })}
        value={ssid}
        placeholder={intl.formatMessage({ id: 'SSID' })}
        renderIcon={({ className }) => <HiOutlineWifi className={className} />}
        onChangeValue={(e) => setSsid(e)}
      />
      <Input
        required={!update}
        type="password"
        data-test-id="password-input"
        label={intl.formatMessage({
          id: 'WiFi password',
        })}
        value={wifiPassword}
        placeholder={intl.formatMessage({ id: 'WiFi password' })}
        renderIcon={({ className }) => (
          <HiOutlineSquaresPlus className={className} />
        )}
        onChangeValue={(e) => setWifiPassword(e)}
      />
      {!update && (
        <>
          <div className="flex flex-col space-y-1">
            <Switch
              data-test-id="offline-enrollment-switch"
              isEnabled={offlineEnrollment}
              onSetEnable={() => setOfflineEnrollment(!offlineEnrollment)}
              label={
                <div className="flex space-x-1 items-center">
                  <div>
                    <FormattedMessage id="Offline enrollment" />
                  </div>
                  <div>
                    <Tooltip>
                      <FormattedMessage id="offline-enrollment-description" />
                    </Tooltip>
                  </div>
                </div>
              }
            />
            <Transition show={offlineEnrollment}>
              <p className="text-red-500 text-xs">
                <FormattedMessage id="offline-enrollment-warning" />
              </p>
            </Transition>
          </div>
          <Switch
            isEnabled={onPremises}
            onSetEnable={() => setOnPremises(!onPremises)}
            label={<FormattedMessage id="On-premises" />}
          />
        </>
      )}
      <Transition show={onPremises} className="space-y-2">
        <Input
          required={onPremises}
          type="text"
          label={intl.formatMessage({
            id: 'Topic',
          })}
          maxLength={36}
          value={organizationUuid}
          placeholder={intl.formatMessage({ id: 'Topic' })}
          renderIcon={({ className }) => (
            <HiOutlineChatBubbleLeftEllipsis className={className} />
          )}
          onChangeValue={(e) => setOrganizationUuid(e)}
        />
        <Input
          required
          type="text"
          label={intl.formatMessage({
            id: 'MQTT broker',
          })}
          value={mqttBroker}
          placeholder={intl.formatMessage({ id: 'MQTT broker' })}
          renderIcon={({ className }) => (
            <HiOutlineServerStack className={className} />
          )}
          onChangeValue={(e) => setMqttBroker(e)}
        />
        <Input
          required={onPremises}
          type="text"
          label={intl.formatMessage({
            id: 'MQTT user',
          })}
          value={mqttUser}
          placeholder={intl.formatMessage({ id: 'MQTT user' })}
          renderIcon={({ className }) => (
            <HiOutlineUser className={className} />
          )}
          onChangeValue={(e) => setMqttUser(e)}
        />
        <Input
          required={onPremises}
          type="password"
          label={intl.formatMessage({
            id: 'MQTT password',
          })}
          value={mqttPassword}
          placeholder={intl.formatMessage({ id: 'MQTT password' })}
          renderIcon={({ className }) => (
            <HiOutlineSquaresPlus className={className} />
          )}
          onChangeValue={(e) => setMqttPassword(e)}
        />
      </Transition>
    </div>
  );
}

export function Mda2SensorPolicyConfiguration({
  setConfiguration,
  setValid,
  organization,
}: Mda2ConfigurationProps) {
  const intl = useIntl();
  const userRoles = useStore((state) => state.user)?.roles;
  const hasuraHeader = useHasuraHeader();
  const [selectedPolicy, setSelectedPolicy] = useState<
    Mda2SensorPolicyConfigurationQuery['SensorPolicies'][number] | undefined
  >(undefined);

  const [{ data: mda2Config, fetching }] =
    useMda2SensorPolicyConfigurationQuery({
      context: useMemo(
        () =>
          hasuraHeader(
            userRoles?.includes(HasuraPermissions.READ_ALL)
              ? HasuraPermissions.READ_ALL
              : HasuraPermissions.VIEW_ADMIN,
            ['SensorPolicies'],
          ),
        [hasuraHeader, userRoles],
      ),
      variables: {
        Organization: organization,
      },
    });

  // biome-ignore lint/correctness/useExhaustiveDependencies: setConfiguration changes on every render and we only need it for callback
  useEffect(() => {
    if (selectedPolicy) {
      setConfiguration(
        (prev) =>
          new Map([
            ...prev,
            [
              organization,
              {
                ...prev.get(organization),
                ...{
                  sensorPolicyId: selectedPolicy.Id,
                },
              },
            ],
          ]),
      );
    }
  }, [selectedPolicy]);

  // Set initial policy
  useEffect(() => {
    if (!selectedPolicy && mda2Config?.SensorPolicies) {
      setSelectedPolicy(mda2Config.SensorPolicies.find((p) => p.Default));
    }
  }, [mda2Config?.SensorPolicies, selectedPolicy]);

  useEffect(() => {
    if (selectedPolicy) {
      setValid?.(true);
    }
  }, [selectedPolicy, setValid]);

  return (
    <div className="flex flex-col space-y-2">
      {fetching && <LoadingSkeleton />}
      <div>
        {mda2Config?.SensorPolicies.length && (
          <Select
            dataTestId="policy-select"
            label="Sensor policy"
            value={selectedPolicy}
            options={mda2Config.SensorPolicies}
            onChangeSelected={(selected) =>
              selected && setSelectedPolicy(selected)
            }
            renderValue={(s) =>
              `${s?.Name} ${
                s?.Default
                  ? `(${intl.formatMessage({ id: 'Default policy' })})`
                  : ''
              }`
            }
            keyParameter="Id"
            required
            isDeselectable={false}
          />
        )}
      </div>
    </div>
  );
}

export function Mda2FirmwarePackageConfiguration({
  setConfiguration,
  setValid,
  organization,
}: Mda2ConfigurationProps) {
  const intl = useIntl();
  const hasuraHeader = useHasuraHeader();
  const [switchBackToDefault, setSwitchBackToDefault] = useState(false);
  const [selectedFirmwarePackage, setSelectedFirmwarePackage] = useState<
    | Exclude<
        Mda2FirmwarePackageConfigurationQuery['FirmwarePackages'],
        undefined
      >[number]
    | undefined
  >(undefined);

  const [{ data: mda2Config, fetching }] =
    useMda2FirmwarePackageConfigurationQuery({
      context: useMemo(
        () => hasuraHeader(HasuraPermissions.READ_ALL, ['FirmwarePackages']),
        [hasuraHeader],
      ),
      variables: {
        Organization: organization,
      },
    });

  // biome-ignore lint/correctness/useExhaustiveDependencies: setConfiguration changes on every render and we only need it for callback
  useEffect(() => {
    if (selectedFirmwarePackage?.Id) {
      setConfiguration(
        (prev) =>
          new Map([
            ...prev,
            [
              organization,
              {
                ...prev.get(organization),
                ...{
                  excludedFromUpdates: !switchBackToDefault,
                  firmwarePackageId: selectedFirmwarePackage.Id,
                },
              },
            ],
          ]),
      );
    }
  }, [selectedFirmwarePackage, selectedFirmwarePackage?.Id]);

  // Set initial firmware package
  useEffect(() => {
    if (!selectedFirmwarePackage && mda2Config?.FirmwarePackages) {
      setSelectedFirmwarePackage(
        mda2Config.FirmwarePackages.find((p) =>
          p.FirmwarePackageOrganizations.find((f) => f.Latest),
        ),
      );
    }
  }, [mda2Config?.FirmwarePackages, selectedFirmwarePackage]);

  useEffect(() => {
    if (selectedFirmwarePackage && setValid) {
      setValid(true);
    }
  }, [selectedFirmwarePackage, setValid]);

  return (
    <div className="flex flex-col space-y-2">
      {fetching && <LoadingSkeleton />}
      <div className="space-y-1">
        {mda2Config?.FirmwarePackages?.length && (
          <Select
            label="Firmware package"
            value={selectedFirmwarePackage}
            options={mda2Config.FirmwarePackages}
            onChangeSelected={(selected) =>
              selected && setSelectedFirmwarePackage(selected)
            }
            renderValue={(s) =>
              `${s?.Version} ${
                s?.FirmwarePackageOrganizations.find((f) => f.Latest)
                  ? `(${intl.formatMessage({ id: 'Latest version' })})`
                  : ''
              }`
            }
            keyParameter="Id"
            required
            isDeselectable={false}
          />
        )}
        <Checkbox
          checked={switchBackToDefault}
          setChecked={(included) => {
            setSwitchBackToDefault(included);
            setConfiguration(
              (prev) =>
                new Map([
                  ...prev,
                  [
                    organization,
                    {
                      ...prev.get(organization),
                      ...{
                        excludedFromUpdates: !included,
                      },
                    },
                  ],
                ]),
            );
          }}
          label={intl.formatMessage({
            id: 'Switch back to organization updates',
          })}
          tooltip={intl.formatMessage({
            id: 'switch-back-organization-description',
          })}
        />
      </div>
    </div>
  );
}
