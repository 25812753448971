import Accordion from 'generic/components/Accordion';
import Card from 'generic/components/Card';
import LoadingSpinner from 'generic/components/LoadingSpinner';
import Subtitle from 'generic/components/Subtitle';
import Title from 'generic/components/Title';
import type { ReactNode } from 'react';
import { type IntlMessageKeys, useIntl } from 'translations/Intl';
import ComplianceChart from '../ComplianceChart';

interface ReportingPageProps {
  reportTitle: IntlMessageKeys;
  kpi?: string;
  reportPageId?: string;
  children: ReactNode;
  loading?: boolean;
  className?: string;
}

export default function ReportingPage({
  reportTitle,
  loading = false,
  kpi,
  reportPageId,
  className,
  children,
}: ReportingPageProps) {
  const intl = useIntl();

  return (
    <Card
      className={`relative print:border-0 print:md:p-0 print:break-before-page ${className ?? ''}`}
    >
      <LoadingSpinner loading={loading} />
      <Accordion
        title={intl.formatMessage({ id: reportTitle })}
        data-test-id={`accordion-${reportTitle}`}
      >
        <div>
          {reportPageId && <ComplianceChart reportPageId={reportPageId} />}
          <Title
            value={reportTitle}
            className="hidden print:block print:text-indigo-600 pb-8"
          />
          {kpi && <Subtitle value={kpi} className="print:pb-4" />}
          <div className="flex flex-col space-y-4">{children}</div>
        </div>
      </Accordion>
    </Card>
  );
}
