import { Themes } from '@/common/types';
import useStore from '@/model/store';
import getColor from '@/utils/getColor';
import type { LinePathProps } from '@visx/shape/lib/shapes/LinePath';
import { motion, useMotionValue } from 'motion/react';
import type { MouseEventHandler } from 'react';

interface AnimatedPathProps<T> {
  path: Parameters<
    Exclude<LinePathProps<T>['children'], undefined>
  >[number]['path'];
  data: T[];
  stroke?: SVGPathElement['style']['stroke'];
  strokeWidth?: number;
  strokeOpacity?: number;
  onMouseMove?: MouseEventHandler<SVGPathElement>;
  onMouseOut?: MouseEventHandler<SVGPathElement>;
}

export default function AnimatedPath<T>({
  path,
  data,
  stroke,
  strokeWidth = 2,
  strokeOpacity = 0.8,
  onMouseMove,
  onMouseOut,
  ...rest
}: AnimatedPathProps<T>) {
  const theme = useStore((state) => state.userSettings.theme);
  const pathLength = useMotionValue(0);
  const d = path(data) ?? undefined;

  return (
    <motion.path
      strokeWidth={strokeWidth}
      strokeOpacity={strokeOpacity}
      strokeLinecap="round"
      fill="none"
      stroke={
        stroke ??
        (theme.color === Themes.LIGHT
          ? getColor('NEUTRAL600')
          : getColor('NEUTRAL300'))
      }
      initial="initial"
      animate="animate"
      variants={{
        initial: {
          pathLength: 0,
        },
        animate: {
          pathLength: 1,
          // TODO: Removing d here and passing it to the motion.path component
          // will correctly animate the path on first load, but on data change it will not animate
          d,
          transition: {
            ease: 'easeInOut',
            duration: 1,
          },
        },
      }}
      style={{
        strokeDashoffset: pathLength,
        strokeDasharray: pathLength,
      }}
      onMouseOut={onMouseOut}
      onMouseMove={onMouseMove}
      {...rest}
    />
  );
}
