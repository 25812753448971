import { type MarginProps, Themes } from '@/common/types';
import { RAINBOW } from '@/constants';
import AnimatedPath from '@/generic/components/AnimatedPath';
import Axis from '@/generic/components/Chart/Axis';
import Legend from '@/generic/components/Chart/Legend';
import useStore from '@/model/store';
import getColor from '@/utils/getColor';
import { localPoint } from '@visx/event';
import GridRows from '@visx/grid/lib/grids/GridRows';
import { Group } from '@visx/group';
import { ParentSize } from '@visx/responsive';
import { scaleLinear, scaleOrdinal } from '@visx/scale';
import { Bar, LinePath } from '@visx/shape';
import { TooltipWithBounds, defaultStyles, useTooltip } from '@visx/tooltip';
import { bisector, max } from 'd3-array';
import { Fragment, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'translations/Intl';

interface Data {
  numberOfPeople: number;
  occupancyPercent: number;
  capacity: number;
}

interface ResponsiveLineChart {
  margin?: MarginProps;
  data: Data[];
}

interface LineChart extends ResponsiveLineChart {
  height: number;
  width: number;
}

const bisectPeople = bisector<Data, number>((d) => d.numberOfPeople).left;

function LineChart({
  height,
  width,
  margin = {
    top: 60,
    left: 70,
    right: 30,
    bottom: 60,
  },
  data,
}: LineChart) {
  const intl = useIntl();
  const theme = useStore((state) => state.userSettings.theme);
  const capacities = useMemo(
    () => [...new Set(data.map((d) => d.capacity))].sort((a, b) => a - b),
    [data],
  );

  // Bounds
  const xMax = Math.max(width - margin.left - margin.right, 0);
  const yMax = height - margin.top - margin.bottom;

  // scales
  const xScale = scaleLinear<number>({
    range: [0, xMax],
    domain: [
      Math.min(...data.map((d) => d.numberOfPeople)),
      Math.max(...data.map((d) => d.numberOfPeople)),
    ],
  });

  const yScale = scaleLinear<number>({
    range: [yMax, 0],
    domain: [0, 100],
    nice: true,
  });

  const colorScale = scaleOrdinal({
    domain: capacities,
    range: RAINBOW,
  });

  const {
    tooltipOpen,
    tooltipLeft,
    tooltipTop,
    tooltipData,
    hideTooltip,
    showTooltip,
  } = useTooltip<Data[]>();

  return (
    <div className="relative">
      <svg width={width} height={height}>
        <Group top={margin.top} left={margin.left}>
          <GridRows
            numTicks={10}
            scale={yScale}
            width={xMax}
            height={yMax}
            strokeDasharray="1,3"
            stroke={getColor('NEUTRAL600')}
            strokeOpacity={0.6}
          />
          {capacities.map((c) => (
            <LinePath<Data>
              key={c}
              data={data.filter((d) => d.capacity === c)}
              x={(d) => xScale(d.numberOfPeople) ?? 0}
              y={(d) => yScale(d.occupancyPercent) ?? 0}
            >
              {({ path }) => (
                <>
                  <AnimatedPath
                    path={path}
                    data={data.filter((d) => d.capacity === c)}
                    stroke={colorScale(c)}
                  />
                </>
              )}
            </LinePath>
          ))}
          {/* Just used for showing the tooltip */}
          <Bar
            width={xMax}
            height={yMax}
            fill="transparent"
            data-test-id="report-line-chart"
            onMouseMove={(event) => {
              const point = localPoint(event);
              const eventData = data;
              if (point?.x) {
                // 5px offset to actually show data for last entry
                const offset = 5;
                const x0 = xScale.invert(point.x - margin.left + offset);
                const index = bisectPeople(eventData, x0, 1);
                const numberOfPeople = data[index - 1]?.numberOfPeople;
                showTooltip({
                  tooltipData: data
                    .filter((d) => d.numberOfPeople === numberOfPeople)
                    .sort((a, b) => a.capacity - b.capacity),
                  tooltipTop: point?.y,
                  tooltipLeft: point.x,
                });
              }
            }}
            onMouseOut={hideTooltip}
          />
          <Axis
            lowLevelChart
            top={yMax}
            scale={xScale}
            orientation="bottom"
            label={intl.formatMessage({ id: 'Number of people' })}
            tickFormat={(d) => d}
            numTicks={max(data.map((d) => d.numberOfPeople))}
          />
          <Axis
            orientation="left"
            lowLevelChart
            tickFormat={(y) => `${y}%`}
            scale={yScale}
            label={intl.formatMessage({
              id: 'Number of people in % of total occupancy',
            })}
          />
        </Group>
      </svg>
      <div className="flex items-center justify-evenly absolute top-8 w-full space-y-2">
        <div className="flex">
          <Legend
            scaleType="ordinal"
            scale={colorScale}
            labelFormat={(d) =>
              `${intl.formatMessage({ id: 'Capacity' })} ${d}`
            }
          />
        </div>
      </div>
      {tooltipOpen && tooltipData && (
        <TooltipWithBounds
          top={tooltipTop}
          left={tooltipLeft}
          style={{
            ...defaultStyles,
            background:
              theme.color === Themes.LIGHT
                ? getColor('WHITE')
                : getColor('NEUTRAL900'),
          }}
        >
          <div className="text-primary-500">
            <strong>
              <FormattedMessage id="Number of people" />:{' '}
              {tooltipData[0]?.numberOfPeople}
            </strong>
          </div>
          {tooltipData.map((d) => (
            <Fragment key={d.capacity}>
              <div style={{ color: colorScale(d.capacity) }}>
                <strong>
                  <FormattedMessage id="Capacity" />: {d.capacity}
                </strong>
              </div>
              <div
                className="dark:text-neutral-200"
                data-test-id={`report-line-tooltip-${d.capacity}`}
              >
                <div>
                  <FormattedMessage id="Number of people in % of total occupancy" />
                  {': '}
                  {d.occupancyPercent.toFixed(2)}%
                </div>
              </div>
            </Fragment>
          ))}
        </TooltipWithBounds>
      )}
    </div>
  );
}

export default function ResponsiveCleaningChart(props: ResponsiveLineChart) {
  return (
    <ParentSize>
      {({ height, width }) => (
        <LineChart {...props} width={width} height={height} />
      )}
    </ParentSize>
  );
}
