import {
  Action,
  DeviceTypes,
  GeometryType,
  ModuleType,
  type NewSensor,
  type RoomFeatures,
  type RoomSensor,
  RoomTypes,
  type Sensor,
  type SensorTypes,
  type SmartModuleFeatures,
} from '@/common/types';
import Accordion from '@/generic/components/Accordion';
import Button from '@/generic/components/Form/Button';
import Select from '@/generic/components/Form/Select';
import Switch from '@/generic/components/Form/Switch';
import ModalFooter from '@/generic/components/ModalFooter';
import Panel from '@/generic/components/Panel';
import Transition from '@/generic/components/Transition';
import {
  type FloorMapFeaturesQuery,
  useInactivateBeaconMutation,
  useInsertSmartModuleMutation,
} from '@/graphql/types';
import useHasuraHeader, {
  HasuraPermissions,
} from '@/utils/graphql/useHasuraHeaders';
import useToast from '@/utils/graphql/useToast';
import renderSensorIcon from '@/utils/renderSensorIcon';
import LuminaireIcon from 'generic/components/LuminaireIcon';
import Tooltip from 'generic/components/Tooltip';
import type GeometryLayer from 'generic/layers/GeometryLayer';
import useStore from 'model/store';
import type Feature from 'ol/Feature';
import type OLMap from 'ol/Map';
import type Point from 'ol/geom/Point';
import type Polygon from 'ol/geom/Polygon';
import { useEffect, useMemo, useState } from 'react';
import {
  HiOutlineExclamationCircle,
  HiOutlineMinus,
  HiOutlinePlus,
  HiOutlineTrash,
} from 'react-icons/hi2';
import { Link } from 'react-router-dom';
import {
  FormattedMessage,
  type IntlMessageKeys,
  useIntl,
} from 'translations/Intl';
import useDeviceDetect from 'utils/useDeviceDetect';
import OtherSensorsList from '../OtherSensorsList';

interface AddModuleCardProps {
  map: OLMap;
  open: boolean;
  isNew: boolean;
  beacon?: SmartModuleFeatures;
  beaconGeom: Point | null | undefined;
  roomLayer: GeometryLayer<FloorMapFeaturesQuery['Rooms'][number], Polygon>;
  intersectedRooms: RoomFeatures[] | null;
  onClose: (isSaving: boolean) => void;
  setIsSaving: (bool: boolean) => void;
  setIsSelectingNewBeacon: (arg: boolean) => void;
  floorId: number;
}

interface RelatedRoom {
  Id: number;
  Name: string;
  RoomType: {
    Id: string;
    Name: string;
  };
  RoomSensors: RoomSensor[];
}

const getRoomOption = (f: Feature<Polygon>) => {
  const { Id, Name, RoomType, RoomSensors } = f.getProperties();
  return {
    Id: Id as number,
    Name: Name as string,
    RoomType: RoomType as {
      Id: string;
      Name: string;
    },
    RoomSensors,
  } satisfies RelatedRoom;
};

const isPresenceSensor = (type: string) => type === ModuleType.PRESENCE;
const isAreaSensor = (type: string) => type === ModuleType.AREACOUNT;

export default function AddModuleCard({
  map,
  open,
  isNew,
  beacon,
  beaconGeom,
  roomLayer,
  intersectedRooms,
  onClose,
  setIsSaving,
  setIsSelectingNewBeacon,
  floorId,
}: AddModuleCardProps) {
  const intl = useIntl();
  const toast = useToast();
  const { isMobile } = useDeviceDetect();
  const AAWActivated = useStore((state) => state.AAWActivated);
  const userRoles = useStore((state) => state.user)?.roles;
  const hasuraHeader = useHasuraHeader();
  const [temporaryRoomSensors, setTemporaryRoomSensors] = useState<
    RoomSensor[]
  >([]);
  const [roomFeatures, setRoomFeatures] = useState<Feature<Polygon>[]>([]);
  const beaconName = useMemo(() => beacon?.getProperties().Name, [beacon]);
  const sensors = useMemo(
    () =>
      beacon
        ? [...beacon.getProperties().Sensors]
            .map((s) => ({
              ...s,
              SensorType: {
                ...s.SensorType,
                Name: s.SensorType.Name as SensorTypes,
              },
            }))
            .sort((a, b) => a.Index - b.Index)
        : [],
    [beacon],
  );

  const [sensorsList, setSensorsList] = useState<
    NewSensor[] | undefined | null
  >(null);
  const [otherSensorsList, setOtherSensorsList] = useState<
    NewSensor[] | undefined | null
  >(null);

  // biome-ignore lint/correctness/useExhaustiveDependencies: open is necessary to update the features correctly
  useEffect(() => {
    const roomOlFeatures = roomLayer?.getFeatures() ?? [];
    setRoomFeatures(
      roomOlFeatures.filter(
        (f) => f.getProperties().RoomType.Name !== RoomTypes.DESKS,
      ),
    );
  }, [open, roomLayer]);

  const roomOptions = useMemo(
    () =>
      roomFeatures
        .map((f) => getRoomOption(f))
        .sort((a, b) => a.Name.localeCompare(b.Name)),
    [roomFeatures],
  );
  const activeSensorsIds = useMemo(
    () =>
      // Bug with generating types from graphql with an alias
      // @typescript-eslint/no-unnecessary-condition
      sensors
        .map((f) => (f?.RoomSensors || [])[0]?.SensorsId)
        .filter((f) => f),
    [sensors],
  );

  const moduleSensors = useMemo(
    () =>
      sensors.filter((sensor) =>
        Object.values(ModuleType).includes(
          sensor.SensorType.Name as ModuleType,
        ),
      ),
    [sensors],
  );

  useEffect(() => {
    const newSensorsList = moduleSensors.map((sensor) => {
      const { Id, Index, SensorType, RoomSensors } = sensor;
      return {
        id: Id,
        index: Index,
        active: isNew ? false : activeSensorsIds.includes(Id),
        roomSensors: RoomSensors,
        type: SensorType.Name as ModuleType,
      };
    });

    setSensorsList(newSensorsList);

    const otherSensors = sensors.filter(
      (sensor) =>
        !Object.values(ModuleType).includes(
          sensor.SensorType.Name as ModuleType,
        ),
    );
    const newOtherSensorsList = otherSensors.map((sensor) => {
      const { Id, Index, SensorType, RoomSensors } = sensor;
      return {
        id: Id,
        index: Index,
        active: isNew ? false : activeSensorsIds.includes(Id),
        roomSensors: RoomSensors,
        type: SensorType.Name as ModuleType,
      };
    });
    setOtherSensorsList(newOtherSensorsList);
  }, [activeSensorsIds, isNew, moduleSensors, sensors]);

  useEffect(
    () => () => {
      if (!open) {
        // Clean up on close
        setTemporaryRoomSensors([]);
      }
    },
    [open],
  );

  const [, addModule] = useInsertSmartModuleMutation();
  const [, inactivateBeacon] = useInactivateBeaconMutation();

  const hasAvailableRoomSensor = (sensor: NewSensor) => {
    const { id, index } = sensor;
    const ids = [id];
    const isLineCounterIn = sensor.type === ModuleType.LINECOUNT_IN;
    if (isLineCounterIn && sensorsList) {
      const associatedLineOutSensor = sensorsList.filter(
        (s) =>
          (
            [ModuleType.LINECOUNT, ModuleType.LINECOUNT_OUT] as SensorTypes[]
          ).includes(s.type) && s.index === index,
      );
      if (associatedLineOutSensor.length) {
        for (const associated of associatedLineOutSensor) {
          ids.push(associated.id);
        }
      }
    }
    const usedRoomIds = [
      ...temporaryRoomSensors
        .filter((rS) => ids.includes(rS.SensorsId))
        .map((rS) => rS.RoomsId),
      ...[
        ...new Set(
          (sensorsList || [])
            .filter((s) => ids.includes(s.id))
            .flatMap((s) => s.roomSensors)
            .map((rS) => rS.RoomsId),
        ),
      ],
    ];
    const availableRooms = roomOptions
      .map((r) => r.Id)
      .filter((i) => !usedRoomIds.includes(i));
    return !!availableRooms.length;
  };

  const hasIncompleteNewRoomSensor = (id: number) =>
    !!temporaryRoomSensors
      .filter((roomSensor) => roomSensor.SensorsId === id)
      .find((roomSensor) => !roomSensor.RoomsId);

  const sensorsLinkedWithRooms = useMemo(() => {
    const sensorsRoomsList = sensorsList
      ?.filter((s) => !isPresenceSensor(s.type) && s.active && s.roomSensors[0])
      .flatMap((s) => s.roomSensors)
      .filter((a) => a);
    return (
      sensorsRoomsList?.map((s) => ({
        RoomsId: s.RoomsId,
        SensorsId: s.SensorsId,
        Sign: s.Sign,
      })) ?? []
    );
  }, [sensorsList]);

  const saveDisabled = useMemo(() => {
    const isIncompleteNewSensors = !!temporaryRoomSensors.find(
      (roomSensor) => !roomSensor.RoomsId,
    );

    return !beaconGeom || isIncompleteNewSensors;
  }, [beaconGeom, temporaryRoomSensors]);

  const setEmptyRelation = (
    sensor: NewSensor,
    sensorArray: FloorMapFeaturesQuery['smartModules'][number]['Sensors'][number][],
  ) => {
    const { id, index, type } = sensor;
    const newTemporaryRoomSensors = [...temporaryRoomSensors];
    if (type === ModuleType.LINECOUNT_IN) {
      const associatedSensors = sensorArray.filter(
        (s) =>
          [ModuleType.LINECOUNT, ModuleType.LINECOUNT_OUT].includes(
            s.SensorType.Name as ModuleType,
          ) && s.Index === index,
      );
      for (const sensor of associatedSensors) {
        newTemporaryRoomSensors.push({
          RoomsId: undefined,
          SensorsId: sensor.Id,
          Sign: 1,
        });
      }
    }
    setTemporaryRoomSensors([
      ...newTemporaryRoomSensors,
      {
        RoomsId: undefined,
        SensorsId: id,
        Sign: 1,
      },
    ]);
  };

  const renderDeleteButon = (
    roomSensor: RoomSensor,
    isEditingExisting: boolean,
  ) => (
    <div className="flex items-center justify-center col-span-1">
      <Button
        className="size-6 m-2 bg-primary-200 hover:bg-primary-600 disabled:hover:bg-primary-200 disabled:hover:text-primary-500 text-primary-500 hover:text-white focus:outline-none rounded-full shadow-md flex items-center justify-center"
        onClick={() => {
          if (!isEditingExisting) {
            const newSensorsList = [...temporaryRoomSensors];
            const idx = temporaryRoomSensors.indexOf(roomSensor);
            const sensor = sensors.find(
              (s) => s.Id === roomSensor.SensorsId,
            ) satisfies Sensor | undefined;
            const isLineCounterIn =
              sensor?.SensorType.Name === ModuleType.LINECOUNT_IN;
            if (isLineCounterIn) {
              const associatedSensors = sensors.filter(
                (s) =>
                  [ModuleType.LINECOUNT, ModuleType.LINECOUNT_IN].includes(
                    sensor.SensorType.Name as ModuleType,
                  ) && s.Index === sensor.Index,
              );
              if (associatedSensors.length) {
                const associatedIdxes = associatedSensors.map(
                  (associatedSensor) =>
                    temporaryRoomSensors.findIndex(
                      (s) => s.SensorsId === associatedSensor.Id,
                    ),
                );
                for (const associatedIdx of associatedIdxes) {
                  newSensorsList.splice(associatedIdx, 1);
                }
              }
            }
            newSensorsList.splice(idx, 1);
            setTemporaryRoomSensors(newSensorsList);
          } else if (sensorsList) {
            const newSensorsList = [...sensorsList];
            const sensorToEdit = newSensorsList.find(
              (s) => s.id === roomSensor.SensorsId,
            ) as NewSensor;
            if (sensorToEdit.type === ModuleType.LINECOUNT_IN) {
              // Remove associated 'linecount_out' sensor
              const associatedSensorToEdit = newSensorsList.find(
                (s) =>
                  s.index === sensorToEdit.index &&
                  s.type === ModuleType.LINECOUNT_OUT,
              ) as NewSensor;
              const associatedRoomSensorToEdit =
                associatedSensorToEdit.roomSensors.find(
                  (rS) => rS.RoomsId === roomSensor.RoomsId,
                ) as RoomSensor;
              const newRoomSensors = [...associatedSensorToEdit.roomSensors];
              const idx = associatedSensorToEdit.roomSensors.indexOf(
                associatedRoomSensorToEdit,
              );
              newRoomSensors.splice(idx, 1);
              associatedSensorToEdit.roomSensors = newRoomSensors;
            }
            const roomSensorToEdit = sensorToEdit.roomSensors.find(
              (rS) =>
                rS.RoomsId === roomSensor.RoomsId &&
                rS.SensorsId === roomSensor.SensorsId,
            ) as RoomSensor;
            const newRoomSensors = [...sensorToEdit.roomSensors];
            const idx = sensorToEdit.roomSensors.indexOf(roomSensorToEdit);
            newRoomSensors.splice(idx, 1);
            sensorToEdit.roomSensors = newRoomSensors;
            setSensorsList(newSensorsList);
          }
        }}
      >
        <HiOutlineTrash className="size-3" />
      </Button>
    </div>
  );

  const setHoveredOption = (option: RelatedRoom | null) => {
    const newHighlightedFeature =
      option &&
      roomLayer.getFeatures()?.find((f) => f.getProperties().Id === option.Id);
    if (option && newHighlightedFeature) {
      roomLayer.hoveredFeature = newHighlightedFeature;
    } else {
      roomLayer.hoveredFeature = undefined;
    }
  };

  const renderRelations = (
    newRoomSensorlist: NewSensor[] | null,
    roomSensorlist: RoomSensor[] | null,
    sensor: NewSensor,
    roomSensor: RoomSensor,
  ): React.JSX.Element | null => {
    const isLineCounter = (
      [ModuleType.LINECOUNT_IN, ModuleType.LINECOUNT_OUT] as SensorTypes[]
    ).includes(sensor.type);
    const isEditingExisting = newRoomSensorlist !== null;
    const onChangeSelected = (
      newEntry: RoomSensor,
      associatedNewEntries?: RoomSensor[],
    ) => {
      if (isEditingExisting) {
        const newSensorsList = [...newRoomSensorlist];
        const sensorToEdit = newSensorsList.find(
          (s) => s.id === sensor.id,
        ) as NewSensor;

        // Change associated 'linecount_out' & 'linecount' value
        if (sensorToEdit.type === ModuleType.LINECOUNT_IN) {
          const associatedSensorsToEdit = newSensorsList.filter(
            (s) =>
              s.index === sensorToEdit.index &&
              (
                [
                  ModuleType.LINECOUNT_OUT,
                  ModuleType.LINECOUNT,
                ] as SensorTypes[]
              ).includes(s.type),
          ) as NewSensor[];

          for (const associatedSensorToEdit of associatedSensorsToEdit) {
            const associatedRoomSensorToEdit =
              associatedSensorToEdit.roomSensors.find(
                (rS) => rS.RoomsId === roomSensor.RoomsId,
              ) as RoomSensor;
            if (associatedNewEntries?.length) {
              const newRoomSensors = [...associatedSensorToEdit.roomSensors];
              const idx = associatedSensorToEdit.roomSensors.indexOf(
                associatedRoomSensorToEdit,
              );
              newRoomSensors.splice(idx, 1);
              const associatedNewEntry = associatedNewEntries.find(
                (asset) => associatedSensorToEdit.id === asset.SensorsId,
              );
              if (associatedNewEntry) {
                newRoomSensors.splice(idx, 0, associatedNewEntry);
              }
              associatedSensorToEdit.roomSensors = newRoomSensors;
            }
          }
        }
        const roomSensorToEdit = sensorToEdit.roomSensors.find(
          (rS) =>
            rS.RoomsId === roomSensor.RoomsId &&
            rS.SensorsId === roomSensor.SensorsId,
        ) as RoomSensor | undefined;
        if (roomSensorToEdit) {
          const newRoomSensors = [...sensorToEdit.roomSensors];
          const idx = sensorToEdit.roomSensors.indexOf(roomSensorToEdit);
          newRoomSensors.splice(idx, 1);
          newRoomSensors.splice(idx, 0, newEntry);
          sensorToEdit.roomSensors = newRoomSensors;
          setSensorsList(newSensorsList);
        }
      }
      if (!isEditingExisting && roomSensorlist !== null) {
        const newSensorsList = [...roomSensorlist];
        let idx = newSensorsList.findIndex(
          (s) =>
            s.SensorsId === newEntry.SensorsId &&
            s.RoomsId === newEntry.RoomsId,
        );
        if (idx === -1) {
          idx = newSensorsList.findIndex(
            (s) =>
              s.SensorsId === newEntry.SensorsId && s.RoomsId === undefined,
          );
        }
        newSensorsList.splice(idx, 1);
        newSensorsList.splice(idx, 0, newEntry);
        if (associatedNewEntries) {
          for (const associatedNewEntry of associatedNewEntries) {
            const associatedIdx = newSensorsList.findIndex(
              (s) =>
                (s.RoomsId === associatedNewEntry.RoomsId ||
                  s.RoomsId === undefined) &&
                s.SensorsId === associatedNewEntry.SensorsId,
            );
            if (associatedIdx >= 0) {
              newSensorsList.splice(associatedIdx, 1);
              newSensorsList.splice(associatedIdx, 0, associatedNewEntry);
            } else {
              newSensorsList.push(associatedNewEntry);
            }
          }
        }
        setTemporaryRoomSensors(newSensorsList);
      }
    };
    return (
      <div className="flex gap-2" key={sensor.id}>
        <Select
          value={roomOptions.find((r) => r.Id === roomSensor.RoomsId)}
          className={isLineCounter ? 'w-1/2 mt-1' : 'w-full mt-1'}
          dataTestId={`${sensor.type}-${sensor.index}-room-relation-select`}
          onChangeSelected={(newRoom) => {
            if (newRoom) {
              const newEntry = {
                SensorsId: roomSensor.SensorsId,
                Sign: roomSensor.Sign,
                RoomsId: newRoom.Id,
              };
              const isLineCounterIn = sensor.type === ModuleType.LINECOUNT_IN;
              if (isLineCounterIn) {
                const associatedLineSensors = sensors.filter(
                  (s) =>
                    [ModuleType.LINECOUNT, ModuleType.LINECOUNT_OUT].includes(
                      s.SensorType.Name as ModuleType,
                    ) && s.Index === sensor.index,
                );
                if (associatedLineSensors.length) {
                  onChangeSelected(
                    newEntry,
                    associatedLineSensors.map((associated) => ({
                      SensorsId: associated.Id,
                      Sign: roomSensor.Sign,
                      RoomsId: newRoom.Id,
                    })),
                  );
                }
              } else {
                onChangeSelected(newEntry);
              }
            }
          }}
          options={roomOptions}
          renderValue={(room) => room?.Name ?? ''}
          keyParameter="Id"
          isDeselectable={false}
          onOptionHover={(option, isEntering) => {
            if (option) {
              if (isEntering) {
                setHoveredOption(option);
              } else {
                setHoveredOption(null);
              }
              roomLayer.olLayer.changed();
            }
          }}
        />
        {isLineCounter ? (
          <div className="flex w-1/2 items-center justify-end">
            <div className="size-6 rounded-full flex items-center justify-center">
              <HiOutlineMinus className="size-3" />
            </div>
            <Switch
              isEnabled={roomSensor.Sign === 1}
              onSetEnable={(enabling) => {
                const newEntry = {
                  SensorsId: roomSensor.SensorsId,
                  Sign: enabling ? 1 : -1,
                  RoomsId: roomSensor.RoomsId,
                };
                const isLineCounterIn = sensor.type === ModuleType.LINECOUNT_IN;
                if (isLineCounterIn) {
                  const associatedLineSensors = sensors.filter(
                    (s) =>
                      [ModuleType.LINECOUNT, ModuleType.LINECOUNT_OUT].includes(
                        s.SensorType.Name as ModuleType,
                      ) && s.Index === sensor.index,
                  );
                  if (associatedLineSensors.length) {
                    onChangeSelected(
                      newEntry,
                      associatedLineSensors.map((associated) => ({
                        SensorsId: associated.Id,
                        Sign: enabling ? 1 : -1,
                        RoomsId: roomSensor.RoomsId,
                      })),
                    );
                  }
                } else {
                  onChangeSelected(newEntry);
                }
              }}
              color="bg-primary-200 dark:bg-neutral-200"
              disabledColor="bg-primary-200 dark:bg-neutral-200"
            />
            <div className="size-6 rounded-full flex items-center justify-center">
              <HiOutlinePlus className="size-3" />
            </div>
            {renderDeleteButon(roomSensor, isEditingExisting)}
          </div>
        ) : (
          <>{renderDeleteButon(roomSensor, isEditingExisting)}</>
        )}
      </div>
    );
  };

  // If there is no geometry it will show a help text
  // Do not show this when on mobile as it uses too much space without
  // being too useful
  if (!beaconGeom && isMobile) {
    return null;
  }

  return (
    <Panel
      open={open}
      setOpen={onClose}
      isRelative
      data-test-id="add-module-panel"
      className="w-full min-w-fit md:w-[350px]"
      title={
        <div className="flex flex-row items-center space-x-4 flex-wrap">
          <div className="dark:bg-neutral-600 rounded-full p-1">
            {beacon && (
              <LuminaireIcon
                device={{
                  deviceType:
                    (beacon.getProperties().DeviceType?.Name as
                      | DeviceTypes
                      | undefined) ?? DeviceTypes.NODE,
                }}
                size={50}
              />
            )}
          </div>
          <div>
            {userRoles?.includes(HasuraPermissions.VIEW_STATUS) ? (
              <Link
                className="text-primary-500"
                data-test-id="desk-name"
                to={`/status?beacon=${beaconName}`}
              >
                {beaconName}
              </Link>
            ) : (
              <div>{beaconName}</div>
            )}
          </div>
          {!isNew && beacon && (
            <Button
              className="transition-all p-1 rounded-full bg-primary-200 dark:bg-primary-400 dark:text-white hover:bg-primary-600 dark:hover:bg-primary-700 disabled:hover:bg-primary-200 text-primary-500 hover:text-white disabled:hover:text-primary-500"
              id={`remove-${beaconName}`}
              onClick={() => {
                setIsSaving(true);
                inactivateBeacon(
                  {
                    BeaconId: beacon.getProperties().Id,
                    DesksToDelete: [],
                    RoomSensorsToDelete: sensors.map((s) => s.Id),
                    Input: {
                      floorId,
                    },
                    AAWActivated,
                  },
                  hasuraHeader(HasuraPermissions.WRITE_DESK),
                )
                  .then((data) => {
                    if (data.error) {
                      setIsSaving(false);
                    } else {
                      toast(data, {
                        message: {
                          type: 'success',
                          content: intl.formatMessage(
                            {
                              id: 'Deleted beacon',
                            },
                            { device: beacon.getProperties().Name },
                          ),
                        },
                      });
                    }
                  })
                  .catch(() => {
                    setIsSaving(false);
                  });
                onClose(false);
              }}
              title={intl.formatMessage({
                id: 'Remove',
              })}
            >
              <HiOutlineTrash className="size-5" />
            </Button>
          )}
        </div>
      }
    >
      <div
        id="add-module-card"
        className="flex flex-col gap-2 [&>*:not(:first-child)]:pt-2 [&>*:not(:first-child)]:border-t [&>*]:border-neutral-200 [&>*]:dark:border-neutral-700"
      >
        {beaconGeom ? (
          <>
            <Accordion
              title={<FormattedMessage id="Sensors" />}
              initialStateOpen={!isMobile}
            >
              <div className="flex flex-col gap-2">
                <Transition show={!roomFeatures.length}>
                  <div className="flex flex-row items-center my-2">
                    <HiOutlineExclamationCircle className="shrink-0 mx-auto size-6 text-primary-500 mr-1" />
                    {intl.formatMessage({
                      id: 'There are no meeting rooms available yet.',
                    })}
                  </div>
                </Transition>
                {sensorsList?.map((sensor) => {
                  const isLineCounterIn =
                    sensor.type === ModuleType.LINECOUNT_IN;
                  const isLineCounterOut =
                    sensor.type === ModuleType.LINECOUNT_OUT;
                  const isLineCounter = sensor.type === ModuleType.LINECOUNT;

                  const intersectedMeetingRooms =
                    intersectedRooms && intersectedRooms.length > 0
                      ? intersectedRooms
                          .filter(
                            (room) =>
                              room.getProperties().RoomType.Name ===
                              RoomTypes.MEETING,
                          )
                          .map((f) => f.getProperties().Name)
                      : [];

                  // Filter out the line out sensor for readability for user.
                  // Changes on line_in are made to line_out type simultaneously.
                  if (isLineCounter || isLineCounterOut) {
                    return null;
                  }
                  const onActivateClick = () => {
                    const newSensorsList = [...sensorsList];
                    const newSensor = newSensorsList.find(
                      (s) => s.id === sensor.id,
                    );
                    if (newSensor) {
                      const newState = !newSensor.active;
                      newSensor.active = newState;
                      if (isLineCounterIn) {
                        const associatedLineSensors = newSensorsList.filter(
                          (s) =>
                            (
                              [
                                ModuleType.LINECOUNT_OUT,
                                ModuleType.LINECOUNT,
                              ] as SensorTypes[]
                            ).includes(s.type) && s.index === sensor.index,
                        );
                        if (associatedLineSensors.length) {
                          for (const associated of associatedLineSensors) {
                            associated.active = newState;
                          }
                        }
                      }
                      const newIntersectedRooms = (
                        intersectedRooms || []
                      ).filter(
                        (f) =>
                          f.getProperties().RoomType.Name === RoomTypes.MEETING,
                      );
                      if (isPresenceSensor(sensor.type)) {
                        newSensor.roomSensors = newIntersectedRooms.map(
                          (interRoom) => ({
                            RoomsId: newIntersectedRooms.length
                              ? interRoom.getProperties().Id
                              : undefined,
                            SensorsId: newSensor.id,
                            Sign: 1,
                          }),
                        );
                      }
                      const [newIntersectedRoom] = newIntersectedRooms;
                      if (
                        sensor.active &&
                        isAreaSensor(sensor.type) &&
                        newIntersectedRoom &&
                        !temporaryRoomSensors.find(
                          (rS) =>
                            rS.RoomsId ===
                              newIntersectedRoom.getProperties().Id &&
                            rS.SensorsId === newSensor.id,
                        ) &&
                        // Check if it the sensor is already linked to the same room
                        !sensor.roomSensors.find(
                          (rS) =>
                            rS.RoomsId ===
                              newIntersectedRoom.getProperties().Id &&
                            rS.SensorsId === newSensor.id,
                        )
                      ) {
                        // If areacount, select per default the first intersected room
                        setTemporaryRoomSensors([
                          ...temporaryRoomSensors,
                          {
                            RoomsId: newIntersectedRoom.getProperties().Id,
                            SensorsId: newSensor.id,
                            Sign: 1,
                          },
                        ]);
                      }
                      setSensorsList(newSensorsList);
                    }
                  };
                  return (
                    <div
                      key={sensor.id}
                      data-test-id={`sensor-wrapper-${sensor.type}-${sensor.index}`}
                      className="flex flex-col border border-neutral-200 dark:border-neutral-700 rounded-md p-2 gap-1"
                    >
                      <div className="flex items-center justify-between">
                        <div className="flex items-center gap-2">
                          {!isPresenceSensor(sensor.type) ? (
                            <Button
                              className={`flex items-center justify-center rounded-full transition duration-150 p-2 focus:outline-none ${
                                sensor.active
                                  ? 'bg-primary-500 text-white'
                                  : 'bg-neutral-100 hover:bg-neutral-200 dark:bg-neutral-700 text-neutral-700 dark:text-neutral-200'
                              }`}
                              onClick={() => onActivateClick()}
                            >
                              {renderSensorIcon(sensor.type, 'size-5')}
                            </Button>
                          ) : (
                            <div
                              className={`flex items-center justify-center rounded-full p-2 focus:outline-none ${
                                intersectedMeetingRooms.length > 0
                                  ? 'bg-primary-500 text-white'
                                  : 'bg-neutral-100 hover:bg-neutral-200 dark:bg-neutral-700'
                              }`}
                            >
                              {renderSensorIcon(sensor.type, 'size-5')}
                            </div>
                          )}
                          <div>{`${intl.formatMessage({
                            id:
                              sensor.type === ModuleType.LINECOUNT_IN
                                ? (`${sensor.type}_admin` as IntlMessageKeys)
                                : (sensor.type as IntlMessageKeys),
                          })} ${sensor.index}`}</div>
                        </div>
                        {!isPresenceSensor(sensor.type) ? (
                          <div>
                            <Switch
                              data-test-id={`${sensor.type}-${sensor.index}`}
                              isEnabled={sensor.active}
                              onSetEnable={() => onActivateClick()}
                              className="mr-2"
                            />
                          </div>
                        ) : (
                          <Transition
                            show={intersectedMeetingRooms.length === 0}
                            appear
                          >
                            <div className="px-5">
                              <Tooltip>
                                <FormattedMessage id="The device is located outside of a meeting room" />
                              </Tooltip>
                            </div>
                          </Transition>
                        )}
                      </div>
                      <Transition show={isPresenceSensor(sensor.type)}>
                        <div className="text-sm">
                          <Transition show={intersectedMeetingRooms.length > 0}>
                            <>
                              <div className="font-bold">
                                {intersectedMeetingRooms.length > 1 ? (
                                  <FormattedMessage id="Related rooms" />
                                ) : (
                                  <FormattedMessage id="Related room" />
                                )}
                              </div>
                              <div
                                className="dark:text-neutral-300 text-neutral-700"
                                data-test-id={`sensor-related-rooms-${sensor.type}-${sensor.index}`}
                              >
                                {intersectedMeetingRooms.length > 0 &&
                                  intersectedMeetingRooms.map(
                                    (intersectedMeetingRoom) => (
                                      <div
                                        key={`${sensor.id}-${intersectedMeetingRoom}`}
                                      >
                                        {intersectedMeetingRoom}
                                      </div>
                                    ),
                                  )}
                              </div>
                            </>
                          </Transition>
                        </div>
                      </Transition>
                      <Transition
                        show={sensor.active && !isPresenceSensor(sensor.type)}
                      >
                        <div>
                          <div className="flex text-sm font-bold">
                            <div
                              className={isLineCounterIn ? 'w-1/2' : 'w-full'}
                            >
                              <FormattedMessage id="Related room" />
                            </div>
                            {isLineCounterIn && (
                              <div className="flex w-1/2 justify-center">
                                <FormattedMessage id="Relation" />
                              </div>
                            )}
                          </div>
                          {sensor.roomSensors.map((roomSensor: RoomSensor) =>
                            renderRelations(
                              sensorsList,
                              null,
                              sensor,
                              roomSensor,
                            ),
                          )}
                          {temporaryRoomSensors
                            .filter((rS) => rS.SensorsId === sensor.id)
                            .map((roomSensor: RoomSensor) =>
                              renderRelations(
                                null,
                                temporaryRoomSensors,
                                sensor,
                                roomSensor,
                              ),
                            )}
                          {!hasIncompleteNewRoomSensor(sensor.id) &&
                            hasAvailableRoomSensor(sensor) && (
                              <div className="flex items-center text-sm ">
                                <Button
                                  data-test-id={`${sensor.type}-${sensor.index}-add-room-relation`}
                                  className="size-6 m-2 bg-primary-200 hover:bg-primary-600 disabled:hover:bg-primary-200 disabled:hover:text-primary-500 text-primary-500 hover:text-white focus:outline-none rounded-full shadow-md flex items-center justify-center"
                                  onClick={() =>
                                    setEmptyRelation(sensor, sensors)
                                  }
                                >
                                  <HiOutlinePlus className="size-3" />
                                </Button>
                                <Button
                                  onClick={() =>
                                    setEmptyRelation(sensor, sensors)
                                  }
                                >
                                  <FormattedMessage id="Add room relation" />
                                </Button>
                              </div>
                            )}
                        </div>
                      </Transition>
                    </div>
                  );
                })}
              </div>
            </Accordion>
            <OtherSensorsList
              beaconGeom={beaconGeom}
              sensors={otherSensorsList}
            />
          </>
        ) : (
          <div className="flex flex-col items-center gap-2">
            <div
              data-test-id="geometry-warning"
              className="flex flex-row items-center"
            >
              <HiOutlineExclamationCircle className="shrink-0 mx-auto size-6 text-primary-500 mr-1" />
              {intl.formatMessage({
                id: 'Place the device on the map',
              })}
            </div>
          </div>
        )}
      </div>
      <Panel.Footer>
        <ModalFooter
          action={isNew ? Action.ADD : Action.UPDATE}
          disabled={saveDisabled}
          dataTestId={{
            proceed: 'module-save',
            cancel: 'module-cancel',
          }}
          onProceed={() => {
            setIsSaving(true);
            (map.getTarget() as HTMLElement).focus();
            const roomSensors = [
              ...sensorsLinkedWithRooms,
              ...temporaryRoomSensors,
            ].filter(
              // filter duplicates
              (value, index, self) =>
                index ===
                self.findIndex(
                  (t) =>
                    t.SensorsId === value.SensorsId &&
                    t.RoomsId === value.RoomsId,
                ),
            );
            if (isNew) {
              addModule(
                {
                  BeaconId: beacon?.getProperties().Id,
                  BeaconGeometry: {
                    type: GeometryType.POINT,
                    coordinates: beaconGeom?.getCoordinates() ?? [0, 0],
                  },
                  RoomSensors: roomSensors,
                  BeaconFloorId: floorId,
                  Input: {
                    floorId,
                  },
                  AAWActivated,
                  DeleteRoomSensors: false,
                },
                hasuraHeader(HasuraPermissions.WRITE_DESK, [
                  'MqttBeacons_aggregate_fields',
                ]),
              )
                .then((data) => {
                  if (data.error) {
                    setIsSaving(false);
                  } else {
                    setIsSelectingNewBeacon(true);
                    toast(data, {
                      message: {
                        type: 'success',
                        content: intl.formatMessage(
                          {
                            id: 'Added beacon',
                          },
                          { device: beacon?.getProperties().Name },
                        ),
                      },
                    });
                  }
                })
                .catch(() => {
                  setIsSaving(false);
                });
            } else if (sensorsList) {
              addModule(
                {
                  BeaconId: beacon?.getProperties().Id,
                  BeaconGeometry: {
                    type: GeometryType.POINT,
                    coordinates: beaconGeom?.getCoordinates() ?? [0, 0],
                  },
                  RoomSensors: roomSensors,
                  BeaconFloorId: floorId,
                  Input: {
                    floorId,
                  },
                  AAWActivated,
                  // Remove RoomSensor that have Id from sensors list, that are not location dependent
                  // and that are not related to the floor room
                  SensorsIds: sensors
                    .filter((s) => !s.SensorType.IsLocationDependentSensor)
                    .map((s) => s.Id),
                  DeleteRoomSensors: true,
                },
                hasuraHeader(HasuraPermissions.WRITE_DESK),
              )
                .then((d) => {
                  if (d.error) {
                    setIsSaving(false);
                  } else {
                    toast(d, {
                      message: {
                        type: 'success',
                        content: intl.formatMessage(
                          {
                            id: 'Updated beacon',
                          },
                          { device: beacon?.getProperties().Name },
                        ),
                      },
                    });
                  }
                })
                .catch(() => {
                  setIsSaving(false);
                });
            }
            onClose(true);
            setTemporaryRoomSensors([]);
          }}
          onCancel={() => {
            onClose(false);
          }}
        />
      </Panel.Footer>
    </Panel>
  );
}
