import type { ButtonHTMLAttributes } from 'react';

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  ref?: React.RefObject<HTMLButtonElement>;
};

export default function Button({
  id,
  onClick,
  ref,
  onMouseOver,
  onFocus,
  className,
  title,
  disabled,
  children,
  ...rest
}: ButtonProps) {
  return (
    <button
      id={id}
      ref={ref}
      type="button"
      onClick={onClick}
      onMouseOver={onMouseOver}
      onFocus={onFocus}
      className={`${className ?? ''} transition-all disabled:opacity-50 disabled:cursor-default`}
      title={title}
      disabled={disabled}
      {...rest}
    >
      {children}
    </button>
  );
}
