import Tooltip from 'generic/components/Tooltip';
import { MqttSystems, useSetSceneIdentifyMutation } from 'graphql/types';
import { FormattedMessage, useIntl } from 'translations/Intl';
import useHasuraHeader, {
  HasuraPermissions,
} from 'utils/graphql/useHasuraHeaders';
import parseBluerangeTopic from 'utils/parseBluerangeTopic';
import useDeviceDetect from 'utils/useDeviceDetect';

import {
  type Colors,
  type DeskFeatures,
  type DrawingDeskFeatures,
  type NewSensor,
  type RoomFeatures,
  RoomTypes,
} from '@/common/types';
import Accordion from '@/generic/components/Accordion';
import Input from '@/generic/components/Form/Input';
import Switch from '@/generic/components/Form/Switch';
import type VectorLayer from '@/generic/layers/VectorLayer';
import type Point from 'ol/geom/Point';
import type OLVectorLayer from 'ol/layer/Vector';
import type VectorSource from 'ol/source/Vector';
import { useEffect, useMemo, useState } from 'react';
import {
  HiOutlineExclamationCircle,
  HiOutlineLightBulb,
} from 'react-icons/hi2';

import Transition from '@/generic/components/Transition';
import { getDeskFeatureIndex } from '../../interactions/moveDesk';
import {
  Mda2MessageType,
  generateMda2Topic,
} from '../SelectNewBeaconCard/components/EnrollDevices/components/TopicCell/TopicCell';

const renderColor = (idx: number): keyof typeof Colors => {
  switch (idx) {
    case 1:
      return 'YELLOW';
    case 2:
      return 'GREEN';
    case 3:
      return 'RED';
    case 4:
      return 'BLUE';
    default:
      return 'NEUTRAL400';
  }
};

interface DeskSensorActivationProps {
  sensorsList: NewSensor[] | undefined | null;
  setSensorsList: (newSensors: NewSensor[]) => void;
  mqttTopic: string;
  mqttSystem: MqttSystems;
  intersectedRooms: RoomFeatures[] | null;
  drawingDesksLayer: VectorLayer<Point>;
  resetDesksToInitialPositions: (sensorIds: number[]) => void;
}

function BlinkInput({
  sensor,
  mqttTopic,
  mqttSystem,
  sensorsList,
  setSensorsList,
}: {
  sensor: NewSensor;
  mqttTopic: string;
  mqttSystem: MqttSystems;
  sensorsList: NewSensor[];
  setSensorsList: (newSensors: NewSensor[]) => void;
}) {
  const hasuraHeader = useHasuraHeader();
  const [, setSceneIdentifyMutation] = useSetSceneIdentifyMutation();
  const [isBlinking, setIsBlinking] = useState(false);
  const intl = useIntl();

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsBlinking(false);
    }, 10000); // 10 seconds blink is in azure function configured

    return () => clearTimeout(timer);
  }, []);

  return (
    <Input
      type="text"
      key={sensor.index}
      value={sensor.name || ''}
      data-test-id={`desk-name-${sensor.index}`}
      renderIcon={() => (
        <Tooltip
          content={
            <p>
              <HiOutlineLightBulb
                className="size-5 text-white cursor-pointer"
                onClick={() =>
                  setSceneIdentifyMutation(
                    {
                      Topics: [
                        mqttSystem === MqttSystems.Bluerange
                          ? parseBluerangeTopic(mqttTopic)
                          : // MDA2
                            generateMda2Topic(mqttTopic, Mda2MessageType.CMD),
                      ],
                      MqttSystem: mqttSystem,
                      Index: sensor.index,
                    },
                    hasuraHeader(HasuraPermissions.VIEW_ADMIN),
                  ).then(() => {
                    setIsBlinking(true);
                  })
                }
              />
            </p>
          }
        >
          <FormattedMessage id="Identify scene" />
        </Tooltip>
      )}
      iconClassName={`${isBlinking ? 'animate-pulse' : ''} bg-${renderColor(
        sensor.index,
      ).toLowerCase()}-400 rounded ${
        !sensor.active ? 'opacity-50 rounded-md' : ''
      }`}
      disabled={!sensor.active}
      onChangeValue={(input) => {
        const newSensorsList = [...sensorsList];
        const sensorIdx = newSensorsList.findIndex(
          (item) => item.index === sensor.index,
        );
        const newSensor = newSensorsList[sensorIdx];
        if (newSensor) {
          newSensor.name = input;
          setSensorsList(newSensorsList);
        }
      }}
      placeholder={intl.formatMessage({
        id: 'Desk name',
      })}
      required
    />
  );
}

function DeskSensorActivation({
  sensorsList,
  mqttSystem,
  mqttTopic,
  setSensorsList,
  intersectedRooms,
  drawingDesksLayer,
  resetDesksToInitialPositions,
}: DeskSensorActivationProps): React.JSX.Element {
  const intl = useIntl();
  const { isMobile } = useDeviceDetect();

  const relatedRooms = useMemo(
    () =>
      intersectedRooms
        ? intersectedRooms
            .filter(
              (room) => room.getProperties().RoomType.Name === RoomTypes.DESKS,
            )
            .map((room) => room.getProperties().Name)
        : [],
    [intersectedRooms],
  );

  return (
    <Accordion
      title={<FormattedMessage id="Sensors" />}
      initialStateOpen={!isMobile}
    >
      <div className="flex flex-col space-y-3">
        {sensorsList?.map((sensor) => (
          <div key={sensor.id} className="space-y-1">
            <Switch
              data-test-id={`desk-activation-switch-${sensor.index}`}
              isEnabled={sensor.active}
              onSetEnable={(enabling) => {
                const newSensorsList = [...sensorsList];
                const newSensor = newSensorsList.find(
                  (s) => s.index === sensor.index,
                );
                if (newSensor) {
                  newSensor.active = enabling;
                }
                setSensorsList(newSensorsList);
                const feat = (
                  (
                    drawingDesksLayer.olLayer as OLVectorLayer<
                      VectorSource<DrawingDeskFeatures | DeskFeatures>
                    >
                  )
                    .getSource()
                    ?.getFeatures() ?? []
                ).find((f) => getDeskFeatureIndex(f) === sensor.index);
                if (!feat) {
                  // If the feature is not defined that means that the currently changed desk was never activated
                  // Therefore we cannot determine its position because the rotation is unknown
                  // We then trigger a reset of all activated desks to default position with the newly activated Desk
                  resetDesksToInitialPositions(
                    sensorsList.filter((s) => s.active).map((s) => s.id),
                  );
                } else {
                  feat.set('visible', enabling);
                }
              }}
              color={`bg-${renderColor(sensor.index).toLowerCase()}-400`}
              label={
                <div className={sensor.active ? '' : 'opacity-50'}>
                  <FormattedMessage id="Desk" /> {sensor.index}
                </div>
              }
            />
            <Transition
              show={[MqttSystems.Bluerange, MqttSystems.Mda2].includes(
                mqttSystem,
              )}
            >
              <BlinkInput
                sensor={sensor}
                mqttSystem={mqttSystem}
                mqttTopic={mqttTopic}
                sensorsList={sensorsList}
                setSensorsList={setSensorsList}
              />
            </Transition>
          </div>
        ))}
        <div className="pt-2">
          <FormattedMessage id="Related rooms" />
          <div
            data-test-id="desk-related-rooms"
            className="text-sm  dark:text-neutral-300 text-neutral-700 max-w-[300px]"
          >
            {relatedRooms.length > 0 ? (
              <div>
                {relatedRooms.map((r) => (
                  <div key={r}>{r}</div>
                ))}
              </div>
            ) : (
              <div className="flex flex-row items-center">
                <HiOutlineExclamationCircle className="shrink-0 mx-auto size-6 text-primary-500 mr-1" />
                {intl.formatMessage({
                  id: 'The device is located outside of a desksharing room',
                })}
              </div>
            )}
          </div>
        </div>
      </div>
    </Accordion>
  );
}

export { renderColor };
export default DeskSensorActivation;
