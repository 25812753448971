import { RAINBOW } from '@/constants';
import Card from '@/generic/components/Card';
import Legend from '@/generic/components/Chart/Legend';
import { useSensorAverageHistoryClimateQuery } from '@/graphql/types';
import useStore from '@/model/store';
import useHasuraHeader, {
  HasuraPermissions,
} from '@/utils/graphql/useHasuraHeaders';
import { scaleOrdinal } from '@visx/scale';
import { useMemo } from 'react';
import { useIntl } from 'translations/Intl';
import useAnalyticsFilter from 'utils/graphql/useAnalyticsFilter';
import LineChart from './components/LineChart';

export default function ClimateChart() {
  const roomTypes = useStore((state) => state.userSettings.roomTypes);
  const climateTypes = useStore((state) => state.userSettings.climateTypes);
  const intl = useIntl();
  const hasuraHeader = useHasuraHeader();

  const [{ data: historyData, fetching: loadingHistory }] =
    useSensorAverageHistoryClimateQuery({
      variables: {
        ...useAnalyticsFilter(),
        RoomTypes: roomTypes,
      },
      context: useMemo(
        () => hasuraHeader(HasuraPermissions.VIEW_CLIMATE),
        [hasuraHeader],
      ),
    });

  const chartData = useMemo(
    () =>
      historyData?.f_history_environment_daily &&
      historyData.f_history_environment_daily.length > 0
        ? historyData.f_history_environment_daily.map((d) => ({
            ...d,
            Date: new Date(d.Date),
          }))
        : (climateTypes?.flatMap((type) => [
            ...['-'].map((r) => ({
              Id: '0',
              Room: r,
              SensorType: type,
              Value: 0,
              RoomType: '-',
              Date: new Date(),
              Unit: '',
              GoodValue: '[0,0]',
              PoorValue: '[0,0]',
              AcceptableValue: '[0,0]',
            })),
          ]) ?? []),
    [climateTypes, historyData, historyData?.f_history_environment_daily],
  );

  const rooms = useMemo(
    () => [...new Set(chartData.map((c) => c.Room))],
    [chartData],
  );

  return (
    <div className="space-y-2">
      <Card className="flex flex-wrap w-full mx-auto justify-center relative md:p-3">
        {loadingHistory ? (
          <div className="space-x-4 flex flex-wrap w-full justify-center mx-auto">
            {Array.from({ length: 10 }).map((_, j) => (
              <div className="flex space-x-1 my-1" key={j}>
                <div className="animate-pulse bg-neutral-200 h-4 rounded-full w-4" />
                <div className="animate-pulse bg-neutral-200 h-4 rounded-md w-16" />
              </div>
            ))}
          </div>
        ) : (
          <Legend
            scaleType="ordinal"
            labelFormat={(r) => r}
            scale={scaleOrdinal({
              domain: rooms,
              range: RAINBOW,
            })}
          />
        )}
      </Card>
      <div className="grid grid-cols-2 relative gap-2">
        {climateTypes?.map(
          (type) =>
            chartData.filter((c) => c.SensorType === type).length > 0 && (
              <Card
                title={`${intl.formatMessage({
                  id: '[Rooms] Climate',
                })} ${intl.formatMessage({ id: type })}`}
                key={type}
                className="relative h-96 col-span-2 md:col-span-1"
                data-test-id={`climate-chart-${type}`}
              >
                <LineChart
                  data={chartData.filter((c) => c.SensorType === type)}
                  type={type}
                  loading={loadingHistory}
                  rooms={rooms}
                />
              </Card>
            ),
        )}
      </div>
    </div>
  );
}
