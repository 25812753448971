import Tooltip from '@/generic/components/Tooltip';
import Transition from '@/generic/components/Transition';
import { FormattedMessage } from '@/translations/Intl';
import useHasuraHeader, {
  HasuraPermissions,
} from '@/utils/graphql/useHasuraHeaders';
import Select from 'generic/components/Form/Select';
import { type ModulesQuery, useArchiveFirmwareMutation } from 'graphql/types';
import { Reorder, useDragControls } from 'motion/react';
import { HiBars3 } from 'react-icons/hi2';
import { LuArchive, LuArchiveRestore } from 'react-icons/lu';
import { compare, valid } from 'semver';

export type FirmwareInfo = {
  FirmwareId: number;
  Version: string;
  ModuleId: string;
  Path?: string | null;
  Changelog?: string | null;
};

interface OrderItemProps {
  module: ModulesQuery['Modules'][number];
  selectedFirmware: FirmwareInfo[] | undefined;
  setSelectedFirmware: React.Dispatch<
    React.SetStateAction<FirmwareInfo[] | undefined>
  >;
  showArchived: boolean;
}

export default function OrderItem({
  module,
  selectedFirmware,
  setSelectedFirmware,
  showArchived,
}: OrderItemProps) {
  const hasuraHeader = useHasuraHeader();
  const [, archiveFirmware] = useArchiveFirmwareMutation();
  const controls = useDragControls();

  const firmware = module.Firmwares.find(
    (m) =>
      selectedFirmware?.find((f) => f.ModuleId === module.Id)?.FirmwareId ===
      m.Id,
  );

  const handleClick = (
    event: React.MouseEvent<SVGElement, MouseEvent>,
    firmware?: ModulesQuery['Modules'][number]['Firmwares'][number],
  ) => {
    // Do not select the option
    event.stopPropagation();
    if (firmware?.Id) {
      archiveFirmware(
        {
          FirmwareId: firmware?.Id,
          IsArchived: !firmware.IsArchived,
        },
        hasuraHeader(HasuraPermissions.READ_ALL),
      );
    }
  };

  return (
    <Reorder.Item
      key={module.Id}
      value={module}
      dragListener={false}
      dragControls={controls}
    >
      <div className="flex space-x-2 items-center">
        <HiBars3
          className="size-8 cursor-pointer text-neutral-400/50"
          onPointerDown={(e) => controls.start(e)}
        />
        <div className="col-span-1">
          <div>{module.Name}</div>
          <Select
            value={firmware}
            options={module.Firmwares.filter(
              (f) => f.Path && (!f.IsArchived || showArchived),
            ).sort((a, b) =>
              valid(a.Version) && valid(b.Version)
                ? compare(a.Version, b.Version)
                : a.Version.localeCompare(b.Version),
            )}
            isOptionDisabled={(f) => f?.IsArchived ?? false}
            keyParameter="Id"
            renderValue={(firmware) => (
              <div className="flex space-x-2">
                <div>{firmware?.Version}</div>
                <Transition
                  show={
                    firmware?.FirmwarePackageFirmwares_aggregate.aggregate
                      ?.count === 0
                  }
                >
                  <Tooltip
                    content={
                      firmware?.IsArchived ? (
                        <LuArchiveRestore
                          className="size-5"
                          onClick={(e) => handleClick(e, firmware)}
                        />
                      ) : (
                        <LuArchive
                          className="size-5"
                          onClick={(e) => handleClick(e, firmware)}
                        />
                      )
                    }
                  >
                    {firmware?.IsArchived ? (
                      <FormattedMessage id="Restore" />
                    ) : (
                      <FormattedMessage id="Archive" />
                    )}
                  </Tooltip>
                </Transition>
              </div>
            )}
            onChangeSelected={(selected) => {
              if (selected) {
                setSelectedFirmware((prev) =>
                  prev
                    ? [
                        ...prev.filter((p) => p.ModuleId !== module.Id),
                        {
                          FirmwareId: selected.Id,
                          ModuleId: module.Id,
                          Path: selected.Path,
                          Changelog: selected.Changelog,
                          Version: selected.Version,
                        },
                      ]
                    : [
                        {
                          FirmwareId: selected.Id,
                          ModuleId: module.Id,
                          Path: selected.Path,
                          Changelog: selected.Changelog,
                          Version: selected.Version,
                        },
                      ],
                );
              } else {
                setSelectedFirmware((prev) =>
                  prev?.filter((p) => p.ModuleId !== module.Id),
                );
              }
            }}
            isDeselectable={false}
          />
        </div>
      </div>
    </Reorder.Item>
  );
}
