import Pill from 'generic/components/Pill/Pill';
import Tooltip from 'generic/components/Tooltip';
import {
  useArchiveFirmwarePackageMutation,
  useSetLatestFirmwarePackageMutation,
} from 'graphql/types';
import { FormattedMessage } from 'translations/Intl';

import PrivateWrapper from '@/generic/components/PrivateWrapper';
import useHasuraHeader, {
  HasuraPermissions,
} from '@/utils/graphql/useHasuraHeaders';
import type { Row } from '@tanstack/react-table';
import { HiOutlinePencil } from 'react-icons/hi2';

import Transition from '@/generic/components/Transition';
import { LuArchive, LuArchiveRestore } from 'react-icons/lu';
import type { Firmwares } from '../AddFirmwaresModal/AddFirmwaresModal';

export function ModulesCell({ row }: { row: Row<Firmwares> }) {
  return (
    <div className="flex flex-col space-y-2">
      {row.original.FirmwarePackageFirmwares.map((o) => (
        <Pill key={o.Firmware.Id}>
          {o.Firmware.Module.Name}: {o.Firmware.Version}
        </Pill>
      ))}
    </div>
  );
}

export function ArchivedCell({ row }: { row: Row<Firmwares> }) {
  return (
    <Pill
      className={
        row.original.IsArchived
          ? '!bg-red-500 !text-red-100'
          : '!bg-green-500 !text-green-100'
      }
    >
      <FormattedMessage id={row.original.IsArchived ? 'Archived' : 'Active'} />
    </Pill>
  );
}

export function OrganizationsCell({ row }: { row: Row<Firmwares> }) {
  const hasuraHeader = useHasuraHeader();
  const [, setLatestFirmware] = useSetLatestFirmwarePackageMutation();

  return (
    <div className="flex flex-col space-y-2">
      {row.original.FirmwarePackageOrganizations.map((o) =>
        o.Latest ? (
          <Tooltip
            key={o.Organization.Id}
            content={
              <Pill
                className="!bg-green-200 !text-green-500"
                key={o.Organization.Id}
              >
                {o.Organization.Name}
              </Pill>
            }
          >
            <FormattedMessage id="Latest version" />
          </Tooltip>
        ) : (
          <Tooltip
            key={o.Organization.Id}
            content={
              <Pill
                setVisible={() =>
                  setLatestFirmware(
                    {
                      OrganizationId: o.Organization.Id,
                      FirmwarePackageId: row.original.Id,
                    },
                    hasuraHeader(HasuraPermissions.READ_ALL),
                  )
                }
              >
                {o.Organization.Name}
              </Pill>
            }
          >
            <FormattedMessage id="Set latest version" />
          </Tooltip>
        ),
      )}
    </div>
  );
}

export function ActionCell({
  row,
  setSelectedFirmwarePackage,
  setOpenAddFirmwareModal,
}: {
  row: Row<Firmwares>;
  setSelectedFirmwarePackage: (firmware: Firmwares | undefined) => void;
  setOpenAddFirmwareModal: (open: boolean) => void;
}) {
  const hasuraHeader = useHasuraHeader();
  const [, archiveFirmware] = useArchiveFirmwarePackageMutation();

  return (
    <div className="flex space-x-2 items-center">
      <PrivateWrapper roleRequired={HasuraPermissions.READ_ALL}>
        <Tooltip
          content={
            <p>
              <HiOutlinePencil
                className="size-5 cursor-pointer text-primary-500 hover:text-primary-700"
                onClick={() => {
                  setSelectedFirmwarePackage(row.original);
                  setOpenAddFirmwareModal(true);
                }}
              />
            </p>
          }
        >
          <FormattedMessage id="Edit" />
        </Tooltip>
        <Transition
          show={row.original.MqttBeacons_aggregate.aggregate?.count === 0}
        >
          {row.original.IsArchived ? (
            <Tooltip
              content={
                <p>
                  <LuArchiveRestore
                    className="size-5 cursor-pointer text-primary-500 hover:text-primary-700"
                    onClick={() =>
                      archiveFirmware(
                        {
                          IsArchived: !row.original.IsArchived,
                          FirmwarePackageId: row.original.Id,
                        },
                        hasuraHeader(HasuraPermissions.READ_ALL),
                      )
                    }
                  />
                </p>
              }
            >
              <FormattedMessage id="Restore" />
            </Tooltip>
          ) : (
            <Tooltip
              content={
                <p>
                  <LuArchive
                    className="size-5 cursor-pointer text-primary-500 hover:text-primary-700"
                    onClick={() =>
                      archiveFirmware(
                        {
                          IsArchived: !row.original.IsArchived,
                          FirmwarePackageId: row.original.Id,
                        },
                        hasuraHeader(HasuraPermissions.READ_ALL),
                      )
                    }
                  />
                </p>
              }
            >
              <FormattedMessage id="Archive" />
            </Tooltip>
          )}
        </Transition>
      </PrivateWrapper>
    </div>
  );
}
