import { RAINBOW } from '@/constants';
import { scaleOrdinal } from '@visx/scale';
import Legend from 'generic/components/Chart/Legend';
import PieChart from 'generic/components/PieChart';
import {
  type MeetingRoomsOccupancyHistoryOccupancy,
  useRoomOccupancyHistoryQuery,
} from 'graphql/types';
import { useIntl } from 'translations/Intl';
import useReportFilter from 'utils/graphql/useReportFilter';
import BarChart from '../BarChart';
import Description from '../Description';
import ReportingPage from '../ReportingPage';

export default function ReportRoomSizeOccupancy() {
  const reportFilter = useReportFilter();
  const intl = useIntl();
  const [{ data, fetching }] = useRoomOccupancyHistoryQuery(reportFilter);

  const typedData = (data?.ReportCharts[0]?.Data ?? { data: [] }) as {
    data: MeetingRoomsOccupancyHistoryOccupancy[];
  };

  const occupancyData = typedData.data.sort((a, b) => a.Capacity - b.Capacity);

  return (
    <ReportingPage
      loading={fetching}
      reportPageId={data?.ReportCharts[0]?.ReportPageId}
      reportTitle="Meeting rooms occupancy by capacity"
      kpi={intl.formatMessage({
        id: 'Is the number of meeting rooms correct?',
      })}
    >
      <div className="space-y-4">
        <div className="flex space-x-4 items-center">
          <div className="flex flex-col">
            <div className="size-96">
              <PieChart
                showLabel
                colors={RAINBOW}
                dataFormat={(d) =>
                  intl.formatMessage(
                    { id: '{rooms} rooms' },
                    { rooms: d.value },
                  )
                }
                data={
                  occupancyData?.map((d) => ({
                    id: `${intl.formatMessage({ id: 'Capacity' })} ${d.Capacity}:`,
                    value: d.NumberOfRoomsWithCapacity,
                  })) ?? []
                }
              />
            </div>
            <Description>
              {intl.formatMessage({
                id: 'Number of meeting rooms by capacity',
              })}
            </Description>
          </div>
          <div>
            <Legend
              scaleType="ordinal"
              scale={scaleOrdinal({
                domain: occupancyData?.map((d) => d.Capacity),
                range: RAINBOW,
              })}
              labelFormat={(d) =>
                `${intl.formatMessage({ id: 'Capacity' })}: ${d}`
              }
            />
          </div>
        </div>
        <div className="h-96 w-full">
          <BarChart
            data={occupancyData ?? []}
            yScaleAccessor={(d) => d.AvgDailyOccupancy}
            yScaleLabel={intl.formatMessage({ id: 'Average daily occupancy' })}
            groupAccessor={(d) => d.Capacity.toString()}
            y1Accessor={(d) => d.MaxHourlyOccupancy}
            y1Label={intl.formatMessage({ id: 'Max hourly occupancy' })}
            y2Accessor={(d) => d.MaxDailyOccupancy}
            y2Label={intl.formatMessage({ id: 'Max daily occupancy' })}
            averageValue={occupancyData?.[0]?.AvgDailyOccupancyAll}
            maxDailyValue={occupancyData?.[0]?.MaxDailyOccupancyAll}
            maxHourlyValue={occupancyData?.[0]?.MaxHourlyOccupancyAll}
            averageLabel={intl.formatMessage({ id: 'Average daily occupancy' })}
            xAxisLabel={intl.formatMessage({ id: 'Capacity' })}
          />
        </div>
      </div>
    </ReportingPage>
  );
}
