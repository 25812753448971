import { RAINBOW } from '@/constants';
import AnimatedPath from '@/generic/components/AnimatedPath';
import { REPORTING_RIGHT_MARGIN } from '@/pages/ReportingView/components/Reports/Reports';
import { GridRows } from '@visx/grid';
import { Group } from '@visx/group';
import { LegendItem, LegendLabel, LegendOrdinal } from '@visx/legend';
import { ParentSize } from '@visx/responsive';
import { scaleBand, scaleLinear, scaleOrdinal } from '@visx/scale';
import { BarGroup, Line, LinePath, Polygon } from '@visx/shape';
import { TooltipWithBounds, defaultStyles, useTooltip } from '@visx/tooltip';
import { type MarginProps, Themes } from 'common/types';
import AnimatedRect from 'generic/components/Chart/AnimatedRect';
import Axis from 'generic/components/Chart/Axis';
import Legend from 'generic/components/Chart/Legend';
import type { DesksFloorHistoryOccupancy } from 'graphql/types';
import useStore from 'model/store';
import { motion } from 'motion/react';
import { FormattedMessage, useIntl } from 'translations/Intl';
import { lower } from 'utils/date';
import format from 'utils/format';
import getColor, { primaryColorToRGB } from 'utils/getColor';
import useReportFilter from 'utils/graphql/useReportFilter';

export interface ChartData {
  [key: number]: number; // Floor number
  date: string;
}

interface ResponsiveGroupedChartProps {
  margin?: MarginProps;
  data: DesksFloorHistoryOccupancy[];
  colors?: string[];
}

interface GroupedChartProps extends ResponsiveGroupedChartProps {
  height: number;
  width: number;
}

type TooltipData = {
  bar: { data?: DesksFloorHistoryOccupancy; date?: string };
  floor?: string;
};

const getDate = (d: ChartData) => d.date;

function GroupedChart({
  height,
  width,
  margin = {
    top: 80,
    left: 70,
    right: REPORTING_RIGHT_MARGIN,
    bottom: 50,
  },
  data,
  colors,
}: GroupedChartProps) {
  const intl = useIntl();
  const { variables } = useReportFilter();
  const theme = useStore((state) => state.userSettings.theme);

  const floorOccupancyData = data?.flatMap((d) => [
    {
      [d.Floor]: d.AvgDailyOccupancy,
      date: format(lower(variables.Period), 'LLL y'),
    },
  ]);

  const chartData: ChartData[] = [
    floorOccupancyData?.reduce(
      (r, c) => Object.assign(r, c),
      {} as ChartData,
    ) ?? {
      date: format(new Date(), 'LLL y'),
    },
  ];

  const keys = Object.keys(chartData[0] ?? {}).filter((d) => d !== 'date');
  const xMax = Math.max(width - margin.left - margin.right, 0);
  const yMax = height - margin.top - margin.bottom;

  const dateScale = scaleBand<string>({
    domain: chartData.map(getDate),
    padding: 0.2,
    range: [0, xMax],
  });
  const floorScale = scaleBand<string>({
    domain: keys,
    padding: 0.1,
    range: [0, dateScale.bandwidth()],
  });
  const occupancyScale = scaleLinear<number>({
    domain: [0, 100],
    range: [yMax, 0],
  });
  const colorScale = scaleOrdinal<string, string>({
    domain: keys,
    range: RAINBOW || colors,
  });
  const shapeScale = scaleOrdinal<string, React.FC | React.ReactNode>({
    domain: [
      intl.formatMessage({ id: 'Max hourly occupancy' }),
      intl.formatMessage({ id: 'Max hourly usage' }),
      intl.formatMessage({ id: 'Average daily occupancy' }),
    ],
    range: [
      <Polygon
        key={intl.formatMessage({ id: 'Max hourly occupancy' })}
        sides={6}
        center={{ x: 6, y: 7 }}
        rotate={90}
        size={6}
        fill={primaryColorToRGB(500, 1)}
      />,
      <Polygon
        key={intl.formatMessage({ id: 'Max hourly usage' })}
        sides={4}
        center={{ x: 6, y: 7 }}
        rotate={90}
        size={6}
        fill={primaryColorToRGB(500, 1)}
      />,
      <Line
        key={intl.formatMessage({ id: 'Average daily occupancy' })}
        from={{ x: 0, y: 8 }}
        to={{ x: 10, y: 8 }}
        strokeWidth={2}
        stroke={
          theme.color === Themes.LIGHT
            ? getColor('NEUTRAL600')
            : getColor('NEUTRAL300')
        }
      />,
    ],
  });

  const {
    tooltipOpen,
    tooltipLeft,
    tooltipTop,
    tooltipData,
    hideTooltip,
    showTooltip,
  } = useTooltip<TooltipData>();

  const variants = {
    initial: {
      fill: '#fff',
      pathLength: 0,
    },
    animate: {
      transition: {
        ease: 'easeInOut',
        duration: 1,
      },
      pathLength: 1,
    },
  };

  return (
    <div className="relative">
      <svg width={width} height={height}>
        <Group top={margin.top} left={margin.left}>
          <GridRows
            numTicks={10}
            scale={occupancyScale}
            width={xMax}
            height={yMax}
            strokeDasharray="1,3"
            stroke={getColor('NEUTRAL600')}
            strokeOpacity={0.6}
          />
          <BarGroup
            data={chartData}
            keys={keys}
            height={yMax}
            x0={getDate}
            x0Scale={dateScale}
            x1Scale={floorScale}
            yScale={occupancyScale}
            color={colorScale}
          >
            {(barGroups) =>
              barGroups.map((barGroup) => (
                <Group
                  key={`flex-bar-group-${barGroup.index}-${barGroup.x0}`}
                  left={barGroup.x0}
                >
                  <g>
                    {barGroup.bars.map((bar) => (
                      <g
                        key={`flex-bar-group-bar-${barGroup.index}-${bar.index}-${bar.value}-${bar.key}`}
                      >
                        <AnimatedRect
                          data-test-id={`flex-bar-group-${chartData[barGroup.index]?.date}-${bar.key}`}
                          bar={bar}
                          onMouseMove={(event) => {
                            showTooltip({
                              tooltipData: {
                                bar: {
                                  data: data.find(
                                    (d) =>
                                      Number.parseInt(bar.key, 10) === d.Floor,
                                  ),
                                  date: chartData[barGroup.index]?.date,
                                },
                                floor: bar.key,
                              },
                              tooltipTop: event?.y,
                              tooltipLeft: event?.x,
                            });
                          }}
                          onMouseOut={hideTooltip}
                        />
                        <Polygon
                          sides={4}
                          center={{
                            x: bar.x + bar.width / 2,
                            y: occupancyScale(
                              data.find(
                                (d) => Number.parseInt(bar.key, 10) === d.Floor,
                              )?.MaxHourlyUsage ?? 0,
                            ),
                          }}
                          rotate={90}
                          size={6}
                        >
                          {({ points }) => (
                            <motion.polygon
                              initial="initial"
                              animate="animate"
                              variants={{
                                initial: variants.initial,
                                animate: {
                                  fill: bar.color,
                                  ...variants.animate,
                                },
                              }}
                              points={`${points}`}
                              fillOpacity={0.8}
                            />
                          )}
                        </Polygon>
                        <Polygon
                          sides={6}
                          center={{
                            x: bar.x + bar.width / 2,
                            y: occupancyScale(
                              data.find(
                                (d) => Number.parseInt(bar.key, 10) === d.Floor,
                              )?.MaxHourlyOccupancy ?? 0,
                            ),
                          }}
                          rotate={90}
                          size={6}
                        >
                          {({ points }) => (
                            <motion.polygon
                              initial="initial"
                              animate="animate"
                              variants={{
                                initial: variants.initial,
                                animate: {
                                  fill: bar.color,
                                  ...variants.animate,
                                },
                              }}
                              points={`${points}`}
                              fillOpacity={0.8}
                            />
                          )}
                        </Polygon>
                      </g>
                    ))}
                    <LinePath
                      data={barGroup.bars}
                      x={(d) => d.x + d.width / 2}
                      y={occupancyScale(data[0]?.AvgDailyOccupancyAll ?? 0)}
                    >
                      {({ path }) => (
                        <AnimatedPath path={path} data={barGroup.bars} />
                      )}
                    </LinePath>
                  </g>
                </Group>
              ))
            }
          </BarGroup>
          <Axis
            lowLevelChart
            orientation="left"
            scale={occupancyScale}
            tickFormat={(y) => `${y}%`}
            label={intl.formatMessage({ id: 'Occupancy' })}
          />
          <Axis
            lowLevelChart
            orientation="bottom"
            top={yMax}
            scale={dateScale}
            label={intl.formatMessage({ id: 'Duration' })}
          />
        </Group>
      </svg>
      <div className="flex items-center justify-evenly absolute top-8 w-full space-y-2">
        <div className="flex flex-col items-center">
          <div className="flex">
            <Legend
              scaleType="ordinal"
              labelFormat={(d) =>
                intl.formatMessage(
                  {
                    id: '{number} Floor',
                  },
                  { number: d },
                )
              }
              scale={colorScale}
            />
          </div>
          <div className="flex">
            <LegendOrdinal
              direction="row"
              labelMargin="0 15px 0 0"
              scale={shapeScale}
            >
              {(groups) => (
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  {groups.map((label, i) => {
                    const shape = shapeScale(label.datum);
                    return (
                      <LegendItem margin="0 5px" key={`legend-quantile-${i}`}>
                        <svg width="16" height="16" viewBox="0 0 16 16">
                          {shape as React.ReactElement}
                        </svg>
                        <LegendLabel align="left" className="text-xs">
                          {label.text}
                        </LegendLabel>
                      </LegendItem>
                    );
                  })}
                </div>
              )}
            </LegendOrdinal>
          </div>
        </div>
      </div>
      {tooltipOpen && tooltipData && (
        <TooltipWithBounds
          top={tooltipTop}
          left={tooltipLeft}
          style={{
            ...defaultStyles,
            background:
              theme.color === Themes.LIGHT
                ? getColor('WHITE')
                : getColor('NEUTRAL900'),
          }}
        >
          <div className="text-primary-500">
            <strong>
              <FormattedMessage id="All" />
            </strong>
          </div>
          <div
            className="dark:text-neutral-200 "
            data-test-id="report-flex-tooltip-all"
          >
            <div>
              <FormattedMessage id="Average daily occupancy" />:{' '}
              {`${tooltipData.bar.data?.AvgDailyOccupancyAll.toFixed(2)}%`}
            </div>
            <div>
              <FormattedMessage id="Max hourly occupancy" />:{' '}
              {`${tooltipData.bar.data?.MaxHourlyOccupancyAll.toFixed(2)}%`}
            </div>
            <div>
              <FormattedMessage id="Max hourly usage" />:{' '}
              {`${tooltipData.bar.data?.MaxHourlyUsageAll.toFixed(2)}%`}
            </div>
          </div>
          {tooltipData.floor && (
            <>
              <div style={{ color: colorScale(tooltipData.floor) }}>
                <strong>
                  <FormattedMessage
                    id="{number} Floor"
                    values={{ number: tooltipData.floor }}
                  />
                </strong>
              </div>
              <div
                className="dark:text-neutral-200 "
                data-test-id="report-flex-tooltip"
              >
                <div>
                  <FormattedMessage id="Average daily occupancy" />:{' '}
                  {`${tooltipData.bar.data?.AvgDailyOccupancy.toFixed(2)}%`}
                </div>
                <div>
                  <FormattedMessage id="Max hourly occupancy" />:{' '}
                  {`${tooltipData.bar.data?.MaxHourlyOccupancy.toFixed(2)}%`}
                </div>
                <div>
                  <FormattedMessage id="Max hourly usage" />:{' '}
                  {`${tooltipData.bar.data?.MaxHourlyUsage.toFixed(2)}%`}
                </div>
              </div>
            </>
          )}
          <div className="dark:text-neutral-200">
            <small>{tooltipData.bar.date}</small>
          </div>
        </TooltipWithBounds>
      )}
    </div>
  );
}

export default function ResponsiveGroupedChart(
  props: ResponsiveGroupedChartProps,
) {
  return (
    <ParentSize>
      {({ height, width }) => (
        <GroupedChart {...props} height={height} width={width} />
      )}
    </ParentSize>
  );
}
