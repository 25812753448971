import { LOCALES } from '@/constants';
import { TZDate, tz } from '@date-fns/tz';
import {
  addDays,
  addHours,
  addYears,
  endOfDay,
  formatDistance,
  set,
  startOfDay,
  startOfWeek,
  startOfYear,
  transpose,
} from 'date-fns';
import useStore from 'model/store';
import localize from './format';

export const startOfDayUTC = (date: Date) =>
  startOfDay(transpose(date, tz('UTC'))).toISOString();
export const endOfDayUTC = (date: Date) =>
  endOfDay(transpose(date, tz('UTC'))).toISOString();
export const startOfYearUTC = (date: Date) =>
  startOfYear(transpose(date, tz('UTC'))).toISOString();

// Source: https://github.com/date-fns/tz/issues/6
export const dateAtUTC = (date: Date) =>
  transpose(new TZDate(date, 'UTC'), Date);

export function getWeekDayFromNumber(number: number): string {
  return localize(addDays(startOfWeek(new Date()), number), 'EEEEEE');
}

export const getTodayAtSpecificHour = (hour = 12, minutes = 0): Date =>
  set(new Date(), { hours: hour, minutes, seconds: 0, milliseconds: 0 });

export const getInterval = (time: string): Date =>
  getTodayAtSpecificHour(
    time ? Number.parseInt(time.split(':')[0] ?? '0', 10) : 0,
    time ? Number.parseInt(time.split(':')[1] ?? '0', 10) : 0,
  );

const parseDateRange = (range: string) => {
  const parts = range.split(',');

  if (parts.length !== 2 || !parts[0] || !parts[1]) {
    throw new Error('Invalid range');
  }

  // Timerange (date range without time also only has length 1)
  if (
    parts[0].split(' ').length === 1 &&
    parts[0].split(' ')[0]?.includes(':')
  ) {
    return {
      start: {
        inclusive: parts[0][0] === '[',
        value: getInterval(parts[0].slice(1)),
      },

      end: {
        inclusive: parts[1][parts[1].length - 1] === ']',
        value: getInterval(parts[1].slice(0, -1)),
      },
    };
  }

  // Daterange
  if (parts[0].split(' ').length === 1) {
    return {
      start: {
        inclusive: parts[0][0] === '[',
        value: new Date(parts[0].slice(1).replaceAll('"', '')),
      },

      end: {
        inclusive: parts[1][parts[1].length - 1] === ']',
        value: new Date(parts[1].slice(0, -1).replaceAll('"', '')),
      },
    };
  }

  // Timestamp range
  return {
    start: {
      inclusive: parts[0][0] === '[',
      // Check if the date includes milliseconds
      value:
        parts[0].slice(1).replaceAll('"', '') === 'infinity'
          ? // Infinity is long, but use an earthly time
            addYears(new Date(), 10)
          : new Date(parts[0].slice(1).replaceAll('"', '')),
    },
    end: {
      inclusive: parts[1][parts[1].length - 1] === ']',
      value:
        parts[1].slice(0, -1).replaceAll('"', '') === 'infinity'
          ? // Infinity is long, but use an earthly time
            addYears(new Date(), 10)
          : new Date(parts[1].slice(0, -1).replaceAll('"', '')),
    },
  };
};

export const serializeRange = (
  range: {
    start: { value: Date; inclusive: boolean };
    end: { value: Date; inclusive: boolean };
  },
  timerange = false,
) => {
  const inclusivity = (inclusive: boolean) => (inclusive ? '[]' : '()');

  // Timerange
  if (timerange) {
    return `${inclusivity(range.start.inclusive)[0]}${localize(
      range.start.value,
      'HH:mm',
    )}, ${localize(range.end.value, 'HH:mm')}${
      inclusivity(range.end.inclusive)[1]
    }`;
  }

  // Daterange
  return `${inclusivity(range.start.inclusive)[0]}${localize(
    range.start.value,
    'yyyy-MM-dd HH:mm:ssx',
  )}, ${localize(range.end.value, 'yyyy-MM-dd HH:mm:ssx')}${
    inclusivity(range.end.inclusive)[1]
  }`;
};

export const lower = (duration: string): Date =>
  parseDateRange(duration).start.value;

export const upper = (duration: string): Date =>
  parseDateRange(duration).end.value;

export const getAllWeekdays = (): string[] =>
  Array.from(Array(7)).map((_, i) => getWeekDayFromNumber(i + 1));

export const getAllHours = (): string[] =>
  Array.from(Array(24)).map((_, i) =>
    localize(addHours(startOfDay(new Date()), i), 'H'),
  );

export const formattedDistance = (
  date: Date,
  options: { includeSeconds: boolean } = { includeSeconds: false },
  baseDate: Date = new Date(),
) =>
  formatDistance(date, baseDate, {
    addSuffix: true,
    includeSeconds: options.includeSeconds,
    locale: LOCALES[useStore.getState().userSettings.language],
  });
