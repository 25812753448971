import {
  FloatingPortal,
  type Placement,
  arrow,
  autoUpdate,
  flip,
  offset,
  shift,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useRole,
} from '@floating-ui/react';
import { AnimatePresence, motion } from 'motion/react';
import { cloneElement, useMemo, useRef, useState } from 'react';
import { HiOutlineExclamationCircle } from 'react-icons/hi2';
import { mergeRefs } from 'react-merge-refs';

interface TooltipProps {
  content?: React.JSX.Element;
  className?: string;
  arrowClassName?: string;
  placement?: Placement;
  staticPlacement?: boolean;
  tooltipOffset?: { crossAxis: number; mainAxis: number } | number;
  arrowOffset?: number;
  children: React.ReactNode;
}

export default function Tooltip({
  children,
  className,
  arrowClassName,
  content = (
    <div>
      <HiOutlineExclamationCircle className="size-4 rotate-180 rounded-full text-primary-500 hover:bg-neutral-200 dark:hover:bg-neutral-700 transition-all" />
    </div>
  ),
  placement = 'top',
  staticPlacement,
  tooltipOffset = 8,
  arrowOffset = -4,
}: TooltipProps) {
  const [open, setOpen] = useState(false);
  const arrowRef = useRef(null);

  const {
    x,
    y,
    refs,
    strategy,
    context,
    middlewareData: {
      arrow: { x: arrowX, y: arrowY } = { x: 0, y: 0 },
    },
    placement: floatingPlacement,
  } = useFloating({
    placement,
    open,
    onOpenChange: setOpen,
    middleware: [
      offset(tooltipOffset),
      flip(),
      shift({ padding: 8 }),
      arrow({ element: arrowRef }),
    ],
    whileElementsMounted: autoUpdate,
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useHover(context, { restMs: 40 }),
    useFocus(context),
    useRole(context, { role: 'tooltip' }),
    useDismiss(context),
  ]);

  // Preserve the consumer's ref
  const ref = useMemo(
    () => mergeRefs([refs.setReference, (content as any).ref]),
    [refs.setReference, content],
  );

  // Used for positioning the arrow
  const staticSide = {
    top: 'bottom',
    right: 'left',
    bottom: 'top',
    left: 'right',
  }[floatingPlacement.split('-')[0]!];

  return (
    <>
      {cloneElement(content, getReferenceProps({ ref, ...content.props }))}
      <AnimatePresence>
        <FloatingPortal>
          {(open || staticPlacement) && (
            <motion.div
              initial={{ opacity: 0, scale: 0.85 }}
              animate={{
                opacity: 1,
                scale: 1,
              }}
              exit={{ opacity: 0 }}
              transition={{
                type: 'spring',
                damping: 20,
                stiffness: 300,
              }}
              ref={refs.setFloating}
              className={`z-40 h-fit text-sm md:text-xs print:hidden font-semibold justify-center border border-neutral-300 dark:border-neutral-700 rounded-md p-1 shadow w-max max-w-[250px] break-words backdrop-blur bg-opacity-90 bg-white dark:bg-neutral-800 dark:text-white text-neutral-700 ${className ?? ''}`}
              style={{
                position: strategy,
                top: y,
                left: x,
              }}
              {...getFloatingProps()}
            >
              {children}
              <div
                ref={arrowRef}
                className={`absolute dark:hidden size-2 rotate-45 backdrop-blur bg-opacity-90 bg-white dark:bg-neutral-800 ${arrowClassName}`}
                style={{
                  left: arrowX,
                  top: arrowY,
                  [staticSide!]: `${arrowOffset}px`,
                }}
              />
            </motion.div>
          )}
        </FloatingPortal>
      </AnimatePresence>
    </>
  );
}
