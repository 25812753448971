interface TitleProps {
  value: string;
  className?: string;
}

export default function Subtitle({
  value,
  className = '',
  ...rest
}: TitleProps): React.JSX.Element {
  return (
    <div
      className={`md:text-xl text-lg font-extrabold dark:text-white text-neutral-800 ${className ?? ''}`}
      {...rest}
    >
      {value}
    </div>
  );
}
